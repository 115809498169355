import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Navbar, Nav, Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { BiLogOutCircle, BiClipboard } from 'react-icons/bi';
import { CgProfile } from 'react-icons/cg';
import { BsBookmark, BsChatLeftText } from 'react-icons/bs'
    ;
import { Link, useNavigate } from "react-router-dom";
// import { logout } from "../../helpers/auth";
// import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { switchAppView } from "../../redux/actions/meta.action";

// import { getUserProfile } from "../../redux/actions/user.action";
import { showAuthModal, logout } from "../../redux/actions/auth.actions";
import AuthModal from "../Authentication/AuthModal";
import { optimizedCloudinaryImage } from "../../utils/fileCompression";
import { generateLetterSVG } from "../../utils/validations";

// import 'bootstrap/dist/css/bootstrap.min.css';
import './headerStyle.css';
import './loginStyle.css';
// import '../../App.css';
import BrowseSpecialtiesModal from "./BrowseSpecialtiesModal";
import Hotjar from '@hotjar/browser';

function HeaderSite() {
    const navRef = useRef(null);

    // const navigate = useNavigate();
    const dispatch = useDispatch();
    // const location = useLocation();
    const [showLogin, setLoginShow] = useState(false);

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState();
    const [expanded, setExpanded] = useState(false);
    /** ---- Update Message Count ---- */
    const [messageCount, setMessageCount] = useState(null);


    const auth = useSelector(state => state.auth);
    const user = useSelector(state => state.user);
    const showModal = useSelector(state => state.showModal);

    const socketState = useSelector(state => state.socket)

    // const searchParams = new URLSearchParams(location.search);
    // const token = searchParams.get('token');

    // useEffect(() => {
    //     if (auth.loggedIn) {
    //         dispatch(getUserProfile())
    //     }
    // }, [auth.loggedIn, dispatch]);

    // Utility function to safely call Hotjar
    const safeHotjar = (action, ...args) => {
        try {
            if (Hotjar.initialized()) {
                if (action === 'identify') {
                    Hotjar.identify(...args);
                } else if (action === 'event') {
                    Hotjar.event(...args);
                }
            }
        } catch (error) {
            console.warn('Hotjar not available:', error);
        }
    };

    useEffect(() => {
        // console.log('[header:user]',  user );
        if (!user.error && user.userMetaDetail) {
            setUserData(user.userMetaDetail);
            setIsLoggedIn(true);

            // Safely identify logged-in users
            safeHotjar('identify', user.userMetaDetail._id, {
                name: user.userMetaDetail.name,
                role: user.userMetaDetail.role,
                email: user.userMetaDetail.email,
                userType: 'registered'
            });
        }
    }, [user]);

    useEffect(() => {
        if (showModal.authModal !== null || showModal.authModal !== undefined) {
            setLoginShow(showModal.authModal);
        }
    }, [showModal.authModal]);

    /** ------ Message Count State ------ */
    useEffect(() => {
        if (socketState.unreadCount) {
            setMessageCount(socketState.unreadCount.unreadCount)
        }
    }, [socketState])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setExpanded(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [navRef]);

    const handleToggle = () => setExpanded(!expanded);

    // Universal tracking function for all users
    const trackUserAction = (action, data = {}) => {
        const baseData = {
            userType: isLoggedIn ? 'registered' : 'anonymous',
            timestamp: new Date().toISOString(),
            page: window.location.pathname,
            ...data
        };

        if (isLoggedIn && userData) {
            baseData.userId = userData._id;
            baseData.userRole = userData.role;
        }

        safeHotjar('event', action, baseData);
    };

    // Track navigation
    const handleNavigation = (destination) => {
        trackUserAction('navigation', {
            from: window.location.pathname,
            to: destination
        });
    };

    // Track authentication attempts
    const handleAuthClick = () => {
        trackUserAction('auth_modal_opened', {
            previousActions: 'direct_login_click'
        });
    };


    const renderAccountMenus = () => {
        return (
            <Dropdown className="order-lg-last">
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    <UserAvatar userData={userData} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {userData?.name && userData?.role === 'seller' ? (
                        <Dropdown.Item 
                            className="my-profile-text"
                            onClick={() => {
                                dispatch(switchAppView('seller'));
                                trackUserAction('practice_management_access', {
                                    userRole: userData.role
                                });
                            }}
                        >
                            <span style={{ cursor: 'pointer' }}>
                                <CgProfile /> {userData.name}
                            </span>
                        </Dropdown.Item>
                    ) : (
                        <Dropdown.Item className="my-profile-text">
                            <Link to="/profile">
                                <CgProfile /> {userData.name}
                            </Link>
                        </Dropdown.Item>
                    )}
                    {(userData && userData.role === 'buyer') &&
                        <>
                            <Dropdown.Item>
                                <Link to="/bookmarks">
                                    <BsBookmark /> Bookmarks
                                </Link>
                            </Dropdown.Item>
                           {window.location.hostname !== 'mybirthbridge.com' && <Dropdown.Item>
                                <Link to="/family-journey">
                                    <BsBookmark /> My Journey
                                </Link>
                            </Dropdown.Item>}
                            <Dropdown.Item>
                                <Link to="/appointments">
                                    <BiClipboard /> Appointments
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <Link to="/chat">
                                    <span>
                                        <BsChatLeftText /><span className="nav--title">Messages</span>
                                    </span>
                                    {(messageCount > 0) &&
                                        <span className="nav--count--badge ml-1">{messageCount}</span>
                                    }
                                </Link>
                            </Dropdown.Item>
                        </>}
                    <Dropdown.Item onClick={() => logout()}><BiLogOutCircle /> Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    return (
        <>
            <header className="App-header my-profile-page-main-wraper header-main">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <Navbar ref={navRef} expand="lg" className="p-0" expanded={expanded}>
                                <Navbar.Brand>
                                    <Link to="/">
                                        <img src="/images/logo.webp" loading='lazy' alt="BirthBridge Logo" width={'60px'} />
                                        <span>BirthBridge</span>
                                    </Link>
                                </Navbar.Brand>
                                <Navbar.Toggle className="ml-auto" aria-controls="basic-navbar-nav" onClick={handleToggle} />
                                {isLoggedIn ? renderAccountMenus() : null}
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <NavLinks
                                        isLoggedIn={auth.loggedIn}
                                        onClose={() => setExpanded(false)}
                                        userData={userData}
                                        onNavClick={handleNavigation}
                                        onAuthClick={handleAuthClick}
                                        trackUserAction={trackUserAction}
                                    />
                                </Navbar.Collapse>
                            </Navbar>
                        </Col>
                    </Row>
                </Container>
            </header>
            {showLogin &&
                <AuthModal
                    show={showLogin}
                    onHide={() => dispatch(showAuthModal(false))}
                />
            }
        </>
    );
}

const UserAvatar = ({ userData }) => {
    return (
        <div className="my-pic-user">
            {(userData?.profilePic && userData?.profilePic?.url) ? (
                <img src={optimizedCloudinaryImage(userData?.profilePic.url)} alt="profile picture" loading="lazy" />
            ) : (
                <div className="user-buyer-name">
                    {generateLetterSVG(userData.name, 26)}
                </div>
            )}
        </div>
    );
};

const NavLinks = ({
    isLoggedIn,
    onClose,
    userData,
    onNavClick,
    onAuthClick,
    trackUserAction
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showSpecialtiesModal, setShowSpecialtiesModal] = useState(false);
    return (
        <Nav className="ml-auto">
            <Nav.Link
                onClick={() => {
                    trackUserAction('specialties_browse', {
                        interaction: 'modal_open'
                    });
                    setShowSpecialtiesModal(true);
                }}
            >
                Browse Specialties
            </Nav.Link>

            <BrowseSpecialtiesModal
                show={showSpecialtiesModal}
                onHide={() => setShowSpecialtiesModal(false)}
            />

            <Nav.Link
                as={Link}
                to="/local-birth-professionals"
                onClick={() => {
                    onClose();
                    trackUserAction('search_page_visit');
                }}
            >
                Find Birth Professionals
            </Nav.Link>

            <Nav.Link
                as={Link}
                to="/community"
                onClick={() => {
                    onClose();
                    trackUserAction('community_page_visit');
                }}
            >
                Our Community
            </Nav.Link>

            {/* <Nav.Link as={Link} to="/blog-list" onClick={onClose}>Articles</Nav.Link> */}
            {/* <Nav.Link as={Link} to="/support-families-through-surrogacy-and-adoption-donations" onClick={onClose}>Donations</Nav.Link> */}

            {!isLoggedIn ? (
                <>
                    <Nav.Link
                        as={Link}
                        to="/grow-your-practice"
                        onClick={() => {
                            onClose();
                            trackUserAction('professional_listing_visit');
                        }}
                    >
                        Get Listed as a Birth Professional
                    </Nav.Link>
                    <Nav.Link
                        onClick={() => {
                            navigate('/');
                            dispatch(showAuthModal(true));
                            onClose();
                            trackUserAction('login_attempt', {
                                source: 'header_nav'
                            });
                        }}
                    >
                        Login
                    </Nav.Link>
                </>
            ) : (
                userData?.role !== 'buyer' && (
                    <Nav.Link
                        onClick={() => {
                            dispatch(switchAppView('seller'));
                            trackUserAction('practice_management_access', {
                                userRole: userData.role
                            });
                        }}
                    >
                        Manage Your Practice
                    </Nav.Link>
                )
            )}
        </Nav>
    );
};

export default HeaderSite;