import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { IoClose, IoEye } from 'react-icons/io5';
import PhotoViewer from '../../../commonComponents/PhotoViewer';
import { deepClone, generateYears, generateMonths } from '../../../utils/validations';
import SelectCategorySuggestions from '../ServiceManagement/SelectCategorySuggestions';
import { certificateTypes } from '../../../utils/certificateTypes';
import ReactSelect from 'react-select';
import './formStyles.css';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography, Box } from '@mui/material';
import TestComponentRender from './TestComponentRender'
import FormTitle, { FormTitleWithButton } from '../../SharedStyledComponents/FormTitle';
import CustomButton from '../../sharedComponents/Button';
import CustomSpecialtiesModal from '../../../commonComponents/CustomSpecialtiesModal';
// const singleProduct = {
//     service: '',
//     category: []
// }

// const showOptions = [
//     { value: true, label: 'Yes' },
//     { value: false, label: 'No' },
// ]


const Files = ({
    filesIndex,
    file,
    SellerProfile,
    setSellerProfile,
    handleShowFileView,
    handleRemoveFile,
    handleFileInputChange,
    bbServices,
    productError,
    setProductErrors,
    certificateTimeError,
    setTriggerRender,
    triggerRender,
    triggerHack,
}) => {
    const [errors, setErrors] = useState([]);
    // const [showSpecialtiesModal, setShowSpecialtiesModal] = useState(false);

    const months = generateMonths();
    const years = generateYears();

    useEffect(() => {
        if (!file.product || (Array.isArray(file.product) && !file.product.length)) {
            const { copySellerProfileFiles, copyFile } = handleClone()
            copyFile.product = []
            // copyFile.product.push(singleProduct)
            copySellerProfileFiles[filesIndex] = copyFile
            handleSetSellerProfile('files', copySellerProfileFiles)
        }
    }, [file?.url]);

    // useEffect(() => {
    //     if (productError && productError.length) {
    //         setErrors(productError);
    //     } else {
    //         setErrors([]);
    //     }
    // }, [productError])

    const handleClone = () => {
        return { copySellerProfileFiles: deepClone(SellerProfile.files), copyFile: deepClone(file) }
    }

    const handleSetSellerProfile = (field, data) => {
        setSellerProfile({ ...SellerProfile, [field]: data });
    }



    // const handleChange = (value, field, index) => {
    //     const { copySellerProfileFiles, copyFile } = handleClone();
    //     console.log('handleChange: ',  copySellerProfileFiles, copyFile )
    //     copyFile.product[index][field] = value
    //     copySellerProfileFiles[filesIndex] = copyFile;
    //     setProductErrors(index, field)
    //     handleSetSellerProfile('files', copySellerProfileFiles)
    // }

    // const handleAddMoreProduct = (fileProduct) => {
    //     if (fileProduct) {
    //         setProductErrors(fileProduct.length - 1)
    //     } else {
    //         setProductErrors(0)
    //     }
    //     const { copySellerProfileFiles, copyFile } = handleClone()
    //     copyFile.product.push(singleProduct)
    //     copySellerProfileFiles[filesIndex] = copyFile
    //     handleSetSellerProfile('files', copySellerProfileFiles)
    // }

    // const removeProduct = (productIndex) => {
    //     const { copySellerProfileFiles, copyFile } = handleClone();
    //     copyFile.product.splice(productIndex, 1);
    //     copySellerProfileFiles[filesIndex] = copyFile
    //     handleSetSellerProfile('files', copySellerProfileFiles)
    // }

    const renderError = (index, field) => {
        let error = null

        if (errors && errors.length) {
            if (field && typeof errors[index] === 'object' && errors[index][field]) {
                error = (<span className='error-msg'>{errors[index][field]}</span>)
            }

            if (typeof errors[index] === 'string' && errors[index]) {
                error = (<span className='error-msg'>{errors[index]}</span>)
            }
        }
        return error
    }

    return (
        <div className="certificate-files-upload mt-3" key={`_${filesIndex}`}>
            <TestComponentRender
                setTriggerRender={setTriggerRender}
                triggerRender={triggerRender}
                triggerHack={triggerHack}
                filesIndex={filesIndex}
                handleShowFileView={handleShowFileView}
                file={file}
                handleFileInputChange={handleFileInputChange}
                handleRemoveFile={handleRemoveFile}
                certificateTimeError={certificateTimeError}
                years={years}
                months={months}
            />
            <FormTitleWithButton>
                <Box className="col-sm-12 mt-4 p-0">
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <span style={{ margin: 0 }}>
                            Select Relevant Certificate Specialty<span className="required">*</span>
                        </span>
                    </Box>
                </Box>
            </FormTitleWithButton>
            <CustomSpecialtiesModal
                products = { file.product}
                handleSetSellerProfile={(a, b) => handleSetSellerProfile(a, b)}
                handleClone={handleClone}
                filesIndex={filesIndex}
            />
            {productError && <span className='error-msg mt-2'> Specialty is missing</span>}
        </div>
    )
}

export default Files;