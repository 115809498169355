import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Alert } from "react-bootstrap";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Menu, MenuItem } from '@mui/material';
import { AiOutlineUnorderedList, AiOutlineAppstore } from 'react-icons/ai';
import { Button, ButtonGroup } from '@mui/material';

import SingleAppointment from "./SingleAppointment";
import { cancelOrder, clearOrderReduxState, completeOrder, getBuyersOrders, provideFeedback } from '../../../redux/actions/orders.action';
import { useSelector, useDispatch, } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import Appointment_Service_Past from "./Appointment_Service_Past";
import { toast } from 'react-toastify';
import LoadingView from '../../LoadingView';
import { BiClipboard } from 'react-icons/bi';
import "../Buyer.css";
import SEO from "../../../seo";


const Appointments = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [appointments, setAppointments] = useState([]);
    const [alert, setAlert] = useState('');
    const [loader, setLoader] = useState({ appointments: true, cancel: false, accept: false, complete: false, rating: false });
    const [toggleView, setToggleView] = useState(true);

    const ordersState = useSelector(state => state.orders);

    useEffect(() => {
        getAppointments();

        return () => {
            dispatch(clearOrderReduxState())
        }
    }, [])

    /** --- Get Appointments ---- */
    const getAppointments = () => {
        dispatch(getBuyersOrders());
    }

    useEffect(() => {
        if (ordersState.error) {
            // let _alert = {}
            // _alert['variant'] = 'danger'
            // _alert['message'] = ordersState.error.message || 'Oops, Something goes wrong';
            // setAlert( _alert );
            handleCreateAlert('error', ordersState.error.message || 'Oops, Something goes wrong')

            setLoader({ appointments: false, cancel: false });
            handleCloseAlert()
        }

        if (!ordersState.error) {
            if (ordersState.buyerOrders && ordersState.buyerOrders.success) {
                setAppointments(ordersState.buyerOrders.orders);
                // setAlert('')
                setLoader({ appointments: false })
            }

            if (ordersState.cancelOrder && ordersState.cancelOrder.success) {
                // let _alert = {}
                // _alert['variant'] = 'success'
                // _alert['message'] = ordersState.cancelOrder.message || 'Request to cancel order has been sent';
                // setAlert( _alert )
                handleCreateAlert('success', ordersState.cancelOrder.message || 'Request to cancel order has been sent')

                setLoader({ cancel: false })
                handleCloseAlert()
                getAppointments()
            }

            if (ordersState.complete && ordersState.complete.success) {
                // let _alert = {}
                // _alert['variant'] = 'success'
                // _alert['message'] = ordersState.complete.message || 'Request to complete order has been sent'
                // setAlert( _alert )
                handleCreateAlert('success', ordersState.complete.message || 'Request to complete order has been sent')

                setLoader({ complete: false })
                handleCloseAlert()
                getAppointments()
            }

            if (ordersState.rating && ordersState.rating.success) {
                // let _alert = {}
                // _alert['variant'] = 'success'
                // _alert['message'] = ordersState.complete.message.concat(' & your feedback has been saved') || 'Request to complete order has been sent'
                // setAlert( _alert )
                handleCreateAlert('success', ordersState.rating.message)
                setLoader({ rating: false })
                handleCloseAlert()
                getAppointments()
            }

        }

    }, [ordersState])

    const handleCloseAlert = () => {
        // setTimeout(() => {
        //     setAlert('')
        // }, 5000)
        setTimeout(() => {
            // setAlert('')
            toast.dismiss()
        }, 5000)
    }

    const handleCreateAlert = (variant, message) => {
        toast[variant](message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const displayNoAppoinments = () => {
        return (
            <div className='main-wraper-a-p'>
                <BiClipboard />
                <br />
                <h4>No Appointments</h4>
                <p>You don't have any appointments. <br />
                    Let's get you started by booking an appointment nearby.</p>
                <Button variant='primary' onClick={() => navigate('/local-birth-professionals')}>Start Booking Now</Button>
            </div>
        )
    }

    /**
     * 
     * @param {Mongoose ObjectId} order_id 
     */
    const handleCancelAppointment = (order_id, cancelReason) => {
        setLoader({ cancel: true })
        // dispatch( cancelOrder({ order_id }) );
        dispatch(cancelOrder({ order_id, rejection_reason: cancelReason }))
    }

    const handleCompleteOrder = (orderId, feedbackPayload, ratingProvided, orderCompleted) => {

        if (!orderCompleted) {
            setLoader({ complete: true });
            dispatch(completeOrder(orderId));
        }
        if (!ratingProvided) {
            setLoader({ rating: true });
            dispatch(provideFeedback(feedbackPayload))
        }
    }

    return (
        <>
            <SEO
                title="Book Appointments"
                description="Easily book appointments with certified birth professionals on BirthBridge. Find the right support for your prenatal, birth, and postpartum needs."
                url="/appointments"
                keywords="book appointment, BirthBridge, doulas, midwives, lactation consultants, birth support, prenatal care, postpartum care, family support"
            />
            <section className="page--banner">
                <Container>
                    <h1>Appointments</h1>
                </Container>
            </section>
            <section style={{flex:1}}className='profile--Wrapper appointments-main-wraper'>
                <Container>
                    {alert && <Alert variant={alert.variant}>{alert.message}</Alert>}
                    <Row>
                        <Col sm={12}>
                            {loader['appointments']
                                ? <LoadingView />
                                : appointments.length > 0
                                    ?
                                    <>
                                        <ButtonGroup variant="outlined" aria-label="View Toggle Buttons" style={{ marginBottom: '1rem' }}>
                                            <Button
                                                onClick={() => setToggleView(true)} // Table/List View
                                                variant={toggleView ? "contained" : "outlined"}
                                                startIcon={<AiOutlineUnorderedList />}
                                            >
                                                List View
                                            </Button>
                                            <Button
                                                onClick={() => setToggleView(false)} // Card/Grid View
                                                variant={!toggleView ? "contained" : "outlined"}
                                                startIcon={<AiOutlineAppstore />}
                                            >
                                                Grid View
                                            </Button>
                                        </ButtonGroup>


                                        {toggleView ?
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left">Order Id</TableCell>
                                                            {/* <TableCell>Category</TableCell> */}
                                                            <TableCell>Specialty</TableCell>
                                                            <TableCell>Appointment Date/Time</TableCell>
                                                            <TableCell>Booked Date/Time</TableCell>
                                                            <TableCell>Business Name</TableCell>
                                                            <TableCell>Service Provider</TableCell>
                                                            <TableCell>Business Address</TableCell>
                                                            <TableCell>Order Status</TableCell>
                                                            <TableCell>Payment Status</TableCell>
                                                            <TableCell>Total</TableCell>
                                                            <TableCell>Actions</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {appointments.map((appointment, apIndex) => {
                                                        return (

                                                            <SingleAppointment
                                                                appointment={appointment}
                                                                handleCancelAppointment={handleCancelAppointment}
                                                                loading={loader['cancel']}
                                                                handleCompleteOrder={handleCompleteOrder}
                                                                loadingComplete={loader['complete']}
                                                                loadingRating={loader['rating']}
                                                                toggleView={toggleView}
                                                            />
                                                        )
                                                    })}
                                                </Table>
                                            </TableContainer>
                                            :
                                            <section className='profile--Wrapper appointments-main-wraper'>
                                                <Container>
                                                    {alert && <Alert variant={alert.variant}>{alert.message}</Alert>}
                                                    <Row>
                                                        <Col sm={12}>
                                                            {loader['appointments']
                                                                ? <LoadingView />
                                                                : appointments.length > 0
                                                                    ? appointments.map((appointment, apIndex) => {
                                                                        return (
                                                                            <div className='Appointment_Service-main-wraper' key={apIndex}>
                                                                                <SingleAppointment
                                                                                    appointment={appointment}
                                                                                    handleCancelAppointment={handleCancelAppointment}
                                                                                    loading={loader['cancel']}
                                                                                    handleCompleteOrder={handleCompleteOrder}
                                                                                    loadingComplete={loader['complete']}
                                                                                    loadingRating={loader['rating']}
                                                                                    toggleView={toggleView}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    })
                                                                    : displayNoAppoinments()
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </section>


                                        }

                                    </>
                                    : displayNoAppoinments()
                            }
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Appointments