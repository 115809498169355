import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Box, Typography } from '@mui/material';

const FeatureCard = ({ icon, title, description }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        p: 4,
        height: '100%',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
        transition: 'transform 0.3s ease',
        '&:hover': {
          transform: 'translateY(-8px)',
        }
      }}
    >
      <Box
        sx={{
          fontSize: '32px',
          color: '#7FA1B3', // Matching the blue-grey from the image
          mb: 2
        }}
      >
        {icon}
      </Box>
      <Typography
        variant="h6"
        sx={{
          fontSize: '1.25rem',
          fontWeight: 600,
          color: '#2D3748',
          mb: 2
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: '#4A5568',
          fontSize: '1rem',
          lineHeight: 1.6
        }}
      >
        {description}
      </Typography>
    </Box>
  </motion.div>
);

const LandingPageAboutBirthBridge = () => {
  const features = [
    {
      icon: '📝',
      title: 'Showcase Your Services',
      description: 'Create a profile that highlights your specialties, certifications, and offerings—so families know how you can support them.'
    },
    {
      icon: '📅',
      title: 'Smart Scheduling Tools',
      description: 'Set your availability, manage bookings, and sync your calendar to avoid double-booking and last-minute chaos.'
    },
    {
      icon: '💸',
      title: 'Payments Made Simple',
      description: 'Get paid easily and securely with built-in invoicing, flexible payment options, and transaction tracking.'
    },
    {
      icon: '💬',
      title: 'Effortless Client Communication',
      description: 'Message clients directly through the platform to keep conversations organized and HIPAA-compliant.'
    },
    {
      icon: '🤝',
      title: 'Grow Your Referral Network',
      description: 'Connect and collaborate with other professionals in your area to share referrals and build a trusted care circle.'
    },
    {
      icon: '📈',
      title: 'Scale Without the Overwhelm',
      description: 'Spend less time on admin and more time doing what you love—with everything you need to grow your practice in one place.'
    }
  ];
  

  return (
    <Box
      component="section"
      sx={{
        py: { xs: 8, md: 12 },
        backgroundColor: '#F7FAFC'
      }}
    >
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <Typography
            variant="h1"
            align="center"
            sx={{
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              fontWeight: 700,
              color: '#1A202C',
              mb: 2
            }}
          >
            Find Your Perfect Birth Professional Today
          </Typography>
          
          <Typography
            align="center"
            sx={{
              fontSize: { xs: '1.1rem', md: '1.25rem' },
              color: '#4A5568',
              mb: 8,
              maxWidth: '800px',
              mx: 'auto'
            }}
          >
            Connect with experienced doulas, midwives, and birth professionals who provide personalized pregnancy support, labor assistance, and postpartum care in your area.
          </Typography>
        </motion.div>

        <Row>
          {features.map((feature, index) => (
            <Col key={index} xs={12} style={{marginBottom: 16}} md={6} lg={4} sx={{ m: 2 }}>
              <FeatureCard {...feature} />
            </Col>
          ))}
        </Row>

        <Box sx={{ textAlign: 'center', mt: 6 }}>
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Button
              variant="contained"
              size="large"
              sx={{
                backgroundColor: '#7FA1B3',
                fontSize: '1.1rem',
                py: 2,
                px: 4,
                borderRadius: '8px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#6B8A9D'
                }
              }}
            >
              Connect with Local Birth Professionals
            </Button>
          </motion.div>
        </Box>
      </Container>
    </Box>
  );
};

export default LandingPageAboutBirthBridge;
