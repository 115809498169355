export const GA_TRACKING_ID = 'G-E1190C55F9';

// Check if gtag is available
const isGtagAvailable = () => {
  return typeof window !== 'undefined' && typeof window.gtag === 'function';
};

// Check if we're on mybirthbridge.com
const isProductionDomain = () => {
  return window.location.hostname === 'mybirthbridge.com';
};

// Load Google Analytics Script
export const loadGoogleAnalytics = () => {
  if (isProductionDomain() && !isGtagAvailable()) {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
    script.async = true;
    document.head.appendChild(script);
  }
};

// Initialize gtag function
export const initGA = () => {
  if (isProductionDomain()) {
    window.dataLayer = window.dataLayer || [];
    if (!isGtagAvailable()) {
      window.gtag = function() {
        window.dataLayer.push(arguments);
      };
    }
    window.gtag('js', new Date());
    window.gtag('config', GA_TRACKING_ID);
  }
};

// Log page views
export const logPageView = (url) => {
  if (isProductionDomain() && isGtagAvailable()) {
    try {
      window.gtag('config', GA_TRACKING_ID, {
        page_path: url,
      });
    } catch (error) {
      console.warn('Google Analytics page view tracking failed:', error);
    }
  }
};

// Log specific events
export const logEvent = (action, category, label, value) => {
  if (isProductionDomain() && isGtagAvailable()) {
    try {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
      });
    } catch (error) {
      console.warn('Google Analytics event tracking failed:', error);
    }
  }
};