// this is family journey tracking - the family member can have multiple journies. It's essentially one journey per kid. They can have the jounrney at the same time, can overlap or can be one after another later on. It doesn't matter

//make a call for the list of all journies for a family. 

// It will be a list of cards for the journey, in the journey you can have an image being uploaded , a description, baby name, due date, 

// you can then click a card and view that journey individually (url will be /my-journey/:familyJourneyId). where it will call that api and then save the data in the response and display the information that i explained above. Inside the journey you can also invite other professionasl that are on only on our platform. You can see there the list of invites that you've invited to your journey, list of professionals that have accepted it.  

import React, { useState, useEffect } from 'react';
import { 
    Card, CardContent, Typography, Grid, 
    CircularProgress, Box, IconButton
} from '@mui/material';
import { FiEye } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import API from '../../helpers/api';
import { toast } from 'react-toastify';
import LoadingView from "../LoadingView";

const MyFamiliesJourney = () => {
    const [journeys, setJourneys] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchJourneys();
    }, []);

    const fetchJourneys = async () => {
        try {
            setLoading(true);
            const response = await API.apiGetByKey('family-journey', '/journeys');
            if (response.data?.success) {
                setJourneys(response.data.journeys);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Error fetching journeys');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Box display="flex" style={{flex:1}} justifyContent="center"><LoadingView /></Box>;
    }

    return (
        <Box sx={{flex:1, p: 3 }}>
            <Typography variant="h4" sx={{ mb: 3 }}>My Family Journeys</Typography>
            <Grid container spacing={3}>
                {journeys.map((journey) => (
                    <Grid item xs={12} sm={6} md={4} key={journey._id}>
                        <Card sx={{ 
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative'
                        }}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    {journey.familyName}'s Journey
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                                    Created: {new Date(journey.createdAt).toLocaleDateString()}
                                </Typography>
                                <Typography variant="body2" noWrap>
                                    {journey.notes}
                                </Typography>
                                
                                <IconButton 
                                    onClick={() => navigate(`/my-journey/${journey._id}`)}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        backgroundColor: 'primary.main',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                        },
                                    }}
                                    size="small"
                                >
                                    <FiEye size={20} />
                                </IconButton>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default MyFamiliesJourney;
