import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    Grid,
    Chip,
    CircularProgress,
    Button,
    List,
    ListItem,
    ListItemText,
    Divider,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Select,
    MenuItem,
    Slider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import API from '../../../helpers/api';
import { toast } from 'react-toastify';
import { birthProfessionalQuestionList } from '../../FamilyJourney/birthProfessionalQuestions';

const JourneyDetails = () => {
    const [journey, setJourney] = useState(null);
    const [loading, setLoading] = useState(true);
    const [specialtyData, setSpecialtyData] = useState({});
    const [saving, setSaving] = useState(false);
    const [showAssessmentForm, setShowAssessmentForm] = useState(false);
    const [showNoteForm, setShowNoteForm] = useState(false);
    const [note, setNote] = useState('');
    const { journeyId } = useParams();
    const navigate = useNavigate();

    const fetchJourneyDetails = async () => {
        try {
            setLoading(true);
            const response = await API.apiGetByKey('family-journey', `/professional/journey/${journeyId}`);
            if (response.data?.success) {
                setJourney(response.data.journey);
                // Initialize specialty data if it exists
                if (response.data.journey.specialtyData?.data) {
                    setSpecialtyData(response.data.journey.specialtyData.data);
                }
            }
        } catch (error) {
            toast.error('Error fetching journey details');
            navigate('/family-tracking');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchJourneyDetails();
    }, [journeyId]);

    // Get the professional's specialty from the journey data
    const getProfessionalSpecialty = () => {
        // The specialty is directly available in serviceDetails.category
        return journey?.serviceDetails?.category?.toLowerCase().replace(/\s+/g, '_');
    };

    // Add this useEffect to load existing specialty data
    useEffect(() => {
        if (journey?.supportTeam) {
            const professional = journey.supportTeam.find(
                member => member.specialtyData?.data
            );
            if (professional?.specialtyData?.data) {
                setSpecialtyData(professional.specialtyData.data);
            }
        }
    }, [journey]);

    // Render form based on question type
    const renderQuestion = (questionData, sectionKey, questionKey) => {
        const value = specialtyData[sectionKey]?.[questionKey] || '';
        const handleChange = (newValue) => {
            console.log('handleChange called:', {
                sectionKey,
                questionKey,
                oldValue: specialtyData[sectionKey]?.[questionKey],
                newValue,
                valueType: typeof newValue
            });
            
            setSpecialtyData(prev => {
                // Create a deep copy of the previous state
                const updated = JSON.parse(JSON.stringify(prev));
                
                // Ensure the section exists
                if (!updated[sectionKey]) {
                    updated[sectionKey] = {};
                }
                
                // Update the specific question value
                updated[sectionKey][questionKey] = newValue;
                
                console.log('Updated specialtyData:', updated);
                return updated;
            });
        };

        switch (questionData.type) {
            case 'text':
                return (
                    <TextField
                        fullWidth
                        value={value}
                        onChange={(e) => handleChange(e.target.value)}
                        margin="normal"
                    />
                );

            case 'boolean':
                // Ensure value is explicitly a boolean
                const boolValue = value === true ? 'true' : 'false';
                
                return (
                    <FormControl component="fieldset" fullWidth sx={{ my: 1 }}>
                        <RadioGroup
                            row
                            value={boolValue}
                            onChange={(e) => {
                                const newValue = e.target.value === 'true';
                                console.log('Radio change:', {
                                    newValue,
                                    eventValue: e.target.value,
                                    currentValue: boolValue
                                });
                                handleChange(newValue);
                            }}
                        >
                            <FormControlLabel 
                                value="true" 
                                control={<Radio />} 
                                label="Yes"
                                sx={{
                                    mr: 4,
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '0.9rem'
                                    }
                                }}
                            />
                            <FormControlLabel 
                                value="false" 
                                control={<Radio />} 
                                label="No"
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '0.9rem'
                                    }
                                }}
                            />
                        </RadioGroup>
                    </FormControl>
                );

            case 'checkbox':
                return (
                    <FormGroup>
                        {questionData.options.map((option) => (
                            <FormControlLabel
                                key={option}
                                control={
                                    <Checkbox
                                        checked={Array.isArray(value) ? value.includes(option) : false}
                                        onChange={(e) => {
                                            const newValue = Array.isArray(value) ? [...value] : [];
                                            if (e.target.checked) {
                                                newValue.push(option);
                                            } else {
                                                const index = newValue.indexOf(option);
                                                if (index > -1) newValue.splice(index, 1);
                                            }
                                            handleChange(newValue);
                                        }}
                                    />
                                }
                                label={option}
                            />
                        ))}
                    </FormGroup>
                );

            case 'dropdown':
                return (
                    <FormControl fullWidth margin="normal">
                        <Select
                            value={value}
                            onChange={(e) => handleChange(e.target.value)}
                        >
                            {questionData.options.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );

            case 'scale':
                return (
                    <Slider
                        value={value || 1}
                        min={1}
                        max={10}
                        step={1}
                        marks
                        onChange={(_, newValue) => handleChange(newValue)}
                    />
                );

            case 'number':
                return (
                    <TextField
                        type="number"
                        fullWidth
                        value={value}
                        onChange={(e) => handleChange(Number(e.target.value))}
                        margin="normal"
                    />
                );

            default:
                return null;
        }
    };

    const handleSave = async () => {
        try {
            setSaving(true);
            const specialty = getProfessionalSpecialty();
            
            const response = await API.apiPutUrl(
                'family-journey',
                `/professional/journey/${journeyId}/specialty-data`,
                {
                    specialtyData,
                    specialty
                }
            );

            if (response.data?.success) {
                toast.success('Updates saved successfully');
                setShowAssessmentForm(false);
                fetchJourneyDetails();
            }
        } catch (error) {
            toast.error('Error saving updates');
        } finally {
            setSaving(false);
        }
    };

    const handleSaveNote = async () => {
        try {
            if (!note.trim()) {
                toast.error('Please enter a note');
                return;
            }

            const response = await API.apiPostUrl(
                'family-journey',
                `/professional/journey/${journeyId}/note`,
                { note }
            );

            if (response.data?.success) {
                toast.success('Note saved successfully');
                setShowNoteForm(false);
                setNote('');
                fetchJourneyDetails(); // Refresh the journey data
            }
        } catch (error) {
            console.error('Error saving note:', error);
            toast.error('Error saving note');
        }
    };

    // Modify renderSpecialtyForm to work in a modal context
    const renderSpecialtyForm = () => {
        const specialty = getProfessionalSpecialty();
        const specialtyConfig = birthProfessionalQuestionList[specialty];
        
        if (!specialty || !specialtyConfig) {
            return (
                <Box>
                    <Typography>No specialty-specific questions available</Typography>
                    <Typography variant="caption" color="textSecondary">
                        Specialty: {specialtyConfig?.label || specialty || 'Not found'}
                    </Typography>
                </Box>
            );
        }

        return (
            <Dialog 
                open={showAssessmentForm} 
                onClose={() => setShowAssessmentForm(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    Professional Assessment - {specialtyConfig.label}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        {Object.entries(specialtyConfig.sections).map(([sectionKey, sectionConfig]) => (
                            <Box 
                                key={sectionKey} 
                                sx={{ 
                                    mb: 4,
                                    p: 2,
                                    backgroundColor: 'background.default',
                                    borderRadius: 1
                                }}
                            >
                                <Typography 
                                    variant="subtitle1" 
                                    sx={{ 
                                        mb: 2,
                                        fontWeight: 600,
                                        color: 'primary.main',
                                        letterSpacing: '0.5px'
                                    }}
                                >
                                    {sectionConfig.label}
                                </Typography>
                                {Object.entries(sectionConfig.questions).map(([questionKey, questionData]) => (
                                    <Box 
                                        key={questionKey} 
                                        sx={{ 
                                            mb: 3,
                                            p: 2,
                                            backgroundColor: 'white',
                                            borderRadius: 1,
                                            boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                                        }}
                                    >
                                        <FormLabel 
                                            sx={{ 
                                                display: 'block',
                                                mb: 1,
                                                color: 'text.primary',
                                                fontSize: '0.95rem',
                                                fontWeight: 500
                                            }}
                                        >
                                            {questionData.question}
                                        </FormLabel>
                                        {renderQuestion(questionData, sectionKey, questionKey)}
                                    </Box>
                                ))}
                            </Box>
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setShowAssessmentForm(false)}
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSave}
                        disabled={saving}
                    >
                        {saving ? (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <CircularProgress size={20} sx={{ mr: 1, color: 'white' }} />
                                Saving...
                            </Box>
                        ) : 'Save Assessment'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!journey) {
        return (
            <Box p={3}>
                <Typography variant="h6">Journey not found</Typography>
            </Box>
        );
    }

    return (
        <Box p={3}>
            <Button 
                variant="outlined" 
                onClick={() => navigate('/family-tracking')}
                sx={{ mb: 3 }}
            >
                Back to All Journeys
            </Button>

            <Paper elevation={3} sx={{ p: 3 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            {journey.familyName}'s Journey
                        </Typography>
                        <Chip 
                            label={journey.status}
                            color={journey.status === 'accepted' ? 'success' : 'default'}
                            sx={{ mt: 1 }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>Service Details</Typography>
                        <Typography>
                            Service: {journey.serviceDetails?.name || 'Not specified'}
                        </Typography>
                        <Typography>
                            Category: {journey.serviceDetails?.category || 'Not specified'}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>Important Dates</Typography>
                        <Typography>
                            Due Date: {journey.dueDate ? new Date(journey.dueDate).toLocaleDateString() : 'Not specified'}
                        </Typography>
                        <Typography>
                            Birth Date: {journey.birthDate ? new Date(journey.birthDate).toLocaleDateString() : 'Not specified'}
                        </Typography>
                    </Grid>

                    {journey.parentNames && journey.parentNames.length > 0 && (
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Parent Information</Typography>
                            <List>
                                {journey.parentNames.map((parent, index) => (
                                    <ListItem key={index}>
                                        <ListItemText 
                                            primary={parent.name}
                                            secondary={`Email: ${parent.email}, Phone: ${parent.phone || 'Not provided'}`}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    )}

                    {journey.notes && (
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Notes</Typography>
                            <Typography>{journey.notes}</Typography>
                        </Grid>
                    )}
                </Grid>
            </Paper>

            <Box sx={{ mt: 4 }}>
                <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                        <Typography variant="h6" gutterBottom color="primary">
                            Assessments & Notes
                        </Typography>
                        <Box>
                            <Button
                                variant="outlined"
                                onClick={() => setShowNoteForm(true)}
                                sx={{ mr: 2 }}
                                disabled={showNoteForm || showAssessmentForm}
                            >
                                Add Note
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => setShowAssessmentForm(true)}
                                disabled={showNoteForm || showAssessmentForm}
                            >
                                Add Assessment
                            </Button>
                        </Box>
                    </Box>

                    {journey?.supportTeam?.map(member => {
                        const currentUserId = API.getUserId();
                        if (member.professionalId.toString() !== currentUserId) return null;

                        return (
                            <Box key={member._id}>
                                {/* Show assessments */}
                                {member.specialtyData?.data && (
                                    <Box 
                                        sx={{ 
                                            mb: 2, 
                                            p: 2, 
                                            bgcolor: 'background.paper', 
                                            borderRadius: 1,
                                            borderLeft: '4px solid',
                                            borderColor: 'success.main'
                                        }}
                                    >
                                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                            Assessment - {new Date(member.specialtyData.lastUpdated).toLocaleDateString()}
                                        </Typography>
                                    </Box>
                                )}
                                
                                {/* Show notes */}
                                {member.notes?.map((noteItem, index) => (
                                    <Box 
                                        key={index}
                                        sx={{ 
                                            mb: 2, 
                                            p: 2, 
                                            bgcolor: 'background.paper', 
                                            borderRadius: 1,
                                            borderLeft: '4px solid',
                                            borderColor: 'primary.main'
                                        }}
                                    >
                                        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                            Note - {new Date(noteItem.createdAt).toLocaleString()}
                                        </Typography>
                                        <Typography>{noteItem.content}</Typography>
                                    </Box>
                                ))}

                                {/* Show message if no data exists */}
                                {(!member.specialtyData?.data && (!member.notes || member.notes.length === 0)) && (
                                    <Box sx={{ textAlign: 'center', py: 4, color: 'text.secondary' }}>
                                        <Typography>
                                            No assessments or notes have been added yet.
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        );
                    })}

                    {showNoteForm && (
                        <Box sx={{ mt: 3 }}>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                label="Add a Note"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                            />
                            <Box sx={{ mt: 2 }}>
                                <Button 
                                    variant="outlined" 
                                    onClick={() => {
                                        setShowNoteForm(false);
                                        setNote('');
                                    }}
                                    sx={{ mr: 2 }}
                                >
                                    Cancel
                                </Button>
                                <Button 
                                    variant="contained"
                                    onClick={handleSaveNote}
                                >
                                    Save Note
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Paper>
            </Box>

            {/* The assessment form will now be rendered as a modal */}
            {renderSpecialtyForm()}
        </Box>
    );
};

export default JourneyDetails; 