import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    useTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    Card,
    CardContent,
    IconButton,
    Avatar,
    Stack,
    CircularProgress,
    Chip,
    Tooltip,
    Pagination
} from '@mui/material';
import {
    FiHeart,
    FiMessageSquare,
    FiEye
} from 'react-icons/fi';
import { formatDistanceToNow } from 'date-fns';
import { communityService } from '../../services/communityService';
import { getUserProfile } from "../../redux/actions/user.action";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { FaCheckCircle, FaExternalLinkAlt } from 'react-icons/fa';
import { CATEGORY_STYLES } from './postsUtils';
import SEO from "../../seo";
import * as auth from '../../helpers/auth';
import AdUnit from './AdUnit';
import LoginSignupModal from '../auth/LoginSignupModal';
import { nameSlugify } from '../../utils/nameHelper';

const ActionButton = styled(IconButton)(({ theme, isactive }) => ({
    transition: 'all 0.2s ease-in-out',
    padding: '8px',
    borderRadius: '8px',
    color: isactive === 'true' ? '#73a5b5' : 'inherit',
    backgroundColor: isactive === 'true' ? 'rgba(115, 165, 181, 0.1)' : 'transparent',
    '&:hover': {
        backgroundColor: isactive === 'true'
            ? 'rgba(115, 165, 181, 0.2)'
            : theme.palette.action.hover,
        transform: 'scale(1.05)',
    },
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
    '& .MuiPaginationItem-root': {
        '&.Mui-selected': {
            backgroundColor: '#73a5b5',
            color: 'white',
            '&:hover': {
                backgroundColor: '#5d8a99',
            },
        },
        '&:hover': {
            backgroundColor: 'rgba(115, 165, 181, 0.1)',
        },
    },
}));

const PostsList = ({ category, searchQuery, posts, setPosts }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [allPosts, setAllPosts] = useState([]);
    const [initialLoadDone, setInitialLoadDone] = useState(false);
    const [openAuthModal, setOpenAuthModal] = useState(false);
    const loggedIn = auth.isAuth();
    // const [sellerProfileData, setSellerProfileData] = useState(null);
    // const userData

    const sellerProfileData = useSelector(state => state.user);

    // // const userId = sellerProfileData?.userDetail?._id;

    useEffect(() => {
        // const loggedIn = auth.isAuth();
        if (loggedIn) {
            dispatch(getUserProfile());
        }
    }, [dispatch]);

    const handleLogin = () => {
        navigate('/login');
        setOpenAuthModal(false);
    };

    const handleProfessionalSignup = () => {
        navigate('/bridger-signup');
        setOpenAuthModal(false);
    };

    const handleFamilySignup = () => {
        navigate('/family-signup');
        setOpenAuthModal(false);
    };


    const fetchPosts = async (pageNum) => {
        try {
            if (!initialLoadDone) {
                setLoading(true);
            }
            const response = await communityService.getAllPosts(pageNum);

            if (response.data.success) {
                const newPosts = response.data.data;
                setAllPosts(newPosts);
                if (!posts.some(post => !post._id)) {
                    setPosts(newPosts);
                }
                setTotalPages(response.data.pagination.totalPages);
            }
        } catch (error) {
            console.error('Error fetching posts:', error);
        } finally {
            setLoading(false);
            setInitialLoadDone(true);
        }
    };

    useEffect(() => {
        if (!initialLoadDone || category) {
            setPage(1);
            fetchPosts(1);
        }
    }, [category]);

    useEffect(() => {
        if (posts.length > 0) {
            setAllPosts(posts);
        }
    }, [posts]);

    const getCategoryStyles = (category) => {
        return CATEGORY_STYLES[category] || {
            color: '#1976d2',
            backgroundColor: '#e3f2fd'
        };
    };

    const handleUpvote = async (postId) => {
        if (!sellerProfileData?.userDetail?._id) {
            setOpenAuthModal(true);
        } else {
            try {
                const response = await communityService.upvotePost(postId);
                if (response.data.success) {
                    // Update both allPosts and posts states
                    const updatePosts = (prevPosts) =>
                        prevPosts.map(post => {
                            if (post._id === postId) {
                                const userLikedIndex = post.likes.findIndex(like =>
                                    typeof like === 'string' ? like === sellerProfileData?.userDetail?._id : like._id === sellerProfileData?.userDetail?._id
                                );

                                const updatedLikes = userLikedIndex >= 0
                                    ? post.likes.filter((_, index) => index !== userLikedIndex)
                                    : [...post.likes, sellerProfileData?.userDetail?._id];

                                return {
                                    ...post,
                                    likes: updatedLikes
                                };
                            }
                            return post;
                        });

                    setAllPosts(prevPosts => updatePosts(prevPosts));
                    setPosts(prevPosts => updatePosts(prevPosts));
                }
            } catch (error) {
                console.error('Error liking post:', error);
            }
        }
    };

    const handlePostClick = (postId) => {
        navigate(`/community/posts/${postId}`);
    };



    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        fetchPosts(newPage);
        // Scroll to top smoothly
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const filteredPosts = allPosts.filter(post => {
        const matchesCategory = category === 'all' || post.category === category;
        const matchesSearch = !searchQuery ||
            post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            post.description.toLowerCase().includes(searchQuery.toLowerCase());
        return matchesCategory && matchesSearch;
    });

    const isPostLiked = (post) => {
        if (!sellerProfileData?.userDetail?._id || !post.likes) return false;
        return post.likes.some(like =>
            typeof like === 'string' ? like === sellerProfileData?.userDetail?._id : like._id === sellerProfileData?.userDetail?._id
        );
    };

    if (loading) {
        return (
            <Box
                sx={{
                    p: 3,
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '50vh'
                }}
            >
                <CircularProgress />
            </Box>
        );
    }


    return (
        <>
            <SEO
                title="Community | BirthBridge"
                description="Join our vibrant birth community forum. Connect with birth professionals, share experiences, and get support from doulas, midwives, and other verified birth workers."
                url="/community"
                keywords="birth community, birth forum, birth professionals, doulas, midwives, birth workers, pregnancy support, birth support, birth discussion"
                canonical={`${window.location.origin}/community`}
                type="website"
            />

            <Stack spacing={2} id="post-list">
                {filteredPosts.map(post => {

                    const isVerified = post.author?.is_business_approved;

                    return (
                        <Card
                            key={post._id}
                            onClick={() => handlePostClick(post._id)}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    boxShadow: 3,
                                    transform: 'translateY(-2px)',
                                    transition: 'all 0.2s ease-in-out'
                                }
                            }}
                        >
                            <CardContent>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', mb: 1 }}>
                                    <Avatar
                                        src={post.author?.profilePic?.url || ''}
                                        alt={post.author?.name || 'User'}
                                        sx={{
                                            mr: 2,
                                            width: 40,
                                            height: 40
                                        }}
                                    >
                                        {post.author?.name?.charAt(0)}
                                    </Avatar>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="subtitle1">
                                            {post.author?.name || 'Anonymous'}
                                        </Typography>
                                        {isVerified && (
                                            <>
                                                <Tooltip title="Verified Business with BirthBridge" arrow>
                                                    <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 0.5 }}>
                                                        <FaCheckCircle
                                                            size={16}
                                                            color="#1976d2"
                                                            style={{ marginLeft: '8px' }}
                                                        />
                                                    </Box>
                                                </Tooltip>
                                                <Tooltip title={`View ${post.author?.name}'s Business Profile`} arrow>                                            <Box
                                                    component="span"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        window.open(`${window.location.origin}/${nameSlugify(post.author?.name)}/${post.author?._id}`, '_blank');
                                                    }}
                                                    sx={{
                                                        display: 'inline-flex',
                                                        alignItems: 'center',
                                                        ml: 1,
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            opacity: 0.8
                                                        }
                                                    }}
                                                >
                                                    <FaExternalLinkAlt
                                                        size={12}
                                                        color="#1976d2"
                                                    />
                                                </Box>
                                                </Tooltip>
                                            </>
                                        )}
                                    </Box>

                                    <Chip
                                        label={post.category}
                                        size="small"
                                        sx={{
                                            ml: 'auto',
                                            ...getCategoryStyles(post.category)
                                        }}
                                    />
                                </Box>


                                <Typography
                                    variant="caption"
                                    color="text.secondary"
                                    sx={{ display: 'block', mt: 0.5 }}
                                >
                                    Posted: {formatDistanceToNow(new Date(post.createdAt), { addSuffix: true })}
                                </Typography>



                                <Typography variant="h6" gutterBottom>
                                    {post.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        mb: 2,
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden'
                                    }}
                                >
                                    {post.description}
                                </Typography>

                                <Box
                                    onClick={handleUpvote}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <ActionButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleUpvote(post._id);
                                            }}
                                            isactive={isPostLiked(post).toString()}
                                        >
                                            <FiHeart
                                                size={18}
                                                fill={isPostLiked(post) ? '#73a5b5' : 'none'}
                                                stroke={isPostLiked(post) ? '#73a5b5' : 'currentColor'}
                                            />
                                        </ActionButton>
                                        <Typography
                                            variant="body2"
                                            color={isPostLiked(post) ? '#73a5b5' : 'text.secondary'}
                                        >
                                            {post.likes.length}
                                        </Typography>
                                    </Box>

                                    <Box
                                        onClick={() => handlePostClick(post._id)}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <FiMessageSquare size={18} />
                                        <Typography variant="caption">
                                            {post?.replies?.length || 0}
                                        </Typography>
                                    </Box>

                                    {/* <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        color: 'text.secondary'
                                    }}>
                                        <FiEye size={18} />
                                        <Typography variant="caption">
                                            {post?.views || 0}
                                        </Typography>
                                    </Box> */}

                                    {post?.status && post.status !== 'active' && (
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                ml: 'auto',
                                                color: 'warning.main'
                                            }}
                                        >
                                            {post.status}
                                        </Typography>
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                    );
                })}

                {/* <AdUnit slot={'4626134585'} /> */}

                {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
                        <CircularProgress sx={{ color: '#73a5b5' }} />
                    </Box>
                )}

                {!loading && filteredPosts.length === 0 && (
                    <>


                        <Box sx={{ textAlign: 'center', py: 4 }}>
                            <Typography variant="body1" color="text.secondary">
                                No posts found
                            </Typography>
                        </Box>
                    </>
                )}


                {totalPages > 1 && (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        py: 3
                    }}>
                        <StyledPagination
                            count={totalPages}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                            size="large"
                            showFirstButton
                            showLastButton
                            siblingCount={1}
                            boundaryCount={1}
                        // disabled={filteredPosts.length === 0}
                        />
                    </Box>
                )}
            </Stack>

            <LoginSignupModal 
                open={openAuthModal}
                onClose={() => setOpenAuthModal(false)}
            />
        </>
    );
};

export default PostsList; 