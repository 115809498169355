// import moment from 'moment';
import React from 'react';
import { generateLetterSVG, momentFormat } from '../../utils/validations';
import RatingReadOnly from '../../components/RatingReadOnly';
import { optimizedCloudinaryImage } from '../../utils/fileCompression';

const CustomReviewCard = ({ review, key, reviews, singleBusinessDetails }) => {

    if (!review) {
        return null
    }

    return (
        <div className="reviews--single" key={key}>
            <div className="user--Image">
                <div className="user--review--detail">
                    <h6>
                        {review.hideName ? 'Anonymous' : review.buyer.name}
                        <span>{momentFormat.fullDate(review.createdAt)}</span>
                    </h6>
                    <RatingReadOnly value={review.rating} reviews={reviews} />
                </div>
            </div>
            <div className="user--Rating">
                <span className="mt-2">{review.review}</span>
            </div>
        </div>
    )
}

export default CustomReviewCard;