// import styles from '../../styles/Home.module.css';
// import Image from 'next/image';
import { Typography } from '@mui/material';
import { Container, Row, Col, Button } from "react-bootstrap";
import messaging from './images/messaging.png';
import reviews from './images/reviews.png';
import bookingscheduling from './images/bookingscheduling.png';
import payments from './images/payments.png';
import professionalprofile from './images/professionalprofile.png';
import trustedconnections from './images/trustedconnections.png';
import mobile from './images/mobile.png';
import marketing from './images/marketing.png';
import './ourServices.css'



export default function OurServices() {
    return (
        <section className="popular--Wrapper pt-5">
            <Container>
                <Row className="align-items-center flex-row-reverse">
                    {/* Image */}
                    <Col>

                        <div>
                            <header style={{ display: 'flex', justifyContent: 'center' }}>
                                <h2>Specialties on Our Marketplace</h2>
                                {/* <img
                                    loading='lazy'
                                    src={HeaderSeperator}
                                    alt="Decorative icon separating BirthBridge services"
                                    width={100}
                                    height={100}
                                    style={{ borderRadius: '100px', margin: 4 }}
                                    layout="intrinsic"
                                    priority
                                /> */}
                            </header>
                            <div style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                            }}>

                                {/* Service 1: Doulas */}
                                <a href="/local-birth-professionals" style={{ textDecoration: 'unset' }}>

                                    <article className={'featuresItemsLg'} aria-labelledby="doulas">
                                        <img
                                            loading='lazy'
                                            src={professionalprofile}
                                            alt="Icon for doula support on BirthBridge"
                                            width={50}
                                            height={50}
                                            style={{ borderRadius: '100px', margin: 4 }}
                                            layout="intrinsic"
                                            priority
                                        />
                                        <Typography id="doulas" className={'serviceTitle'} variant="h6" align="center">Doulas</Typography>
                                        <Typography variant="body2" className={'serviceBody'} align="center">
                                            Compassionate support throughout pregnancy, birth, and postpartum, tailored to each family's unique needs.
                                        </Typography>
                                    </article>
                                </a>


                                <a href="/local-birth-professionals" style={{ textDecoration: 'unset' }}>

                                    {/* Service 2: Lactation Consultants */}
                                    <article className={'featuresItemsLg'} aria-labelledby="lactation-consultants">
                                        <img
                                            loading='lazy'
                                            src={bookingscheduling}
                                            alt="Icon for lactation consulting services on BirthBridge"
                                            width={50}
                                            height={50}
                                            style={{ borderRadius: '100px', margin: 4 }}
                                            layout="intrinsic"
                                            priority
                                        />
                                        <Typography id="lactation-consultants" className={'serviceTitle'} variant="h6" align="center">Lactation Consultants</Typography>
                                        <Typography variant="body2" className={'serviceBody'} align="center">
                                            Expert breastfeeding guidance and support for new mothers and families.
                                        </Typography>
                                    </article>
                                </a>


                                <a href="/local-birth-professionals" style={{ textDecoration: 'unset' }}>

                                    {/* Service 3: Prenatal & Postpartum Yoga Instructors */}
                                    <article className={'featuresItemsLg'} aria-labelledby="yoga-instructors">
                                        <img
                                            loading='lazy'
                                            src={payments}
                                            alt="Icon for prenatal and postpartum yoga services on BirthBridge"
                                            width={50}
                                            height={50}
                                            style={{ borderRadius: '100px', margin: 4 }}
                                            layout="intrinsic"
                                            priority
                                        />
                                        <Typography id="yoga-instructors" className={'serviceTitle'} variant="h6" align="center">Prenatal & Postpartum Yoga Instructors</Typography>
                                        <Typography variant="body2" className={'serviceBody'} align="center">
                                            Safe, supportive yoga tailored for the journey of pregnancy and recovery.
                                        </Typography>
                                    </article>
                                </a>

                                <a href="/local-birth-professionals" style={{ textDecoration: 'unset' }}>

                                    {/* Service 4: Childbirth Educators */}
                                    <article className={'featuresItemsLg'} aria-labelledby="childbirth-educators">
                                        <img
                                            loading='lazy'
                                            src={trustedconnections}
                                            alt="Icon for childbirth education services on BirthBridge"
                                            width={50}
                                            height={50}
                                            style={{ borderRadius: '100px', margin: 4 }}
                                            layout="intrinsic"
                                            priority
                                        />
                                        <Typography id="childbirth-educators" className={'serviceTitle'} variant="h6" align="center">Childbirth Educators</Typography>
                                        <Typography variant="body2" className={'serviceBody'} align="center">
                                            Empowering families with knowledge for a confident, prepared birth experience.
                                        </Typography>
                                    </article>
                                </a>

                                <a href="/local-birth-professionals" style={{ textDecoration: 'unset' }}>

                                    {/* Service 5: Mental Health Therapists */}
                                    <article className={'featuresItemsLg'} aria-labelledby="mental-health-therapists">
                                        <img
                                            loading='lazy'
                                            src={reviews}
                                            alt="Icon for mental health support services on BirthBridge"
                                            width={50}
                                            height={50}
                                            style={{ borderRadius: '100px', margin: 4 }}
                                            layout="intrinsic"
                                            priority
                                        />
                                        <Typography id="mental-health-therapists" className={'serviceTitle'} variant="h6" align="center">Mental Health Therapists</Typography>
                                        <Typography variant="body2" className={'serviceBody'} align="center">
                                            Specialized support for maternal mental health and family wellness.
                                        </Typography>
                                    </article>
                                </a>


                                <a href="/local-birth-professionals" style={{ textDecoration: 'unset' }}>

                                    {/* Service 6: Nutritional Counseling */}
                                    <article className={'featuresItemsLg'} aria-labelledby="nutritional-counseling">
                                        <img
                                            loading='lazy'
                                            src={messaging}
                                            alt="Icon for nutritional counseling services on BirthBridge"
                                            width={50}
                                            height={50}
                                            style={{ borderRadius: '100px', margin: 4 }}
                                            layout="intrinsic"
                                            priority
                                        />
                                        <Typography id="nutritional-counseling" className={'serviceTitle'} variant="h6" align="center">Nutritional Counseling</Typography>
                                        <Typography variant="body2" className={'serviceBody'} align="center">
                                            Guidance on healthy eating for pregnancy and postpartum recovery.
                                        </Typography>
                                    </article>
                                </a>


                                <a href="/local-birth-professionals" style={{ textDecoration: 'unset' }}>

                                    {/* Service 7: Massage Therapy */}
                                    <article className={'featuresItemsLg'} aria-labelledby="massage-therapy">
                                        <img
                                            loading='lazy'
                                            src={mobile}
                                            alt="Icon for massage therapy services on BirthBridge"
                                            width={50}
                                            height={50}
                                            style={{ borderRadius: '100px', margin: 4 }}
                                            layout="intrinsic"
                                            priority
                                        />
                                        <Typography id="massage-therapy" className={'serviceTitle'} variant="h6" align="center">Massage Therapy</Typography>
                                        <Typography variant="body2" className={'serviceBody'} align="center">
                                            Relieve stress and discomfort with specialized prenatal and postpartum massage.
                                        </Typography>
                                    </article>
                                </a>


                                <a href="/local-birth-professionals" style={{ textDecoration: 'unset' }}>

                                    {/* Service 8: Pelvic Floor Specialists */}
                                    <article className={'featuresItemsLg'} aria-labelledby="pelvic-floor-specialists">
                                        <img
                                            loading='lazy'
                                            src={marketing}
                                            alt="Icon for pelvic floor therapy services on BirthBridge"
                                            width={50}
                                            height={50}
                                            style={{ borderRadius: '100px', margin: 4 }}
                                            layout="intrinsic"
                                            priority
                                        />
                                        <Typography id="pelvic-floor-specialists" className={'serviceTitle'} variant="h6" align="center">Pelvic Floor Specialists</Typography>
                                        <Typography variant="body2" className={'serviceBody'} align="center">
                                            Support for pelvic health and recovery after childbirth.
                                        </Typography>
                                    </article>
                                </a>




                                <a href="/local-birth-professionals" style={{ textDecoration: 'unset' }}>

                                    <article className={'featuresItemsLg'} aria-labelledby="pelvic-floor-specialists">
                                        <img
                                            loading='lazy'
                                            src={marketing}
                                            alt="Icon for pelvic floor therapy services on BirthBridge"
                                            width={50}
                                            height={50}
                                            style={{ borderRadius: '100px', margin: 4 }}
                                            layout="intrinsic"
                                            priority
                                        />
                                        <Typography id="pelvic-floor-specialists" className={'serviceTitle'} variant="h6" align="center">Sleep Consultants</Typography>
                                        <Typography variant="body2" className={'serviceBody'} align="center">
                                            Provide expert guidance on establishing healthy sleep patterns for infants, children, and families.¸
                                        </Typography>
                                    </article>
                                </a>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}> 
                                <Button
                                    href="/local-birth-professionals"
                                    style={{
                                        backgroundColor: '#73a5b5',
                                        marginTop: '24px',
                                        padding: '12px',
                                        textAlign: 'center',
                                        width: '350px',
                                        maxWidth: 'fit-content',
                                        color: 'white',
                                    }}>
                                    Build your Birth Team
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}