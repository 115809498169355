import React, { useState, useEffect } from 'react';
import {
    Card, CardContent, Typography, Grid, Button,
    CircularProgress, Box, TextField, List, ListItem,
    ListItemText, Dialog, DialogTitle, DialogContent,
    DialogActions, Chip, IconButton, Divider,
    FormControl, InputLabel, Input, Stack, MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import { MdEdit, MdSave, MdDelete, MdAdd, MdVisibility, MdExpandMore } from 'react-icons/md';
import { FiEdit, FiSave, FiTrash2, FiPlus, FiEye } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import API from '../../helpers/api';
import { toast } from 'react-toastify';
import LoadingView from "../LoadingView";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ProfessionalSearch } from './ProfessionalSearch';
import {birthProfessionalQuestionList} from './birthProfessionalQuestions';
const MySingleFamilyJourney = () => {
    const [journey, setJourney] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openInviteDialog, setOpenInviteDialog] = useState(false);
    const [inviteEmail, setInviteEmail] = useState('');
    const [newBabyName, setNewBabyName] = useState({ name: '', gender: '' });
    const { familyJourneyId } = useParams();
    const [addingBabyName, setAddingBabyName] = useState(false);
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [editedJourney, setEditedJourney] = useState(null);

    // New family member states
    const [newFamilyMember, setNewFamilyMember] = useState({ 
        name: '', 
        relationship: '', 
        email: '', 
        phone: '', 
        address: '' 
    });
    const [newEmergencyContact, setNewEmergencyContact] = useState({ name: '', relationship: '', phone: '' });
    const [newParentName, setNewParentName] = useState({ 
        name: '', 
        relationship: '', 
        email: '', 
        phone: '', 
        address: '' 
    });

    useEffect(() => {
        fetchJourneyDetails();
    }, [familyJourneyId]);

    useEffect(() => {
        if (journey && !editedJourney) {
            setEditedJourney({ ...journey });
        }
    }, [journey]);

    const fetchJourneyDetails = async () => {
        try {
            setLoading(true);
            const response = await API.apiGetByKey('family-journey', `/journey/${familyJourneyId}`);
            if (response.data?.success) {
                setJourney(response.data.journey);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Error fetching journey details');
        } finally {
            setLoading(false);
        }
    };

    const handleInviteProfessional = async () => {
        try {
            const response = await API.apiPostUrl('family-journey', `/journey/${familyJourneyId}/invite`, {
                email: inviteEmail
            });
            if (response.data?.success) {
                setJourney(prevJourney => ({
                    ...prevJourney,
                    supportTeam: [
                        ...prevJourney.supportTeam,
                        {
                            email: inviteEmail,
                            status: 'pending',
                            invitedAt: new Date()
                        }
                    ]
                }));
                toast.success('Invitation sent successfully');
                setInviteEmail('');
                setOpenInviteDialog(false);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Error sending invitation');
        }
    };

    const handleAddBabyName = () => {
        if (newBabyName.name.trim()) {
            setEditedJourney(prev => ({
                ...prev,
                possibleBirthNames: [
                    ...(prev.possibleBirthNames || []),
                    { name: newBabyName.name, gender: newBabyName.gender }
                ]
            }));
            setNewBabyName({ name: '', gender: '' });
        }
    };

    const handleDeleteBabyName = async (nameId) => {
        try {
            const response = await API.apiDelete('family-journey', `/journey/${familyJourneyId}/baby-name/${nameId}`);
            if (response.data?.success) {
                setJourney(prevJourney => ({
                    ...prevJourney,
                    possibleBirthNames: prevJourney.possibleBirthNames.filter(
                        name => name._id !== nameId
                    )
                }));
                toast.success('Baby name removed successfully');
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Error removing baby name');
        }
    };

    const handleStartEdit = () => {
        setEditing(true);
        setEditedJourney({ ...journey });
    };

    const handleSaveChanges = async () => {
        try {
            setSaving(true);
            const response = await API.apiPutUrl('family-journey', `/journey/${familyJourneyId}`, editedJourney);
            if (response.data?.success) {
                setJourney(response.data.journey);
                setEditing(false);
                toast.success('Journey updated successfully');
            }
        } catch (error) {
            console.log('error.response', error);
            toast.error(error.response?.data?.message || 'Error updating journey');
        } finally {
            setSaving(false);
        }
    };

    const handleAddFamilyMember = () => {
        if (newFamilyMember.name && newFamilyMember.relationship) {
            setEditedJourney(prev => ({
                ...prev,
                otherFamilyMembers: [...(prev.otherFamilyMembers || []), { ...newFamilyMember }]
            }));
            setNewFamilyMember({ name: '', relationship: '', email: '', phone: '', address: '' });
        }
    };

    const handleAddEmergencyContact = () => {
        if (newEmergencyContact.name && newEmergencyContact.phone) {
            setEditedJourney(prev => ({
                ...prev,
                emergencyContacts: [...(prev.emergencyContacts || []), { ...newEmergencyContact }]
            }));
            setNewEmergencyContact({ name: '', relationship: '', phone: '' });
        }
    };

    const handleDeleteParent = async (index) => {
        try {
            const response = await API.apiDelete(
                'family-journey', 
                `/journey/${familyJourneyId}/parent/${index}`
            );
            if (response.data?.success) {
                setEditedJourney(prev => ({
                    ...prev,
                    parentNames: prev.parentNames.filter((_, i) => i !== index)
                }));
                toast.success('Parent removed successfully');
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Error removing parent');
        }
    };

    const handleDeleteFamilyMember = async (index) => {
        try {
            const response = await API.apiDelete(
                'family-journey', 
                `/journey/${familyJourneyId}/family-member/${index}`
            );
            if (response.data?.success) {
                setEditedJourney(prev => ({
                    ...prev,
                    otherFamilyMembers: prev.otherFamilyMembers.filter((_, i) => i !== index)
                }));
                toast.success('Family member removed successfully');
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Error removing family member');
        }
    };

    const handleRemoveSupportTeamMember = async (memberId) => {
        try {
            const response = await API.apiDelete(
                'family-journey', 
                `/journey/${familyJourneyId}/support-team/${memberId}`
            );
            if (response.data?.success) {
                setEditedJourney(prev => ({
                    ...prev,
                    supportTeam: prev.supportTeam.filter(member => member._id !== memberId)
                }));
                toast.success('Team member removed successfully');
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Error removing team member');
        }
    };

    if (loading) {
        return <Box display="flex" style={{ flex: 1 }} justifyContent="center"><LoadingView /></Box>;
    }


    if (!journey) {
        return <Typography>Journey not found</Typography>;
    }


    // Update the styles constant
    const styles = `
      .date-picker-input {
          padding: 8px 12px;
          border: 1px solid #ccc;
          border-radius: 4px;
          width: 200px;
          font-size: 14px;
      }
      
      .date-picker-input:focus {
          border-color: #1976d2;
          outline: none;
      }
      
      .react-datepicker-wrapper {
          display: block;
      }

      .react-datepicker-popper {
          z-index: 2;
      }

      .react-datepicker {
          font-family: inherit;
          border-radius: 4px;
          border: 1px solid #ccc;
      }
  `;


    // Render the edit form
    const renderEditForm = () => (
        <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom>Edit Journey Details</Typography>

            {/* Parent Names Section */}
            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>Parent Names</Typography>
                <Stack spacing={2} sx={{ mb: 2 }}>
                    <TextField
                        size="small"
                        value={newParentName.name}
                        onChange={(e) => setNewParentName(prev => ({
                            ...prev,
                            name: e.target.value
                        }))}
                        placeholder="Parent name"
                    />
                    <TextField
                        size="small"
                        value={newParentName.relationship}
                        onChange={(e) => setNewParentName(prev => ({
                            ...prev,
                            relationship: e.target.value
                        }))}
                        placeholder="Relationship to baby"
                    />
                    <TextField
                        size="small"
                        value={newParentName.email}
                        onChange={(e) => setNewParentName(prev => ({
                            ...prev,
                            email: e.target.value
                        }))}
                        placeholder="Email"
                    />
                    <TextField
                        size="small"
                        value={newParentName.phone}
                        onChange={(e) => setNewParentName(prev => ({
                            ...prev,
                            phone: e.target.value
                        }))}
                        placeholder="Phone"
                    />
                    <TextField
                        size="small"
                        value={newParentName.address}
                        onChange={(e) => setNewParentName(prev => ({
                            ...prev,
                            address: e.target.value
                        }))}
                        placeholder="Address"
                    />
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (newParentName.name && newParentName.relationship) {
                                setEditedJourney(prev => ({
                                    ...prev,
                                    parentNames: [...(prev.parentNames || []), { ...newParentName }]
                                }));
                                setNewParentName({ name: '', relationship: '', email: '', phone: '', address: '' });
                            }
                        }}
                        disabled={!newParentName.name || !newParentName.relationship}
                        startIcon={<FiPlus size={20} />}
                    >
                        Add Parent
                    </Button>
                </Stack>
                <List>
                    {editedJourney?.parentNames?.map((parent, index) => (
                        <ListItem
                            key={index}
                            secondaryAction={
                                <IconButton
                                    edge="end"
                                    onClick={() => handleDeleteParent(index)}
                                >
                                    <FiTrash2 size={20} />
                                </IconButton>
                            }
                        >
                            <ListItemText
                                primary={`${parent.name} (${parent.relationship})`}
                                secondary={
                                    <>
                                        {parent.email && `Email: ${parent.email}`}<br />
                                        {parent.phone && `Phone: ${parent.phone}`}<br />
                                        {parent.address && `Address: ${parent.address}`}
                                    </>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </Box>

            {/* Birth Support Team Section */}
            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>Birth Support Team Members</Typography>
                <List>
                    {editedJourney?.supportTeam
                        ?.filter(member => member.status === 'accepted')
                        ?.map((member, index) => (
                            <ListItem
                                key={index}
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        onClick={() => handleRemoveSupportTeamMember(member._id)}
                                    >
                                        <FiTrash2 size={20} />
                                    </IconButton>
                                }
                            >
                                <ListItemText
                                    primary={member.email}
                                    secondary={
                                        <>
                                            Status: {member.status}
                                            {member.serviceDetails && (
                                                <>
                                                    <br />
                                                    Service: {member.serviceDetails.name}
                                                    <br />
                                                    Category: {member.serviceDetails.category}
                                                </>
                                            )}
                                            {member.specialtyData && (
                                                <>
                                                    <br />
                                                    Specialty: {member.specialtyData.specialty.replace(/_/g, ' ')}
                                                </>
                                            )}
                                        </>
                                    }
                                />
                            </ListItem>
                        ))}
                </List>
            </Box>

            <ProfessionalSearch
                journeyId={journey._id}
                onInviteSent={() => {
                    // Optionally refresh journey data here
                }}
            />

            {/* Important Dates */}
            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>Important Dates</Typography>
                <Stack spacing={2}>
                    <div style={{ position: 'relative', width: '300px' }}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Due Date
                        </Typography>
                        <DatePicker
                            selected={editedJourney?.dueDate ? new Date(editedJourney.dueDate) : null}
                            onChange={(date) => {
                                setEditedJourney(prev => ({
                                    ...prev,
                                    dueDate: date
                                }));
                            }}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Select due date"
                            isClearable
                            popperPlacement="bottom"
                            minDate={new Date()}
                        />
                    </div>
                    <div style={{ position: 'relative', width: '300px' }}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Birth Date
                        </Typography>
                        <DatePicker
                            selected={editedJourney?.birthDate ? new Date(editedJourney.birthDate) : null}
                            onChange={(date) => {
                                setEditedJourney(prev => ({
                                    ...prev,
                                    birthDate: date
                                }));
                            }}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Select birth date"
                            isClearable
                            popperPlacement="bottom"
                            minDate={new Date()}
                        />
                    </div>
                </Stack>
            </Box>

            {/* Other Family Members */}
            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>Other Family Members</Typography>
                <Stack spacing={2} sx={{ mb: 2 }}>
                    <TextField
                        size="small"
                        label="Name"
                        value={newFamilyMember.name}
                        onChange={(e) => setNewFamilyMember(prev => ({
                            ...prev,
                            name: e.target.value
                        }))}
                    />
                    <TextField
                        size="small"
                        label="Relationship"
                        value={newFamilyMember.relationship}
                        onChange={(e) => setNewFamilyMember(prev => ({
                            ...prev,
                            relationship: e.target.value
                        }))}
                    />
                    <TextField
                        size="small"
                        label="Email"
                        value={newFamilyMember.email}
                        onChange={(e) => setNewFamilyMember(prev => ({
                            ...prev,
                            email: e.target.value
                        }))}
                    />
                    <TextField
                        size="small"
                        label="Phone"
                        value={newFamilyMember.phone}
                        onChange={(e) => setNewFamilyMember(prev => ({
                            ...prev,
                            phone: e.target.value
                        }))}
                    />
                    <TextField
                        size="small"
                        label="Address"
                        value={newFamilyMember.address}
                        onChange={(e) => setNewFamilyMember(prev => ({
                            ...prev,
                            address: e.target.value
                        }))}
                    />
                    <Button
                        variant="contained"
                        onClick={handleAddFamilyMember}
                        disabled={!newFamilyMember.name || !newFamilyMember.relationship}
                    >
                        Add Family Member
                    </Button>
                </Stack>
                <List>
                    {editedJourney?.otherFamilyMembers?.map((member, index) => (
                        <ListItem
                            key={index}
                            secondaryAction={
                                <IconButton
                                    edge="end"
                                    onClick={() => handleDeleteFamilyMember(index)}
                                >
                                    <FiTrash2 size={20} />
                                </IconButton>
                            }
                        >
                            <ListItemText
                                primary={`${member.name} (${member.relationship})`}
                                secondary={
                                    <>
                                        {member.email && `Email: ${member.email}`}<br />
                                        {member.phone && `Phone: ${member.phone}`}<br />
                                        {member.address && `Address: ${member.address}`}
                                    </>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </Box>

            {/* Emergency Contacts */}
            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>Emergency Contacts</Typography>
                <Stack spacing={2} sx={{ mb: 2 }}>
                    <TextField
                        size="small"
                        label="Name"
                        value={newEmergencyContact.name}
                        onChange={(e) => setNewEmergencyContact(prev => ({
                            ...prev,
                            name: e.target.value
                        }))}
                    />
                    <TextField
                        size="small"
                        label="Relationship"
                        value={newEmergencyContact.relationship}
                        onChange={(e) => setNewEmergencyContact(prev => ({
                            ...prev,
                            relationship: e.target.value
                        }))}
                    />
                    <TextField
                        size="small"
                        label="Phone"
                        value={newEmergencyContact.phone}
                        onChange={(e) => setNewEmergencyContact(prev => ({
                            ...prev,
                            phone: e.target.value
                        }))}
                    />
                    <Button
                        variant="contained"
                        onClick={handleAddEmergencyContact}
                        disabled={!newEmergencyContact.name || !newEmergencyContact.phone}
                    >
                        Add Emergency Contact
                    </Button>
                </Stack>
                <List>
                    {editedJourney?.emergencyContacts?.map((contact, index) => (
                        <ListItem
                            key={index}
                            secondaryAction={
                                <IconButton
                                    edge="end"
                                    onClick={() => {
                                        setEditedJourney(prev => ({
                                            ...prev,
                                            emergencyContacts: prev.emergencyContacts.filter((_, i) => i !== index)
                                        }));
                                    }}
                                >
                                    <FiTrash2 size={20} />
                                </IconButton>
                            }
                        >
                            <ListItemText
                                primary={contact.name}
                                secondary={`${contact.relationship} - ${contact.phone}`}
                            />
                        </ListItem>
                    ))}
                </List>
            </Box>

            {/* Possible Baby Names */}
            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>Possible Baby Names</Typography>
                <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                    <TextField
                        size="small"
                        value={newBabyName.name}
                        onChange={(e) => setNewBabyName(prev => ({
                            ...prev,
                            name: e.target.value
                        }))}
                        placeholder="Add a baby name"
                    />
                    <TextField
                        size="small"
                        value={newBabyName.gender}
                        onChange={(e) => setNewBabyName(prev => ({
                            ...prev,
                            gender: e.target.value
                        }))}
                        placeholder="Gender"
                    />
                    <Button
                        variant="contained"
                        onClick={handleAddBabyName}
                        disabled={!newBabyName.name.trim()}
                        startIcon={<FiPlus size={20} />}
                    >
                        Add Name
                    </Button>
                </Stack>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {editedJourney?.possibleBirthNames?.map((nameObj, index) => (
                        <Chip
                            key={index}
                            label={`${nameObj.name}${nameObj.gender ? ` (${nameObj.gender})` : ''}`}
                            onDelete={() => {
                                setEditedJourney(prev => ({
                                    ...prev,
                                    possibleBirthNames: prev.possibleBirthNames.filter((_, i) => i !== index)
                                }));
                            }}
                        />
                    ))}
                </Box>
            </Box>

            {/* Birth Name (once chosen) */}
            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>Chosen Birth Name</Typography>
                <TextField
                    select
                    fullWidth
                    size="small"
                    value={editedJourney?.birthName || ''}
                    onChange={(e) => setEditedJourney(prev => ({
                        ...prev,
                        birthName: e.target.value
                    }))}
                    placeholder="Select chosen birth name"
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {editedJourney?.possibleBirthNames?.map((nameObj, index) => (
                        <MenuItem key={index} value={nameObj.name}>
                            {`${nameObj.name}${nameObj.gender ? ` (${nameObj.gender})` : ''}`}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>

            {/* Preferred Contact Method */}
            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>Preferred Contact Method</Typography>
                <TextField
                    select
                    fullWidth
                    size="small"
                    value={editedJourney?.preferredContactMethod || ''}
                    onChange={(e) => setEditedJourney(prev => ({
                        ...prev,
                        preferredContactMethod: e.target.value
                    }))}
                >
                    <MenuItem value="Phone">Phone</MenuItem>
                    <MenuItem value="Email">Email</MenuItem>
                    <MenuItem value="Text">Text</MenuItem>
                </TextField>
            </Box>

            {/* Important Dates Section */}
            <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>Important Dates</Typography>
                <Stack spacing={2}>
                    {editedJourney?.importantDates?.map((date, index) => (
                        <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                            <TextField
                                size="small"
                                value={date.description}
                                onChange={(e) => {
                                    const newDates = [...editedJourney.importantDates];
                                    newDates[index].description = e.target.value;
                                    setEditedJourney(prev => ({
                                        ...prev,
                                        importantDates: newDates
                                    }));
                                }}
                                placeholder="Event description"
                            />
                            <DatePicker
                                selected={date.date ? new Date(date.date) : null}
                                onChange={(newDate) => {
                                    const newDates = [...editedJourney.importantDates];
                                    newDates[index].date = newDate;
                                    setEditedJourney(prev => ({
                                        ...prev,
                                        importantDates: newDates
                                    }));
                                }}
                                dateFormat="MM/dd/yyyy"
                                isClearable
                                popperPlacement="right"
                                className="date-picker-input"
                            />
                            <IconButton
                                onClick={() => {
                                    setEditedJourney(prev => ({
                                        ...prev,
                                        importantDates: prev.importantDates.filter((_, i) => i !== index)
                                    }));
                                }}
                            >
                                <FiTrash2 size={20} />
                            </IconButton>
                        </Box>
                    ))}
                    <Button
                        variant="outlined"
                        startIcon={<FiPlus size={20} />}
                        onClick={() => {
                            setEditedJourney(prev => ({
                                ...prev,
                                importantDates: [
                                    ...(prev.importantDates || []),
                                    { date: null, description: '' }
                                ]
                            }));
                        }}
                    >
                        Add Important Date
                    </Button>
                </Stack>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                <Button
                    variant="outlined"
                    onClick={() => setEditing(false)}
                    disabled={saving}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSaveChanges}
                    disabled={saving}
                    startIcon={saving ? <CircularProgress size={20} /> : <FiSave size={20} />}
                >
                    {saving ? 'Saving...' : 'Save Changes'}
                </Button>
            </Box>
        </Box>
    );

    const renderFamilyJourneyView = () => {
        return (
            <Grid container className="testestest"spacing={3}>
                {/* Basic Information Card */}
                <Grid item xs={12} md={6}>
                    <Card elevation={2}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom color="primary">
                                Basic Information
                            </Typography>
                            
                            {/* Important Dates */}
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="subtitle1" gutterBottom>Important Dates</Typography>
                                <Stack spacing={1}>
                                    {journey.dueDate && (
                                        <Typography>
                                            Due Date: {new Date(journey.dueDate).toLocaleDateString()}
                                        </Typography>
                                    )}
                                    {journey.birthDate && (
                                        <Typography>
                                            Birth Date: {new Date(journey.birthDate).toLocaleDateString()}
                                        </Typography>
                                    )}
                                </Stack>
                            </Box>

                            {/* Preferred Contact Method */}
                            <Box>
                                <Typography variant="subtitle1" gutterBottom>Preferred Contact Method</Typography>
                                <Typography>{journey.preferredContactMethod || 'Not specified'}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Parent Names Card */}
                <Grid item xs={12} md={6}>
                    <Card elevation={2}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom color="primary">
                                Parent Information
                            </Typography>
                            {journey.parentNames?.length > 0 ? (
                                <List>
                                    {journey.parentNames.map((parent, index) => (
                                        <ListItem key={index}>
                                            <ListItemText
                                                primary={`${parent.name} (${parent.relationship})`}
                                                secondary={
                                                    <>
                                                        {parent.email && `Email: ${parent.email}`}<br />
                                                        {parent.phone && `Phone: ${parent.phone}`}<br />
                                                        {parent.address && `Address: ${parent.address}`}
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Typography color="text.secondary">No parent names added</Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {/* Other Family Members Card */}
                <Grid item xs={12} md={6}>
                    <Card elevation={2}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom color="primary">
                                Other Family Members
                            </Typography>
                            {journey.otherFamilyMembers?.length > 0 ? (
                                <List>
                                    {journey.otherFamilyMembers.map((member, index) => (
                                        <ListItem key={index}>
                                            <ListItemText
                                                primary={`${member.name} (${member.relationship})`}
                                                secondary={
                                                    <>
                                                        {member.email && `Email: ${member.email}`}<br />
                                                        {member.phone && `Phone: ${member.phone}`}<br />
                                                        {member.address && `Address: ${member.address}`}
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Typography color="text.secondary">No family members added</Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {/* Emergency Contacts Card */}
                <Grid item xs={12} md={6}>
                    <Card elevation={2}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom color="primary">
                                Emergency Contacts
                            </Typography>
                            {journey.emergencyContacts?.length > 0 ? (
                                <List>
                                    {journey.emergencyContacts.map((contact, index) => (
                                        <ListItem key={index}>
                                            <ListItemText
                                                primary={contact.name}
                                                secondary={
                                                    <>
                                                        {contact.relationship}<br />
                                                        {contact.phone}
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Typography color="text.secondary">No emergency contacts added</Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {/* Support Team Card */}
                <Grid item xs={12} md={6}>
                    <Card elevation={2}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom color="primary">
                                Support Team
                            </Typography>
                            {journey.supportTeam?.length > 0 ? (
                                <List>
                                    {journey.supportTeam.map((member, index) => (
                                        <ListItem key={index}>
                                            <ListItemText
                                                primary={member.email}
                                                secondary={`Status: ${member.status} (Invited: ${new Date(member.invitedAt).toLocaleDateString()})`}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Typography color="text.secondary">No team members added yet</Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                  {/* Baby Names Card */}
                  <Grid item xs={12} md={6}>
                    <Card elevation={2}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom color="primary">
                                Baby Names
                            </Typography>
                            
                            {/* Possible Names */}
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="subtitle1" gutterBottom>Possible Names</Typography>
                                {journey.possibleBirthNames?.length > 0 ? (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                        {journey.possibleBirthNames.map((nameObj, index) => (
                                            <Chip
                                                key={index}
                                                label={`${nameObj.name}${nameObj.gender ? ` (${nameObj.gender})` : ''}`}
                                            />
                                        ))}
                                    </Box>
                                ) : (
                                    <Typography color="text.secondary">No baby names added yet</Typography>
                                )}
                            </Box>

                            {/* Chosen Name */}
                            {journey.birthName && (
                                <Box>
                                    <Typography variant="subtitle1" gutterBottom>Chosen Name</Typography>
                                    <Typography>
                                        {journey.birthName}
                                        {journey.possibleBirthNames?.find(n => n.name === journey.birthName)?.gender
                                            ? ` (${journey.possibleBirthNames.find(n => n.name === journey.birthName).gender})`
                                            : ''}
                                    </Typography>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {/* Professional Assessments Card */}
                <Grid item xs={12} md={6}>
                    <Card elevation={2}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom color="primary">
                                Professional Assessments
                            </Typography>
                            {journey.supportTeam?.filter(member => member.specialtyData?.data).map((professional, index) => (
                                <Accordion 
                                    key={index}
                                    sx={{
                                        mb: 1,
                                        '&:before': {
                                            display: 'none',
                                        },
                                        boxShadow: 'none',
                                        border: '1px solid',
                                        borderColor: 'divider'
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<MdExpandMore />}
                                        sx={{
                                            backgroundColor: 'background.default',
                                            '&:hover': {
                                                backgroundColor: 'action.hover',
                                            }
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: 500 }}>
                                            {professional.professionalName || professional.email} - {
                                                birthProfessionalQuestionList[professional.specialtyData.specialty]?.label || 
                                                professional.specialtyData.specialty.replace(/_/g, ' ')
                                            }
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {Object.entries(professional.specialtyData.data).map(([sectionKey, sectionData]) => {
                                            const sectionConfig = birthProfessionalQuestionList[professional.specialtyData.specialty]
                                                ?.sections[sectionKey];
                                            
                                            return (
                                                <Box key={sectionKey} sx={{ mb: 2 }}>
                                                    <Typography 
                                                        variant="subtitle2" 
                                                        sx={{ 
                                                            color: 'primary.main',
                                                            mb: 1,
                                                            fontWeight: 600
                                                        }}
                                                    >
                                                        {sectionConfig?.label || sectionKey.replace(/_/g, ' ')}
                                                    </Typography>
                                                    {Object.entries(sectionData).map(([questionKey, answer]) => {
                                                        const questionConfig = sectionConfig?.questions[questionKey];

                                                        return (
                                                            <Box 
                                                                key={questionKey} 
                                                                sx={{ 
                                                                    mb: 1.5,
                                                                    pl: 2,
                                                                    pb: 1,
                                                                    borderBottom: '1px solid',
                                                                    borderColor: 'divider'
                                                                }}
                                                            >
                                                                <Typography 
                                                                    variant="body2" 
                                                                    sx={{ 
                                                                        fontWeight: 500,
                                                                        color: 'text.secondary'
                                                                    }}
                                                                >
                                                                    {questionConfig?.label || questionKey.replace(/_/g, ' ')}
                                                                </Typography>
                                                                <Typography variant="body2">
                                                                    {Array.isArray(answer) 
                                                                        ? answer.join(', ')
                                                                        : typeof answer === 'boolean'
                                                                            ? answer ? 'Yes' : 'No'
                                                                            : answer.toString()}
                                                                </Typography>
                                                            </Box>
                                                        );
                                                    })}
                                                </Box>
                                            );
                                        })}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                            {(!journey.supportTeam?.length || !journey.supportTeam?.some(member => member.specialtyData?.data)) && (
                                <Typography color="text.secondary">
                                    No professional assessments available yet
                                </Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

              
            </Grid>
        );
    };



    return (
        <Box sx={{ p: 3 }}>
            <Grid container style={{justifyContent: 'center'}}>
                <Grid item >
                    <Card>
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                                <Typography variant="h4">
                                    {journey.familyName}'s Journey
                                </Typography>
                                {!editing && (
                                    <>

                                        <Button
                                            variant="contained"
                                            startIcon={<FiEdit size={20} />}
                                            onClick={handleStartEdit}
                                        >
                                            Edit Details
                                        </Button>
                                    </>
                                )}
                            </Box>

                            {editing ? renderEditForm() : renderFamilyJourneyView()}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <style>{styles}</style>
        </Box>
    );
};

export default MySingleFamilyJourney;
