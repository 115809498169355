import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Menu, MenuItem } from '@mui/material';
import { Col, Dropdown, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { displayAddress, formatPrice, generateLetterSVG, isAppointmentOverToCompleteOrder, momentFormat } from '../../../utils/validations';
import CancelOrderReason from '../../CancelOrderReason';
import RatingFeedback from '../../RatingFeedback';
import { getOrderStatus } from '../../../utils/status';
import "../Buyer.css";
import API from '../../../helpers/api';
import { toast } from 'react-toastify';
import { nameSlugify } from '../../../utils/nameHelper';

function SingleAppointment({ toggleView, appointment, handleCancelAppointment, loading, loadingComplete, loadingRating, handleCompleteOrder }) {
    const navigate = useNavigate()

    const startTime = new Date(appointment.timeSlot.start_time);
    const endTime = new Date(appointment.timeSlot.end_time);
    const now = new Date();

    const isUpcoming = now < startTime;
    const isInProgress = now >= startTime && now <= endTime;


    const [showCancelModal, setShowCancelModal] = useState(false);
    const [cancelReason, setCancelReason] = useState('');

    const [showReviewModal, setShowReviewModal] = useState(false);
    const [feedback, setFeedback] = useState({ rating: null, review: '', hideName: false });

    const handleCloseCancelModal = () => {
        setShowCancelModal(false);
        setCancelReason('');
    }

    const handleCancel = () => {
        handleCancelAppointment(appointment._id, cancelReason)
        handleCloseCancelModal()
    }

    const handleCloseReviewModal = () => {
        setShowReviewModal(false);
        setFeedback({ rating: null, review: '' })
    }

    const handleSubmitCompleteRequest = () => {
        let payload = {
            buisness_id: appointment.associatedWithService.seller._id,
            service_provider: appointment.associatedWithService.associatedWithUser._id,
            service_id: appointment.associatedWithService._id,
            orderId: appointment._id,
            rating: feedback.rating,
            review: feedback.review,
            hideName: feedback.hideName,
        }

        let ratingProvided = appointment.isFeedbackGiven;
        let orderCompleted = (appointment.status === 'completed')
        handleCompleteOrder(appointment._id, payload, ratingProvided, orderCompleted);
        handleCloseReviewModal();
    }

    const handleRebookAppointment = (service) => {
        navigate(`/book-appointments/${service._id}`, {
            state: {
                item: service
            }
        })
    }

    const handleCreateJourney = async () => {
        try {
            const journeyData = {
                createdBy: appointment.associatedWithUser._id,
                familyName: appointment.associatedWithUser.name,
                professionalId: appointment.businessId._id,
                contactInfo: {
                    email: appointment.associatedWithUser.email,
                    phone: appointment.associatedWithUser.phone || '',
                    address: appointment.associatedWithUser.address ?
                        `${appointment.associatedWithUser.address.street}, ${appointment.associatedWithUser.address.city}, ${appointment.associatedWithUser.address.state}, ${appointment.associatedWithUser.address.postalCode}` : ''
                },
                notes: `Journey created from appointment #${appointment.order_id}. Service: ${appointment.associatedWithService.bbservice.name} with ${appointment.associatedWithService.seller.name}`,
            };

            const response = await API.apiPostUrl('family-journey', '/journey', journeyData);

            if (response.data?.success) {
                toast.success('Journey created successfully!');
                navigate(`/family-journey`);
            }
        } catch (error) {
            console.log('error', error);
            toast.error(error.response?.data?.message || 'Error creating journey');
        }
    };

    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [currentAppointment, setCurrentAppointment] = React.useState(null);

    const openMenu = (event, appointment) => {
        setMenuAnchorEl(event.currentTarget);
        setCurrentAppointment(appointment);
    };

    const closeMenu = () => {
        setMenuAnchorEl(null);
        setCurrentAppointment(null);
    };

    return (
        <>
            {toggleView ?
                <TableBody>

                    <TableRow key={appointment.order_id}>
                        <TableCell>
                            #{appointment.order_id}
                        </TableCell>
                        <TableCell>
                            {typeof appointment.associatedWithService.category === 'object'
                                ? appointment.associatedWithService.category.name
                                : 'N/A'}
                        </TableCell>

                        {/* <TableCell>{appointment.associatedWithService.bbservice.name}</TableCell> */}

                        <TableCell>
                            {`${momentFormat.fullDate(appointment.timeSlot.date)} `}
                            {appointment.timeSlot.start_time && appointment.timeSlot.end_time
                                ? `, ${momentFormat.timIn12Format(appointment.timeSlot.start_time)} - ${momentFormat.timIn12Format(appointment.timeSlot.end_time)}`
                                : null}
                        </TableCell>

                        <TableCell>{momentFormat.dateTimeFormat(appointment.createdAt)}</TableCell>

                        <TableCell>{appointment.associatedWithService.seller.name}</TableCell>

                        <TableCell>{appointment.associatedWithService.associatedWithUser.name}</TableCell>

                        <TableCell>{displayAddress(appointment.associatedWithService.seller.address)}</TableCell>

                        <TableCell>
                            <span style={{ width: '200px', display: 'block' }} className={`status-services status--${appointment.status}`}>
                                {getOrderStatus(appointment.status)}
                            </span>
                        </TableCell>

                        <TableCell>
                            {['offline'].includes(appointment.mode)
                                ? 'Will be paid off platform'
                                : appointment.tnxId?.status === 'refund_initiated'
                                    ? 'Refund Initiated'
                                    : appointment.tnxId?.status || 'Failed'}
                        </TableCell>

                        <TableCell>{`$${formatPrice(appointment.total)}`}</TableCell>

                        <TableCell>
                            <Button
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={(e) => openMenu(e, appointment)}
                                variant="outlined"
                                size="small"
                            >
                                Actions
                            </Button>
                            <Menu
                                anchorEl={menuAnchorEl}
                                keepMounted
                                open={Boolean(menuAnchorEl && currentAppointment?.order_id === appointment.order_id)}
                                onClose={closeMenu}
                            >
                                <MenuItem onClick={() => navigate(`/${nameSlugify(appointment.associatedWithService.seller.name)}/${appointment.associatedWithService.seller._id}`)}>View Business</MenuItem>
                                {!['cancelled', 'pending', 'completed'].includes(appointment.status) && (
                                    <MenuItem
                                        onClick={() => {
                                            if (!loading) setShowCancelModal(true);
                                            closeMenu();
                                        }}
                                        disabled={loading || isAppointmentOverToCompleteOrder(appointment)}
                                    >
                                        {loading ? 'Processing...' : 'Cancel Appointment'}
                                    </MenuItem>
                                )}
                                {appointment.status === 'accepted' && (
                                    <>
                                        <MenuItem
                                            onClick={() => {
                                                if (!loadingComplete) setShowReviewModal(true);
                                                closeMenu();
                                            }}
                                            disabled={loadingComplete || !isAppointmentOverToCompleteOrder(appointment)}
                                        >
                                            {loadingComplete ? 'Processing...' : 'Complete Order'}
                                        </MenuItem>
                                        {window.location.hostname !== 'mybirthbridge.com' && <MenuItem
                                            onClick={handleCreateJourney}
                                        >
                                            Create a Journey
                                        </MenuItem>}
                                    </>
                                )}
                                {appointment.status === 'completed' && !appointment.isFeedbackGiven && (
                                    <MenuItem
                                        onClick={() => {
                                            if (!loadingRating) setShowReviewModal(true);
                                            closeMenu();
                                        }}
                                        disabled={loadingRating}
                                    >
                                        {loadingRating ? 'Processing...' : 'Share Feedback'}
                                    </MenuItem>
                                )}
                                {['cancelled', 'completed'].includes(appointment.status) && (
                                    <MenuItem
                                        onClick={() => handleRebookAppointment(appointment.associatedWithService)}
                                    >
                                        Re-Book
                                    </MenuItem>
                                )}
                            </Menu>
                        </TableCell>
                    </TableRow>

                </TableBody>
                :
                <div className='main-card-services'>
                    <div className="main-card-s-box">
                        {/* <div className='card-box-s-img'>
                            <img
                                loading='lazy'
                                alt="appointment img"
                                src={typeof appointment.associatedWithService.category === 'object'
                                    ? appointment.associatedWithService.category.profile
                                        ? appointment.associatedWithService.category.profile.url
                                        : generateLetterSVG(appointment.associatedWithService.category.name, 78)
                                    : '/images/logo.webp'
                                }
                            />
                        </div> */}
                        <div className='card-box-s-content'>
                            <h4>{typeof appointment.associatedWithService.category === 'object' ? appointment.associatedWithService.category.name : appointment.associatedWithService.category}</h4>
                            <h6>{appointment.associatedWithService.bbservice.name}</h6>
                            <hr />
                            <h6>
                                <strong>Appointment Date/Time:{' '}</strong>
                                {`${momentFormat.fullDate(appointment.timeSlot.date)} `} {(appointment.timeSlot.start_time && appointment.timeSlot.end_time) ? `, ${momentFormat.timIn12Format(appointment.timeSlot.start_time)} - ${momentFormat.timIn12Format(appointment.timeSlot.end_time)}` : null}
                                {(appointment.timeSlot.start_time && appointment.timeSlot.end_time && isInProgress) && <span className={`ml-2 status-services ${isInProgress ? 'status--progress' : ''}`}> Class  In-Progress </span>}
                            </h6>
                            <h6>
                                <strong>Booked Date/Time:{' '}</strong>
                                {momentFormat.dateTimeFormat(appointment.createdAt)}
                            </h6>
                            {appointment.timeSlot.description &&
                                <h6>
                                    <strong>Additional Note:{' '}</strong>
                                    {appointment.timeSlot.description}
                                </h6>}
                            <hr />
                            <h6><strong>Business Name: </strong> {appointment.associatedWithService.seller.name}</h6>
                            {(appointment.associatedWithService.associatedWithUser._id !== appointment.associatedWithService.seller._id) &&
                                <h6><strong>Service Provider: </strong> {appointment.associatedWithService.associatedWithUser.name}</h6>
                            }
                            <h6><strong>Business Address: </strong> {displayAddress(appointment.associatedWithService.seller.address)}</h6>

                            <hr />
                            <Row className='display--status'>
                                <Col sm={6} md={6} lg={6} className='mt-2'>
                                    <strong>Order Status:{' '}</strong>
                                    <span style={{ whiteSpace: 'nowrap' }} className={`status-services status--${appointment.status}`}>
                                        {getOrderStatus(appointment.status)}
                                    </span>
                                </Col>
                                <Col sm={6} md={6} lg={6} className='mt-2'>
                                    <strong>Payment Status:{' '}</strong>
                                    {['offline'].includes(appointment.mode)
                                        ? <span className='status-services'>Offline</span>
                                        : <span
                                            className={`status-services status--${appointment.tnxId?.status || 'failed'}`}>
                                            {appointment.tnxId?.status === 'refund_initiated'
                                                ? 'Refund Initiated'
                                                : appointment.tnxId?.status || 'Failed'
                                            }
                                        </span>}
                                </Col>
                            </Row>

                        </div>
                    </div>
                    <div className='amount-services-card'>
                        <h4>{`$${formatPrice(appointment.total)}`}</h4>
                        <p>#{appointment.order_id}</p>

                        <div className='action-service-card'>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    More
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => navigate(`/${nameSlugify(appointment.associatedWithService.seller.name)}/${appointment.associatedWithService.seller._id}`)}>View Business</Dropdown.Item>
                                    {!['cancelled', 'pending', 'completed'].includes(appointment.status) &&
                                        <Dropdown.Item
                                            onClick={() => loading ? null : setShowCancelModal(true)}
                                            disabled={['cancelled', 'pending', 'completed'].includes(appointment.status) || loading || isAppointmentOverToCompleteOrder(appointment)}
                                        >
                                            {loading ? 'Processing...' : 'Cancel Appointment'}
                                        </Dropdown.Item>
                                    }

                                    {appointment.status === 'accepted' && (
                                        <>
                                            <Dropdown.Item
                                                onClick={() => loadingComplete ? null : setShowReviewModal(true)}
                                                disabled={loadingComplete || !isAppointmentOverToCompleteOrder(appointment)}
                                            >
                                                {loadingComplete ? 'Processing...' : 'Complete Order'}
                                            </Dropdown.Item>
                                            {window.location.origin !== 'https://mybirthbridge.com' &&
                                                <Dropdown.Item
                                                    onClick={handleCreateJourney}
                                                >
                                                    Create a Journey
                                                </Dropdown.Item>
                                            }
                                        </>
                                    )}

                                    {(appointment.status === 'completed' && !appointment.isFeedbackGiven) &&
                                        <Dropdown.Item
                                            onClick={() => loadingRating ? null : setShowReviewModal(true)}
                                            disabled={loadingRating || !isAppointmentOverToCompleteOrder(appointment)}
                                        >
                                            {loadingRating ? 'Processing...' : 'Share Feedback'}
                                        </Dropdown.Item>
                                    }

                                    {['cancelled', 'completed'].includes(appointment.status) &&
                                        <Dropdown.Item
                                            onClick={() => handleRebookAppointment(appointment.associatedWithService)}
                                        >
                                            Re-Book
                                        </Dropdown.Item>
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>}


            {showCancelModal &&
                <CancelOrderReason
                    appointment={appointment}
                    showCancelModal={showCancelModal}
                    handleCloseCancelModal={handleCloseCancelModal}
                    handleChangeReason={(e) => setCancelReason(e.target.value)}
                    cancelReason={cancelReason}
                    handleSubmitCancelRequest={handleCancel}
                    loading={loading}
                />}

            {showReviewModal &&
                <RatingFeedback
                    loading={(appointment.status === 'completed') ? loadingRating || false : loadingComplete || false}
                    // loadingRating={loadingRating}
                    showReviewModal={showReviewModal}
                    handleCloseReviewModal={handleCloseReviewModal}
                    handleSubmitCompleteRequest={handleSubmitCompleteRequest}
                    feedback={feedback}
                    handleChangeFeedback={(v, field) => setFeedback({ ...feedback, [field]: v })}
                />}
        </>
    )
}

export default SingleAppointment
