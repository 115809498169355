// import CryptoJS from 'crypto-js';
import jwt_decode from "jwt-decode";
import { setAuthorization } from './api/index';

const welcomePopupKey = '__wlcm_birth_bridge_popup_1__';
const comingSoonPopupKey = "__coming_soon_birth_bridge_popup_1__";


export function getAppId() {
  return process.env.REACT_APP_SECRET_KEY || 'INVALID';
}

export function getToken() {
  return localStorage.getItem('accessToken');
}

export function getSeller(){
  let token = localStorage.getItem('accessToken');
  if(token){
    const user = jwt_decode(token) ; 
    if(user.role === 'seller'){
      return true
    }
  }  
}
export function getStaff(){
  let token = localStorage.getItem('accessToken');
  if(token){
    const user = jwt_decode(token) ; 
    if(user.role === 'staff'){
      return true
    }
  }  
}

export function getBuyer(){
  let token = localStorage.getItem('accessToken');
  if(token){
    const user = jwt_decode(token) ; 
    if(user.role === 'buyer'){
      return true
    }
  }  
}

export function saveCurrentView(value){
  localStorage.setItem('appView', value)
}

export function getCurrentView(){
  return localStorage.getItem('appView');
}

export const sellerClientView = () => {
  const token = localStorage.getItem('accessToken');
  
  if(token){
    const decoded = jwt_decode(token);
    return (decoded && (decoded.role === 'seller' || decoded.role === 'staff')) ? true : false
  }
  
  return false 
}



export function saveCurrentFilledSteps(activeStep, activeSubStep){
  let data = { activeStep, activeSubStep };
  localStorage.setItem('currentFilledSteps', JSON.stringify(data))
}

export function getCurrentFilledSteps(){
  const data = localStorage.getItem('currentFilledSteps');

  if(!data) return null
  return JSON.parse(data);
}

export function removeCurrentFilledSteps(){
  localStorage.removeItem('currentFilledSteps')
}


export function saveUserSignupFields(payload){
  localStorage.setItem('userSignupFields', JSON.stringify(payload))
}

export function getUserSignupFields(){
  const data = localStorage.getItem('userSignupFields');

  if(!data) return null
  return JSON.parse(data);
}

export function removeUserSignupFields(){
  localStorage.removeItem('userSignupFields')
}

export function saveSignupLastServiceId(id){
  localStorage.setItem('sp_id', id)
}

export function getSignupLastServiceId(){
  const data = localStorage.getItem('sp_id');

  if(!data) return null
  return data;
}

export function removeSignupLastServiceId(){
  localStorage.removeItem('sp_id');
}


export function saveSignupUserId(id){
  localStorage.setItem('u_id', id)
}

export function getSignupUserId(){
  const data = localStorage.getItem('u_id');

  if(!data) return null
  return data;
}

export function removeSignupUserId(){
  localStorage.removeItem('u_id');
}

// export function encodeJWT(payload, KEY = getAppId()) {
//   try {
//     const encodedPayload = CryptoJS.AES.encrypt(JSON.stringify(payload), KEY).toString();
//     const encodedPayloadString = encodedPayload.replace(/\+/g, '~').replace(/\//g, '!').replace(/=/g, '_');
//     return encodedPayloadString;
//   } catch (error) {
//     error.message = 'Data encryption failed';
//     return false;
//   }
// }

// export function decodeJWT(initialToken, KEY = getAppId()) {
//   try {
//     const token = initialToken.replace(/~/g, '+').replace(/!/g, '/').replace(/_/g, '=');
//     const bytes = CryptoJS.AES.decrypt(token, KEY);
//     const playload = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//     return playload;
//   } catch (error) {
//     error.message = 'Invalid secret key';
//     return false;
//   }
// }

export function makeWebId(length) {
  const result = [];
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
}

export function currentUser() {
  return JSON.parse(localStorage.getItem('accessToken'));
}

export function isAuth() {
  try {
    const token = localStorage.getItem('accessToken');
    if (token) {
      return jwt_decode(token);
    }
    return false;
  } catch (err) {
    return false;
  }
}

export function login(token, appId = '') {
  localStorage.setItem('accessToken', token);
  localStorage.setItem('appId', appId);
  setAuthorization();
  return true;
}
export function setSubscriptionSession(open) {
  return localStorage.setItem('_paySubs', open);
}

export function getSubscriptionSession(){
  return localStorage.getItem('_paySubs');
}

export function removeSubscriptionSession(){
  return localStorage.removeItem('_paySubs');
}

export function setRemember(user = {}) {
  localStorage.setItem('userRemember', JSON.stringify(user || isAuth()));
  return true;
}

export function removeRemember() {
  localStorage.removeItem('userRemember');
  return true;
}

export function setWelcomePopupVisited(){
  localStorage.setItem(welcomePopupKey, 'true');
}

export function getWelcomePopupVisited(){
    const value = localStorage.getItem(welcomePopupKey);
    return value ? true : false
}

export function setComingSoonPopupVisited(){
  localStorage.setItem(comingSoonPopupKey, 'true');
}

export function getComingSoonPopupVisited(){
    const value = localStorage.getItem(comingSoonPopupKey);
    return value ? true : false
}

export function logout() {
  console.log('Logging Out...')
  localStorage.removeItem('accessToken');
  localStorage.removeItem('appId');
  localStorage.removeItem('appView');
  removeSubscriptionSession();
  setAuthorization();
  setTimeout(() => {
    window.location.href = `${window.location.origin}/`;
  }, 500);
  return true;
}
