import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tooltip,
  Zoom,
} from '@mui/material';
import {
  Close as CloseIcon,
  EmojiObjects as IdeaIcon,
  BugReport as BugIcon,
  Star as FeatureIcon,
  Favorite as LoveIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { feedbackService } from '../../services/feedbackService';

const FeedbackModal = ({ open, onClose }) => {
  const [feedback, setFeedback] = useState({
    type: '',
    description: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Get user data from Redux
  const loggedIn = useSelector((state) => state.auth?.loggedIn);
  const userProfileData = useSelector(state => state.user);
  

  const feedbackTypes = [
    { value: 'feature', label: 'Feature Request', icon: <FeatureIcon />, color: '#2E7D32' },
    { value: 'bug', label: 'Report an Issue', icon: <BugIcon />, color: '#C62828' },
    { value: 'idea', label: 'Share an Idea', icon: <IdeaIcon />, color: '#F57F17' },
    { value: 'love', label: 'Show Some Love', icon: <LoveIcon />, color: '#C2185B' },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Validate required fields
      if (!feedback.type || !feedback.description) {
        toast.error('Please fill in all required fields');
        return;
      }

      const feedbackPayload = {
        type: feedback.type,
        description: feedback.description,
        metadata: {
          browser: navigator.userAgent,
          platform: navigator.platform,
          device: navigator.userAgentData?.mobile ? 'mobile' : 'desktop',
          currentPage: window.location.pathname
        },
        // Add user information if available
        ...(userProfileData && {
          userEmail: userProfileData?.userMetaDetail?.email || 'Anonymous',
          userRole: userProfileData?.userMetaDetail?.role || 'Anonymous',
          userName: userProfileData?.userMetaDetail?.name || 'Anonymous',
          isLoggedIn: !!loggedIn
        })
      };

      const response = await feedbackService.submitFeedback(feedbackPayload);
      
      if (response.success) {
        toast.success(response.message || 'Thank you for your feedback!');
        setFeedback({ type: '', description: '' });
        onClose();
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error(error.response?.data?.message || 'Failed to submit feedback. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setFeedback({ type: '', description: '' });
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
          boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
          background: '#ffffff',
        }
      }}
    >
      <Box sx={{ 
        position: 'relative',
        p: 2.5,
      }}>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'rgba(0,0,0,0.5)',
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h5" sx={{ 
          mb: 1,
          fontWeight: 600,
          color: '#1976D2',
        }}>
          We'd Love Your Feedback!
        </Typography>

        <Typography variant="body1" sx={{ mb: 3, color: 'rgba(0,0,0,0.7)' }}>
          Your feedback helps us improve BirthBridge for everyone. What's on your mind?
        </Typography>

        <form onSubmit={handleSubmit}>
          <DialogContent sx={{ p: 0 }}>
            <Box sx={{ mb: 3 }}>
              <RadioGroup
                value={feedback.type}
                onChange={(e) => setFeedback({ ...feedback, type: e.target.value })}
              >
                <Box sx={{ 
                  display: 'grid', 
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gap: 1.5,
                }}>
                  {feedbackTypes.map((type) => (
                    <Tooltip 
                      key={type.value}
                      title={type.label}
                      TransitionComponent={Zoom}
                      arrow
                    >
                      <FormControlLabel
                        value={type.value}
                        control={
                          <Radio 
                            sx={{
                              '&.Mui-checked': {
                                color: type.color,
                              }
                            }}
                          />
                        }
                        label={
                          <Box sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            p: 1,
                            border: '1px solid',
                            borderColor: feedback.type === type.value ? type.color : 'rgba(0,0,0,0.12)',
                            borderRadius: 1.5,
                            transition: 'all 0.2s ease',
                            cursor: 'pointer',
                            backgroundColor: feedback.type === type.value ? `${type.color}10` : 'transparent',
                            '&:hover': {
                              borderColor: type.color,
                              backgroundColor: `${type.color}10`,
                            },
                          }}>
                            <Box sx={{ color: type.color }}>
                              {type.icon}
                            </Box>
                            <Typography 
                              variant="body2" 
                              sx={{ 
                                fontWeight: feedback.type === type.value ? 600 : 400,
                                color: 'rgba(0,0,0,0.87)',
                              }}
                            >
                              {type.label}
                            </Typography>
                          </Box>
                        }
                        sx={{ 
                          margin: 0,
                          width: '100%',
                        }}
                      />
                    </Tooltip>
                  ))}
                </Box>
              </RadioGroup>
            </Box>

            <TextField
              multiline
              rows={4}
              fullWidth
              value={feedback.description}
              onChange={(e) => setFeedback({ ...feedback, description: e.target.value })}
              placeholder="Tell us more... What would make your experience better?"
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 1.5,
                  backgroundColor: 'rgba(0,0,0,0.02)',
                }
              }}
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={!feedback.type || !feedback.description || isSubmitting}
              sx={{
                mt: 3,
                py: 1.5,
                borderRadius: 1.5,
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 600,
                backgroundColor: '#1976D2',
                '&:hover': {
                  backgroundColor: '#1565C0',
                },
                '&:disabled': {
                  backgroundColor: 'rgba(0,0,0,0.12)',
                }
              }}
              endIcon={<SendIcon />}
            >
              {isSubmitting ? 'Sending...' : 'Send Feedback'}
            </Button>
          </DialogContent>
        </form>
      </Box>
    </Dialog>
  );
};

export default FeedbackModal; 