import slugify from 'slugify';

export const displayOwner = (user) => {

    if( user){  
        if( user.is_seller_with_staff ){
            let n =`${user.name}`

            if(user.seller_personal_name ){
                n += ` | ${user.seller_personal_name}`
            }

            return n
        }

        return user.name
    }
}

export const nameSlugify = (name) => {
    if(name){
        const generatedSlug = slugify(name, {
            lower: true,
            strict: true, // Removes special characters
            remove: /[*+~.()'"!:@]/g, // Additional custom removals (optional)
        });

        return generatedSlug;
    }

    return name
}