import styled from '@emotion/styled';
import { Button as BootstrapButton } from 'react-bootstrap';

const CustomButton = styled(BootstrapButton)`
  &.btn-primary {
    background-color: #73a5b5;
    border-color: #73a5b5;
    color: #fff;
    
    &:not(:disabled) {
      &:hover, &:focus, &:active {
        background-color: #5d8a99 !important;
        border-color: #5d8a99 !important;
      }
    }
  }

  &.btn-outline-secondary {
    background-color: transparent;
    border-color: #6c757d;
    color: #6c757d;
    
    &:not(:disabled) {
      &:hover, &:focus, &:active {
        background-color: #6c757d !important;
        border-color: #6c757d !important;
        color: #fff !important;
      }
    }
  }

  // Update disabled state styling
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    background-color: #808080 !important;
    border-color: #808080 !important;
    color: #fff !important;
  }
`;

const Button = ({ children, startIcon, ...props }) => {
  return (
    <CustomButton {...props}>
      {startIcon && (
        <span style={{ 
          marginRight: '0.5rem', 
          display: 'inline-flex', 
          alignItems: 'center',
          verticalAlign: 'middle'
        }}>
          {startIcon}
        </span>
      )}
      {children}
    </CustomButton>
  );
};

export default Button;