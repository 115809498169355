import React, { useState, useEffect } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { IoClose, IoEye } from 'react-icons/io5';
import PhotoViewer from '../../../commonComponents/PhotoViewer';
import { deepClone, generateYears, generateMonths } from '../../../utils/validations';
import SelectCategorySuggestions from '../ServiceManagement/SelectCategorySuggestions';
import { certificateTypes } from '../../../utils/certificateTypes';
import ReactSelect from 'react-select';
import './formStyles.css';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography, Box, Chip } from '@mui/material';
import styled from '@emotion/styled';
import FormTitle from '../../SharedStyledComponents/FormTitle';

const singleProduct = {
    service: '',
    category: []
}

const showOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
]

const TestComponentRender = ({
    filesIndex,
    handleShowFileView,
    handleFileInputChange,
    file,
    handleRemoveFile,
    certificateTimeError,
    years,
    months,
    setTriggerRender,
    triggerRender,
    triggerHack,
}) => {

    return (
        <div className="certificate-files-upload mt-3" key={`_${filesIndex}`}>
            <p className='cert__title m-0 d-flex align-items-center'>
                <span className="mr-2" >
                    {filesIndex + 1} -
                </span>
                <span className="mr-2" >
                    {file.name}
                </span>
                {file.file_type === 'pdf'
                    ?
                    <span
                        className='ml-auto badge-success px-2'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleShowFileView(file)}
                    >
                        View
                    </span>
                    : <PhotoViewer url={file.url} title={file.name}>
                        <span
                            className='ml-auto badge-success px-2'
                            style={{ cursor: 'pointer' }}
                        >
                            View
                        </span>
                    </PhotoViewer>
                }
                {!file.is_saved &&
                    <span
                        className="cerificate--files--close badge-danger ml-1 px-1"
                        onClick={() => handleRemoveFile(filesIndex, 'files')}
                        style={{ cursor: 'pointer' }}
                    >
                        <IoClose />
                    </span>
                }
            </p>
            <Box className="col-sm-12 mt-3 p-0">
                <FormControl component="fieldset">
                    <FormTitle>
                        Make Visible to Families <span className="required">*</span>
                    </FormTitle>
                    <RadioGroup
                        row
                        aria-label="visible-to-families"
                        name={`visible-to-families-${filesIndex}`}
                        value={['yes', true, 'true', 'Yes'].includes(file.show) ? true : false}
                        onChange={(e) => {
                            handleFileInputChange({
                                target: { name: 'show', value: JSON.parse(e.target.value) },
                            }, filesIndex)
                            if (triggerHack) {
                                setTriggerRender(!triggerRender)
                            }
                        }}
                    >
                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                        <FormControlLabel value={false} control={<Radio />} label="No" />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box className='col-sm-12 mt-3 p-0'>
                <FormTitle>
                    Certificate Type <span className="required">*</span>
                </FormTitle>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1, marginBottom: 2 }}>
                    {certificateTypes.map((type) => {
                        const isSelected = file.cert_type ?
                            certificateTypes.find(ct => ct.value === file.cert_type)?.value === type.value :
                            false;

                        return (
                            <Chip
                                key={type.value}
                                label={type.label}
                                onClick={() => {
                                    handleFileInputChange(
                                        { target: { name: 'cert_type', value: type.value } },
                                        filesIndex
                                    );
                                    if (triggerHack) {
                                        setTriggerRender(!triggerRender);
                                    }
                                }}
                                color={isSelected ? "primary" : "default"}
                                variant={isSelected ? "filled" : "outlined"}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: isSelected ? '' : '#f5f5f5'
                                    }
                                }}
                            />
                        );
                    })}
                </Box>
            </Box>
            <Box className="col-sm-12 mt-3 p-0">
                <Form.Group className="col-sm-12 p-0">
                    <FormTitle>
                        Certificate Title <span className="required">*</span>
                    </FormTitle>
                    <Form.Control
                        type="text"
                        name="title"
                        value={file?.title || ''}
                        onChange={(e) => {
                            handleFileInputChange(e, filesIndex)
                            if (triggerHack) {
                                if (file?.title) {
                                    setTriggerRender(true)
                                } else {
                                    setTriggerRender(false)
                                }
                            }
                        }}
                        placeholder={`e.g., DONA`}
                    />
                </Form.Group>
            </Box>
            <Box className="col-sm-12 mt-3 p-0">
            <Form.Group className="col-sm-12 p-0">
                <FormTitle>
                    Certificate Expiration (Year <span className="required">*</span> / Month)
                </FormTitle>
                <div className='row mt-2'>
                    <Form.Group className='col-sm-6 mb-3 mb-lg-0'>
                        <Form.Control
                            as="select"
                            aria-label="Year"
                            name='year'
                            value={file?.last_updated?.year || ''}
                            onChange={(e) => {
                                if (triggerHack) {
                                    setTriggerRender(!triggerRender)
                                }
                                handleFileInputChange(e, filesIndex, 'last_updated')
                            }}
                        >
                            <option>Year</option>
                            {
                                years.map((year, yearIndex) => {
                                    return <option value={year} key={yearIndex}>{year}</option>
                                })
                            }
                        </Form.Control>
                        {certificateTimeError && certificateTimeError.year && <span className='error-msg'>{certificateTimeError.year}</span>}
                    </Form.Group>
                    <Form.Group className='col-sm-6 mb-0'>
                        <Form.Control
                            as="select"
                            aria-label="Month"
                            name='month'
                            value={file?.last_updated?.month || ''}
                            onChange={(e) => {
                                if (triggerHack) {
                                    setTriggerRender(!triggerRender)
                                }
                                handleFileInputChange(e, filesIndex, 'last_updated')
                            }}
                        >
                            <option>Month</option>
                            {
                                months.map((month, monthIndex) => {
                                    return <option value={month.number} key={monthIndex}>{month.name}</option>
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </div>
            </Form.Group>
            </Box>
        </div>
    )
}

export default TestComponentRender;