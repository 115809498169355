export const birthProfessionalQuestionList = {
    postpartum_doula: {
        label: "Postpartum Doula",
        sections: {
            parent_wellbeing_recovery: {
                label: "Parent Wellbeing & Recovery",
                questions: {
                    emotional_checkin: {
                        label: "Emotional Check-in",
                        question: "How are you feeling emotionally today?",
                        type: "scale",
                        range: "1-10"
                    },
                    mental_health_symptoms: {
                        label: "Mental Health Symptoms",
                        question: "Are you experiencing any of the following?",
                        type: "checkbox",
                        options: [
                            "Mood swings",
                            "Anxiety",
                            "Sadness",
                            "Difficulty sleeping",
                            "Overwhelm",
                            "None of the above"
                        ]
                    },
                    sleep_quality: {
                        label: "Sleep Quality",
                        question: "How is your sleep quality?",
                        type: "text"
                    },
                    pain_discomfort_levels: {
                        label: "Pain/Discomfort Levels",
                        question: "Pain/Discomfort Levels (Postpartum Healing)",
                        type: "scale",
                        range: "1-10"
                    },
                    postpartum_recovery: {
                        label: "Postpartum Recovery",
                        question: "Are you healing from a C-section or any birth complications?",
                        type: "boolean"
                    },
                    self_care: {
                        label: "Self Care",
                        question: "Have you been able to engage in light movement or self-care today?",
                        type: "boolean"
                    }
                }
            },
            infant_care_development: {
                label: "Infant Care & Development",
                questions: {
                    feeding_method: {
                        label: "Feeding Method",
                        question: "Baby's feeding method",
                        type: "dropdown",
                        options: ["Breastfeeding", "Bottle-feeding", "Combination", "Other"]
                    },
                    feeding_frequency: {
                        label: "Feeding Frequency",
                        question: "How many feedings has your baby had today?",
                        type: "number"
                    },
                    feeding_concerns: {
                        label: "Feeding Concerns",
                        question: "Do you have any concerns about feeding?",
                        type: "boolean"
                    },
                    diaper_output: {
                        label: "Diaper Output",
                        wet_diapers: {
                            label: "Wet Diapers",
                            question: "Wet diapers count today",
                            type: "number"
                        },
                        dirty_diapers: {
                            label: "Dirty Diapers",
                            question: "Dirty diapers count today",
                            type: "number"
                        }
                    },
                    infant_sleep_quality: {
                        label: "Infant Sleep Quality",
                        question: "How well is your baby sleeping?",
                        type: "scale",
                        range: "1-10"
                    },
                    infant_discomfort: {
                        label: "Infant Discomfort",
                        question: "Is your baby showing any of these signs of discomfort?",
                        type: "checkbox",
                        options: [
                            "Colic/Fussiness",
                            "Gas/Reflux",
                            "Poor Latch",
                            "Excessive Crying",
                            "None of the above"
                        ]
                    }
                }
            },
            household_adjustments: {
                label: "Household Adjustments",
                questions: {
                    family_support: {
                        label: "Family Support",
                        question: "Are you receiving enough support from family/partner?",
                        type: "boolean"
                    },
                    daily_task_confidence: {
                        label: "Daily Task Confidence",
                        question: "How confident do you feel about managing daily tasks?",
                        type: "scale",
                        range: "1-10"
                    },
                    help_needed: {
                        label: "Help Needed",
                        question: "Would you like help with any of the following?",
                        type: "checkbox",
                        options: [
                            "Meal Prep",
                            "Light Housekeeping",
                            "Organizing Baby's Space",
                            "Sibling Adjustment",
                            "None of the above"
                        ]
                    }
                }
            },
            lactation_support: {
                label: "Lactation Support",
                questions: {
                    pain_breastfeeding: {
                        label: "Pain During Breastfeeding",
                        question: "Are you experiencing pain while breastfeeding?",
                        type: "boolean"
                    },
                    hydration_check: {
                        label: "Hydration Check",
                        question: "Have you met your hydration goals today?",
                        type: "boolean"
                    },
                    milk_supply: {
                        label: "Milk Supply",
                        question: "Are you experiencing any changes in milk supply?",
                        type: "dropdown",
                        options: ["Low supply", "Oversupply", "Normal"]
                    },
                    lactation_support_needed: {
                        label: "Additional Lactation Support Needed",
                        question: "Would you like additional lactation support?",
                        type: "boolean"
                    }
                }
            }
        }
    },
    birth_doula: {
        label: "Postpartum Doula",
        sections: {
            parent_wellbeing_recovery: {
                label: "Parent Wellbeing & Recovery",
                questions: {
                    emotional_checkin: {
                        label: "Emotional Check-in",
                        question: "How are you feeling emotionally today?",
                        type: "scale",
                        range: "1-10"
                    },
                    mental_health_symptoms: {
                        label: "Mental Health Symptoms",
                        question: "Are you experiencing any of the following?",
                        type: "checkbox",
                        options: [
                            "Mood swings",
                            "Anxiety",
                            "Sadness",
                            "Difficulty sleeping",
                            "Overwhelm",
                            "None of the above"
                        ]
                    },
                    sleep_quality: {
                        label: "Sleep Quality",
                        question: "How is your sleep quality?",
                        type: "text"
                    },
                    pain_discomfort_levels: {
                        label: "Pain/Discomfort Levels",
                        question: "Pain/Discomfort Levels (Postpartum Healing)",
                        type: "scale",
                        range: "1-10"
                    },
                    postpartum_recovery: {
                        label: "Postpartum Recovery",
                        question: "Are you healing from a C-section or any birth complications?",
                        type: "boolean"
                    },
                    self_care: {
                        label: "Self Care",
                        question: "Have you been able to engage in light movement or self-care today?",
                        type: "boolean"
                    }
                }
            },
            infant_care_development: {
                label: "Infant Care & Development",
                questions: {
                    feeding_method: {
                        label: "Feeding Method",
                        question: "Baby's feeding method",
                        type: "dropdown",
                        options: ["Breastfeeding", "Bottle-feeding", "Combination", "Other"]
                    },
                    feeding_frequency: {
                        label: "Feeding Frequency",
                        question: "How many feedings has your baby had today?",
                        type: "number"
                    },
                    feeding_concerns: {
                        label: "Feeding Concerns",
                        question: "Do you have any concerns about feeding?",
                        type: "boolean"
                    },
                    diaper_output: {
                        label: "Diaper Output",
                        wet_diapers: {
                            label: "Wet Diapers",
                            question: "Wet diapers count today",
                            type: "number"
                        },
                        dirty_diapers: {
                            label: "Dirty Diapers",
                            question: "Dirty diapers count today",
                            type: "number"
                        }
                    },
                    infant_sleep_quality: {
                        label: "Infant Sleep Quality",
                        question: "How well is your baby sleeping?",
                        type: "scale",
                        range: "1-10"
                    },
                    infant_discomfort: {
                        label: "Infant Discomfort",
                        question: "Is your baby showing any of these signs of discomfort?",
                        type: "checkbox",
                        options: [
                            "Colic/Fussiness",
                            "Gas/Reflux",
                            "Poor Latch",
                            "Excessive Crying",
                            "None of the above"
                        ]
                    }
                }
            },
            household_adjustments: {
                label: "Household Adjustments",
                questions: {
                    family_support: {
                        label: "Family Support",
                        question: "Are you receiving enough support from family/partner?",
                        type: "boolean"
                    },
                    daily_task_confidence: {
                        label: "Daily Task Confidence",
                        question: "How confident do you feel about managing daily tasks?",
                        type: "scale",
                        range: "1-10"
                    },
                    help_needed: {
                        label: "Help Needed",
                        question: "Would you like help with any of the following?",
                        type: "checkbox",
                        options: [
                            "Meal Prep",
                            "Light Housekeeping",
                            "Organizing Baby's Space",
                            "Sibling Adjustment",
                            "None of the above"
                        ]
                    }
                }
            },
            lactation_support: {
                label: "Lactation Support",
                questions: {
                    pain_breastfeeding: {
                        label: "Pain During Breastfeeding",
                        question: "Are you experiencing pain while breastfeeding?",
                        type: "boolean"
                    },
                    hydration_check: {
                        label: "Hydration Check",
                        question: "Have you met your hydration goals today?",
                        type: "boolean"
                    },
                    milk_supply: {
                        label: "Milk Supply",
                        question: "Are you experiencing any changes in milk supply?",
                        type: "dropdown",
                        options: ["Low supply", "Oversupply", "Normal"]
                    },
                    lactation_support_needed: {
                        label: "Additional Lactation Support Needed",
                        question: "Would you like additional lactation support?",
                        type: "boolean"
                    }
                }
            }
        }
    },
    perinatal_yoga: {
        label: "Perinatal Yoga",
        sections: {
            parent_wellbeing_recovery: {
                label: "Parent Wellbeing & Recovery",
                questions: {
                    emotional_checkin: {
                        label: "Emotional Check-in",
                        question: "How are you feeling emotionally today?",
                        type: "scale",
                        range: "1-10"
                    },
                    stress_levels: {
                        label: "Stress Levels",
                        question: "How would you rate your stress levels today?",
                        type: "scale",
                        range: "1-10"
                    },
                    sleep_quality: {
                        label: "Sleep Quality",
                        question: "How well did you sleep last night?",
                        type: "scale",
                        range: "1-10"
                    },
                    pain_discomfort_levels: {
                        label: "Pain/Discomfort Levels",
                        question: "Are you experiencing any pain or discomfort in your body?",
                        type: "checkbox",
                        options: [
                            "Lower back pain",
                            "Hip pain",
                            "Pelvic pressure",
                            "Neck and shoulder tension",
                            "Swelling in hands or feet",
                            "No pain"
                        ]
                    },
                    breathing_quality: {
                        label: "Breathing Quality",
                        question: "How easy does deep breathing feel today?",
                        type: "scale",
                        range: "1-10"
                    },
                    core_strength: {
                        label: "Core Strength",
                        question: "Do you feel comfortable engaging your core muscles during movement?",
                        type: "boolean"
                    },
                    postpartum_recovery: {
                        label: "Postpartum Recovery",
                        question: "Are you healing from a C-section or any birth complications?",
                        type: "boolean"
                    }
                }
            },
            physical_progress: {
                label: "Physical Progress",
                questions: {
                    flexibility_progress: {
                        label: "Flexibility Progress",
                        question: "Do you feel an improvement in your flexibility over time?",
                        type: "boolean"
                    },
                    balance_and_stability: {
                        label: "Balance and Stability",
                        question: "Are you experiencing difficulty with balance or stability?",
                        type: "boolean"
                    },
                    strength_improvement: {
                        label: "Strength Improvement",
                        question: "Which areas do you feel stronger in?",
                        type: "checkbox",
                        options: [
                            "Legs",
                            "Core",
                            "Arms & shoulders",
                            "Pelvic floor",
                            "Overall stamina",
                            "Not feeling stronger yet"
                        ]
                    },
                    breathwork_practice: {
                        label: "Breathwork Practice",
                        question: "Are you practicing breathwork techniques outside of class?",
                        type: "boolean"
                    },
                    movement_confidence: {
                        label: "Movement Confidence",
                        question: "Do you feel more confident in your movements and posture?",
                        type: "scale",
                        range: "1-10"
                    }
                }
            },
            birth_preparation_practice: {
                label: "Birth Preparation Practice",
                questions: {
                    labor_position_practice: {
                        label: "Labor Position Practice",
                        question: "Have you been able to practice any yoga-based labor positions?",
                        type: "boolean"
                    },
                    contraction_coping_practice: {
                        label: "Contraction Coping Practice",
                        question: "How comfortable do you feel using breathwork to manage contractions?",
                        type: "scale",
                        range: "1-10"
                    },
                    pelvic_opening_movement: {
                        label: "Pelvic Opening Movement",
                        question: "Are you incorporating movements that support pelvic opening?",
                        type: "boolean"
                    }
                }
            },
            postpartum_recovery_yoga: {
                label: "Postpartum Recovery Yoga",
                questions: {
                    postpartum_energy_levels: {
                        label: "Postpartum Energy Levels",
                        question: "How is your energy level today?",
                        type: "scale",
                        range: "1-10"
                    },
                    pelvic_floor_engagement: {
                        label: "Pelvic Floor Engagement",
                        question: "Do you feel comfortable engaging your pelvic floor muscles?",
                        type: "boolean"
                    },
                    postpartum_mobility: {
                        label: "Postpartum Mobility",
                        question: "Are there any movements that feel particularly difficult postpartum?",
                        type: "text"
                    },
                    relaxation_practice: {
                        label: "Relaxation Practice",
                        question: "Are you making time for restorative yoga or relaxation exercises?",
                        type: "boolean"
                    }
                }
            },
            class_experience_feedback: {
                label: "Class Experience Feedback",
                questions: {
                    class_comfort: {
                        label: "Class Comfort",
                        question: "Did you feel comfortable and supported during your last session?",
                        type: "boolean"
                    },
                    session_preference: {
                        label: "Session Preference",
                        question: "Would you like to focus more on any of the following areas?",
                        type: "checkbox",
                        options: [
                            "Strength-building",
                            "Breathwork & relaxation",
                            "Flexibility & stretching",
                            "Pelvic floor & core stability",
                            "Labor preparation"
                        ]
                    },
                    session_difficulty: {
                        label: "Session Difficulty",
                        question: "How would you rate the difficulty level of your last session?",
                        type: "scale",
                        range: "1-10"
                    },
                    additional_support_needed: {
                        label: "Additional Support Needed",
                        question: "Would you like additional resources or guidance?",
                        type: "checkbox",
                        options: [
                            "More yoga videos or home exercises",
                            "Guided breathwork recordings",
                            "Nutritional advice for pregnancy/postpartum",
                            "None, I feel good!"
                        ]
                    }
                }
            }
        }
    },
    perinatal_nutritionist: {
        label: "Perinatal Nutritionist",
        sections: {
            maternal_nutrition: {
                label: "Maternal Nutrition",
                questions: {
                    meal_tracking: {
                        label: "Meal Tracking",
                        question: "How many meals/snacks have you had today?",
                        type: "number"
                    },
                    hydration_check: {
                        label: "Hydration Check",
                        question: "Are you meeting your hydration goals?",
                        type: "boolean"
                    },
                    food_aversion: {
                        label: "Food Aversion",
                        question: "What foods have been difficult to eat lately?",
                        type: "text"
                    },
                    supplement_tracking: {
                        label: "Supplement Tracking",
                        question: "Are you currently taking supplements?",
                        type: "boolean",
                        details: "Type of Supplements"
                    },
                    nutrition_concerns: {
                        label: "Nutrition Concerns",
                        question: "Are you experiencing any of the following?",
                        type: "checkbox",
                        options: [
                            "Low energy",
                            "Poor appetite",
                            "Digestive issues (constipation, bloating)",
                            "Dizziness or lightheadedness",
                            "None of the above"
                        ]
                    }
                }
            },
            postpartum_recovery: {
                label: "Postpartum Recovery",
                questions: {
                    nutrient_deficiencies: {
                        label: "Nutrient Deficiencies",
                        question: "Are you experiencing any signs of nutrient deficiencies?",
                        type: "checkbox",
                        options: [
                            "Hair loss",
                            "Brittle nails",
                            "Extreme fatigue",
                            "Cravings for non-food items (e.g., ice, dirt)",
                            "None of the above"
                        ]
                    },
                    mood_check: {
                        label: "Mood Check",
                        question: "Are you currently experiencing mood swings or increased anxiety?",
                        type: "boolean"
                    },
                    protein_intake: {
                        label: "Protein Intake",
                        question: "Are you getting enough protein and iron-rich foods?",
                        type: "boolean"
                    },
                    weight_concerns: {
                        label: "Weight Concerns",
                        question: "Are you struggling with postpartum weight loss/gain?",
                        type: "boolean"
                    }
                }
            },
            infant_feeding: {
                label: "Infant Feeding",
                questions: {
                    feeding_type: {
                        label: "Feeding Type",
                        question: "Are you breastfeeding or formula-feeding?",
                        type: "dropdown",
                        options: ["Breastfeeding", "Formula-feeding", "Combination"]
                    },
                    food_sensitivity: {
                        label: "Food Sensitivity",
                        question: "Do you notice any changes in your baby after eating certain foods?",
                        type: "checkbox",
                        options: ["Gas", "Reflux", "Colic", "Eczema"]
                    },
                    dietary_restrictions: {
                        label: "Dietary Restrictions",
                        question: "Are you following any dietary restrictions?",
                        type: "boolean"
                    },
                    milk_supply_diet: {
                        label: "Milk Supply Through Diet",
                        question: "Would you like recommendations for boosting milk supply through diet?",
                        type: "boolean"
                    }
                }
            },
            specialized_nutrition: {
                label: "Specialized Nutrition",
                questions: {
                    gestational_diabetes: {
                        label: "Gestational Diabetes",
                        question: "Are you currently managing gestational diabetes or blood sugar levels?",
                        type: "boolean"
                    },
                    diet_type: {
                        label: "Diet Type",
                        question: "Are you following a specific diet?",
                        type: "dropdown",
                        options: ["Vegan", "Paleo", "Mediterranean", "Anti-Inflammatory", "None"]
                    },
                    meal_planning: {
                        label: "Meal Planning",
                        question: "Do you need personalized meal planning assistance?",
                        type: "boolean"
                    }
                }
            }
        }
    }
}
