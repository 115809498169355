import React, { useState, useEffect } from 'react';
import {
    Box, TextField, Autocomplete, Button,
    Dialog, DialogTitle, DialogContent,
    DialogActions, CircularProgress,
    List, ListItem, ListItemText,
    Typography, Chip,
    FormControl,
    InputLabel,
    Select, MenuItem,
    FormHelperText,
    IconButton
} from '@mui/material';
import API from '../../helpers/api';
import { toast } from 'react-toastify';
import { IoClose } from "react-icons/io5";


export const ProfessionalSearch = ({ journeyId, onInviteSent }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [professionals, setProfessionals] = useState([]);
    const [selectedProfessional, setSelectedProfessional] = useState(null);
    const [pendingInvites, setPendingInvites] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [inviting, setInviting] = useState(false);
    
    useEffect(() => {
        console.log('Setting up 2 second debounce timer for search');
        const debounceTimer = setTimeout(async () => {
            if (!searchQuery.trim()) {
                console.log('Empty search query, clearing professionals');
                setProfessionals([]);
                return;
            }
            
            
            try {
                setLoading(true);
                console.log('Fetching professionals for query:', searchQuery);

                const response = await API.apiGetByKey(
                    'family-journey',
                    `/search-professionals?query=${encodeURIComponent(searchQuery)}`
                );

                console.log('API Response:', response);
                console.log('Number of professionals received:', response.data.professionals?.length);

                if (response.data?.success) {
                    const receivedProfessionals = response.data.professionals || [];
                    console.log('Setting professionals:', receivedProfessionals);
                    setProfessionals(receivedProfessionals);
                }
            } catch (error) {
                console.error('Error searching professionals:', error);
                toast.error('Error searching professionals');
            } finally {
                setLoading(false);
            }
        }, 2000);

        return () => clearTimeout(debounceTimer);
    }, [searchQuery]);

    useEffect(() => {
        fetchPendingInvites();
    }, [journeyId]);

    const fetchPendingInvites = async () => {
        try {
            const response = await API.apiGetByKey(
                'family-journey',
                `/journey/${journeyId}`
            );

            if (response.data?.success) {
                // Filter only pending invites from the support team
                const pending = response.data.journey.supportTeam.filter(
                    member => member.status === 'pending'
                );
                setPendingInvites(pending);
            }
        } catch (error) {
            console.error('Error fetching pending invites:', error);
            toast.error('Error fetching pending invitations');
        }
    };

    const handleDeleteInvite = async (inviteId) => {
        try {
            await API.apiDeleteUrl(
                'family-journey',
                `/journey/${journeyId}/invite/${inviteId}`
            );
            toast.success('Invitation deleted');
            await fetchPendingInvites(); // Refresh the list
        } catch (error) {
            console.error('Delete invite error:', error);
            toast.error('Failed to delete invitation');
        }
    };

    const PendingInvitesList = ({ pendingInvites, onDeleteInvite }) => {
        return (
            <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Pending Invitations
                </Typography>
    
                {/* Invite Button */}
                <Button style={{ marginBottom: '32px' }} variant="contained" onClick={() => setOpen(true)}>
                    Invite a Birth Professional
                </Button>
    
    
                {pendingInvites.length > 0 ? (
                    <List>
                        {pendingInvites.map((invite) => (
                            <ListItem
                                key={invite._id}
                                sx={{
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    border: '1px solid #e0e0e0',
                                    borderRadius: 1,
                                    mb: 1
                                }}
                            >
                                <Box sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    <Box>
                                        <Typography variant="body1">
                                            {invite.email}
                                        </Typography>
                                        <Typography variant="caption" color="text.secondary">
                                            Invited: {new Date(invite.invitedAt).toLocaleDateString()}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Chip
                                            label="Pending"
                                            color="warning"
                                            size="small"
                                        />
                                        <IconButton 
                                            size="small" 
                                            color="error"
                                            onClick={() => onDeleteInvite(invite._id)}
                                        >
                                            <IoClose />
                                        </IconButton>
                                    </Box>
                                </Box>
    
                                {invite.serviceDetails && (
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                        <Typography variant="caption" color="text.secondary">
                                            Invited for:
                                        </Typography>
                                        {invite.serviceDetails.category && (
                                            <Chip
                                                label={invite.serviceDetails.category}
                                                size="small"
                                                sx={{ ml: 1 }}
                                                color="primary"
                                                variant="outlined"
                                            />
                                        )}
                                    </Box>
                                )}
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Typography color="text.secondary">
                        No pending invitations
                    </Typography>
                )}
            </Box>
        );
    };

    const handleInvite = async () => {
        if (!selectedProfessional || !selectedService) return;

        try {
            setInviting(true);
            const response = await API.apiPostUrl(
                'family-journey',
                `/journey/${journeyId}/invite`,
                {
                    professionalId: selectedProfessional._id,
                    email: selectedProfessional.email,
                    serviceId: selectedService._id,
                    serviceName: selectedService.name,
                    serviceCategory: selectedService.category
                }
            );

            if (response.data?.success) {
                toast.success('Invitation sent successfully');
                setOpen(false);
                setSelectedProfessional(null);
                setSelectedService(null);
                await fetchPendingInvites();
                if (onInviteSent) onInviteSent();
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Error sending invitation');
        } finally {
            setInviting(false);
        }
    };

    return (
        <Box>

            <PendingInvitesList 
                pendingInvites={pendingInvites} 
                onDeleteInvite={handleDeleteInvite}
            />


            {/* Search Dialog */}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Invite a Professional
                    {professionals.length > 0 && (
                        <Typography variant="caption" display="block">
                            Found {professionals.length} professionals
                        </Typography>
                    )}
                </DialogTitle>
                <DialogContent>
                    <Autocomplete
                        fullWidth
                        options={professionals}
                        loading={loading}
                        getOptionLabel={(option) => `${option.name} (${option.email})`}
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                        onChange={(_, newValue) => {
                            console.log('Selected professional:', newValue);
                            setSelectedProfessional(newValue);
                        }}
                        renderInput={(params) => (
                            <>
                                <TextField
                                    {...params}
                                    // label="Search Professionals"
                                    variant="outlined"
                                    onChange={(e) => {
                                        console.log('Search input changed:', e.target.value);
                                        setSearchQuery(e.target.value);
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loading && <CircularProgress size={20} />}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            </>
                        )}
                        ListboxProps={{
                            style: { maxHeight: '400px' }
                        }}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option._id}>
                                    <Box sx={{ p: 1 }}>
                                        <Typography variant="body1" component="div">
                                            {option.name}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" component="div">
                                            {option.email}
                                        </Typography>
                                        {option.services && option.services.length > 0 && (
                                            <Box sx={{ mt: 1 }}>
                                                <Typography variant="caption" color="text.secondary">
                                                    Services:
                                                </Typography>
                                                {option.services.map((service, index) => (
                                                    <Chip
                                                        key={service._id}
                                                        label={`${service.name} (${service.category})`}
                                                        size="small"
                                                        sx={{ mr: 0.5, mb: 0.5 }}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                        {option.seller_business_desc && (
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{
                                                    mt: 0.5,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 2,
                                                    WebkitBoxOrient: 'vertical',
                                                }}
                                            >
                                                {option.seller_business_desc}
                                            </Typography>
                                        )}
                                    </Box>
                                </li>
                            );
                        }}
                        noOptionsText="No professionals found"
                        filterOptions={(options, state) => options}  // Disable built-in filtering
                    />

                    {selectedProfessional && selectedProfessional.services && (
                        <Box sx={{ mt: 3 }}>
                            <FormControl fullWidth>
                                <InputLabel id="service-select-label">
                                    Select Service
                                </InputLabel>
                                <Select
                                    labelId="service-select-label"
                                    value={selectedService?._id || ''}
                                    onChange={(e) => {
                                        const service = selectedProfessional.services.find(
                                            s => s._id === e.target.value
                                        );
                                        setSelectedService(service);
                                    }}
                                    label="Select Service"
                                >
                                    {selectedProfessional.services.map((service) => (
                                        <MenuItem key={service._id} value={service._id}>
                                            {service.name} ({service.category})
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>
                                    Please select the service you'd like to invite this professional for
                                </FormHelperText>
                            </FormControl>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button
                        onClick={handleInvite}
                        disabled={!selectedProfessional || !selectedService || inviting}
                        variant="contained"
                        startIcon={inviting ? <CircularProgress size={20} color="inherit" /> : null}
                    >
                        {inviting ? 'Sending...' : 'Send Invite'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}; 