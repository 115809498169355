import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';
// import jwtDecode from 'jwt-decode';

import { 
  LOGIN_FAILED, 
  LOGIN_SUCCESS,
  REQUEST_OTP_SUCCESS,
  REQUEST_OTP_FAILED,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,

  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,

  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILED,

  CREATE_SELLER_ACCOUNT_SUCCESS,
  CREATE_SELLER_ACCOUNT_FAILED,

/** using for new password  */
  VERIFY_EMAIL_OTP_SUCCESS,
  VERIFY_EMAIL_OTP_FAILED,

  PATCH_FIRST_NEW_PASSWORD_FAILED,
  PATCH_FIRST_NEW_PASSWORD_SUCCESS,

  SHOW_LOGIN_MODAL,
  LOGIN_SUCCESS_UNPAID_SUBSCRIPTION,
  SHOW_NORMAL_SIGNUP,
  SHOW_SELLER_SIGNUP,
  SHOW_VERIFY_OTP,
  SHOW_RESET_PASS,
  SHOW_REQUEST_OTP,
  SHOW_AUTH_MODAL,
  VERIFY_STAFF_JOINING_LINK_SUCCESS,
  VERIFY_STAFF_JOINING_LINK_FAILED,
  CLEAR_REDUX_STATE,

  
} from "./types";

function errorRequest(err, dispatch, type) {
  let data = get(err, 'response.data', null);
  data = data || get(err, 'response');
  data = data || err;
  if (data.error) {
      dispatch({
          type: type,
          payload: data.error.message,
      });
  } else {
      dispatch({
          type: type,
          payload:data.message,
      });
  }
}
/**
 * 
 * @param {*} using for create new account 
 * @returns 
 */
export const createAccount = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl('auth', '/register', payload);
      if (response.data && response.data.success) {
        await dispatch({ type: CREATE_ACCOUNT_SUCCESS, payload: response.data });
      } else {
        await dispatch({ type: CREATE_ACCOUNT_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch, CREATE_ACCOUNT_FAILED);
    }
  }
}

/**
 * 
 * @param {*} using for create seller account
 * @returns 
 */

export const createSeller = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl('auth', '/register', payload);
      if (response.data && response.data.success) {
        await dispatch({ type: CREATE_SELLER_ACCOUNT_SUCCESS, payload: response.data });
      } else {
        await dispatch({ type: CREATE_SELLER_ACCOUNT_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch, CREATE_SELLER_ACCOUNT_FAILED);
    }
  }
}

/**
 * 
 * @param {*} login user / seller
 * @returns 
 */

export const login = (payload)  => {
  return async (dispatch) => {
    try {
        // localStorage.clear(); 
        const response = await API.apiPostUrl('auth', '/login' , payload);

        /** -- If Response Success -- */
        if(response.data.accessToken){
        /** -- If Response with token and unpaidSubscription -- */
          auth.login(response.data.accessToken);
          if(response.data.unpaidSubscription){
            auth.setSubscriptionSession(true)
            await dispatch({ type: LOGIN_SUCCESS_UNPAID_SUBSCRIPTION, payload: response.data })
          }else{
            await dispatch({ type: LOGIN_SUCCESS, payload: response.data.accessToken });
          }
          window.location.replace('/')
          // await dispatch({ type: LOGIN_SUCCESS, payload: response.data.accessToken });
        }else{
          await dispatch({ type: LOGIN_FAILED, payload: response.data.message });
        }
    } catch (err) {
      // console.log("err" , err)
      errorRequest(err, dispatch, LOGIN_FAILED);
    }
  };
}


export const createNewPassword = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPatchUrl('auth', '/password', payload)
      if (response.data && response.data.success) {
        await dispatch({ type: PATCH_FIRST_NEW_PASSWORD_SUCCESS, payload: response.data });
      }else{
        await dispatch({ type: PATCH_FIRST_NEW_PASSWORD_FAILED, payload: response.data.message });
      }
    } catch (err) {
      errorRequest(err, dispatch, PATCH_FIRST_NEW_PASSWORD_FAILED);
    }
  };
}


/**
 * 
 * @param {*} requestEmailVerification using for request email verification link 
 * @returns 
 */
export const requestEmailVerification = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl('auth', '/email-verification/request', payload);
      if (response.data && response.data.success) {
        await dispatch({ type: REQUEST_OTP_SUCCESS, payload: response.data });
      }else{
        await dispatch({ type: REQUEST_OTP_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch, REQUEST_OTP_FAILED)
    }
  }
}


/**
 * @param {*} requestOtp is used for requesting OTP in case forgot password
 */

export const requestOtp = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl('auth', '/otp/request', payload);
      if (response.data && response.data.success) {
        await dispatch({ type: REQUEST_OTP_SUCCESS, payload: response.data });
      }else{
        await dispatch({ type: REQUEST_OTP_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch, REQUEST_OTP_FAILED)
    }
  }
}

/**
 * 
 * @param {*} verifyOtp using for verify forgot pass
 * @returns 
 */

export const verifyOtp = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl('auth', '/otp/verify', payload);
      if (response.data && response.data.success) {
        await dispatch({ type: VERIFY_OTP_SUCCESS, payload: response.data });
      }else{
        await dispatch({ type: VERIFY_OTP_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch, VERIFY_OTP_FAILED)
    }
  }
}


/**
 * 
 * @param {*} EmailOtp using for verify email on signup  
 * @returns 
 */

export const verifyEmailOtp = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl('auth', '/email/verify', payload);
      if (response.data && response.data.success) {
        await dispatch({ type: VERIFY_EMAIL_OTP_SUCCESS, payload: response.data });
      }else{
        await dispatch({ type: VERIFY_EMAIL_OTP_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch, VERIFY_EMAIL_OTP_FAILED)
    }
  }
}


/**
 * using for create new password 
 */

export const resetPassword = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl('auth', '/password/reset', payload);
      if (response.data && response.data.success) {
        await dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response.data });
      }else{
        await dispatch({ type: RESET_PASSWORD_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch, RESET_PASSWORD_FAILED)
    }
  }
}

export const verifyStaffJoiningLink = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl('auth', '/verify-staff-joining-link', payload);
      if (response.data && response.data.success) {
        await dispatch({ type: VERIFY_STAFF_JOINING_LINK_SUCCESS, payload: response.data });
      }else{
        await dispatch({ type: VERIFY_STAFF_JOINING_LINK_FAILED, payload: response.data });
      }
    } catch (error) {
      errorRequest(error, dispatch, VERIFY_STAFF_JOINING_LINK_FAILED);
    }
  }
}

export const showAuthModal = (value) => {
  return async dispatch => {
    dispatch({ type:SHOW_AUTH_MODAL, payload: value})
  }
}

export const showLoginModal = (value) => {
  return async dispatch => {
    dispatch({ type:SHOW_LOGIN_MODAL})
  }
}

export const showNormalSignUp = (value) => {
  return async dispatch => {
    dispatch({ type:SHOW_NORMAL_SIGNUP})
  }
}

export const showSellerSignUp = (value) => {
  return async dispatch => {
    dispatch({ type:SHOW_SELLER_SIGNUP})
  }
}

/**
 * 
 * @param {Boolean} value // here false is email is verified during signup otherwise true
 * @param {Boolean} isSeller if true 
 * @returns 
 */
export const showVerifyOtp = (value, isSeller) => {
  return async dispatch => {
    dispatch({ type:SHOW_VERIFY_OTP, payload: {emailVerify: value, isSeller: isSeller}})
  }
}

export const showResetPassword = (value) => {
  return async dispatch => {
    dispatch({ type:SHOW_RESET_PASS})
  }
}

export const showRequestOTP = (value) => {
  return async dispatch => {
    dispatch({ type:SHOW_REQUEST_OTP});
  }
}

export const clearReduxState = () => {
  return async dispatch => {
    dispatch({ type:CLEAR_REDUX_STATE});
  }
}

export function logout() {
    auth.logout();
}


  