module.exports = [
    { key: 'Google Search', value: 'Google Search' },
    // { key: 'Truck Ad', value: 'Truck Ad' },
    { key: 'Instagram', value: 'Instagram' },
    { key: 'Facebook', value: 'Facebook' },
    { key: 'Instagram Threads', value: 'Instagram Threads' },
    { key: 'Pinterest', value: 'Pinterest'},
    { label: 'Email', value: 'Email' },
    // { key: 'TikTok', value:'TikTok'},
    { key: 'Reddit', value: 'Reddit'},
    // { key: 'Twitter', value: 'Twitter'},
    { key: 'Outdoor Ads', value: 'Outdoor Ads'},
    { key: 'Referred By', value: 'Referred by a Birth Professional'}
];