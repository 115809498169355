import API from '../helpers/api';

export const feedbackService = {
    submitFeedback: async (feedbackData) => {
        try {
            const response = await API.apiPostUrl('feedback', '/submit', feedbackData);
            return response.data;
        } catch (error) {
            console.error('Error submitting feedback:', error);
            throw error;
        }
    }
}; 