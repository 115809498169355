import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    Chip,
    CircularProgress,
    Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import API from '../../../helpers/api';
import { toast } from 'react-toastify';

const FamilyTracking = () => {
    const [journeys, setJourneys] = useState({ pending: [], active: [] });
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchJourneys();
    }, []);

    const fetchJourneys = async () => {
        try {
            setLoading(true);
            const response = await API.apiGetByKey('family-journey', '/professional/journeys');
            if (response.data?.success) {
                // Update status filtering to include 'accepted' as active
                const pending = response.data.journeys.filter(j => j.status === 'pending');
                const active = response.data.journeys.filter(j => ['active', 'accepted'].includes(j.status));
                setJourneys({ pending, active });
            }
        } catch (error) {
            toast.error('Error fetching journeys');
        } finally {
            setLoading(false);
        }
    };

    const handleAcceptInvite = async (journeyId) => {
        try {
            await API.apiPutUrl('family-journey', `/journey/${journeyId}/accept`);
            toast.success('Invitation accepted');
            fetchJourneys(); // Refresh the list
        } catch (error) {
            toast.error('Error accepting invitation');
        }
    };

    const handleDeclineInvite = async (journeyId) => {
        try {
            await API.apiPutUrl('family-journey', `/journey/${journeyId}/decline`);
            toast.success('Invitation declined');
            fetchJourneys(); // Refresh the list
        } catch (error) {
            toast.error('Error declining invitation');
        }
    };

    const handleViewJourney = (journeyId) => {
        navigate(`/journey/${journeyId}`);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            {/* Pending Invitations Section */}
            <Box mb={4}>
                <Typography variant="h5" gutterBottom>
                    Pending Invitations
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Family</TableCell>
                                <TableCell>Service Requested</TableCell>
                                <TableCell>Invited On</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {journeys.pending.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        No pending invitations
                                    </TableCell>
                                </TableRow>
                            ) : (
                                journeys.pending.map((journey) => (
                                    <TableRow key={journey._id}>
                                        <TableCell>{journey.familyName}</TableCell>
                                        <TableCell>
                                            {journey.serviceDetails?.name || 'Not specified'}
                                        </TableCell>
                                        <TableCell>
                                            {new Date(journey.invitedAt).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                color="primary"
                                                onClick={() => handleAcceptInvite(journey._id)}
                                                sx={{ mr: 1 }}
                                            >
                                                Accept
                                            </Button>
                                            <Button
                                                color="error"
                                                onClick={() => handleDeclineInvite(journey._id)}
                                            >
                                                Decline
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Active Journeys Section */}
            <Box>
                <Typography variant="h5" gutterBottom>
                    Active Journeys
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Family</TableCell>
                                <TableCell>Service Type</TableCell>
                                <TableCell>Due Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {journeys.active.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        No active journeys
                                    </TableCell>
                                </TableRow>
                            ) : (
                                journeys.active.map((journey) => (
                                    <TableRow key={journey._id}>
                                        <TableCell>{journey.familyName}</TableCell>
                                        <TableCell>
                                            {journey.serviceDetails?.name || 'Not specified'}
                                        </TableCell>
                                        <TableCell>
                                            {journey.dueDate ? 
                                                new Date(journey.dueDate).toLocaleDateString() : 
                                                'Not specified'
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Chip 
                                                label={journey.status}
                                                color="success"
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                onClick={() => handleViewJourney(journey._id)}
                                            >
                                                View Details
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default FamilyTracking;
