import React, { Suspense } from 'react';
import { Container } from "react-bootstrap";
import { motion } from 'framer-motion';
import SEO from '../../seo';
import './style.css';
import '../../App.css';
import LandingPageAboutBirthProfessionals from './LandingPageAboutBirthProfessionals.js';
import LandingPageHowWeHelpYou from './LandingPageHowWeHelpYou';
import BirthProfessionalTestimonials from './BirthProfessionalTestimonials';
import Header from './header';
import OurServices from './ourServices';
import sectionDivider from './section_divider.avif';

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

const SectionDivider = ({ img }) => {
  return (
    <motion.section 
      className="custom-section"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
    >
      <div className="image-container">
        <img src={img} loading="lazy" alt="Section Divider" />
      </div>
    </motion.section>
  );
};

const SectionDivider2 = ({ img }) => {
  return (
    <motion.section 
      className="custom-section"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
    >
      <div className="image-container">
        <img 
          src={img}
          style={{
            width: '100%',
            height: '400px',
            borderRadius: '10px',
            objectFit: 'cover',
            objectPosition: '0px -290px'
          }}
          loading="lazy"
          alt="Section Divider" 
        />
      </div>
    </motion.section>
  );
};

const HomeComponent = () => {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit={{ opacity: 0 }}
    >
      <SEO
        title="Welcome to BirthBridge"
        description="Connect with top birth professionals and easily book prenatal, birth, and postpartum services. Join BirthBridge today for seamless and supportive care."
        keywords="BirthBridge, birth professionals, prenatal care, postpartum care, doula services, midwife services, lactation consultant, birth services, family support, pregnancy care, infant care, booking birth professionals, trusted birth services"
        url="/"
      />
      
      <Header
        title="Built for Birth Professionals—Designed to Help You Grow"
        subTitle="Your space to thrive, connect, and grow alongside others in the birthwork community. Built to support your practice—and your purpose."
        btnDetails={{
          showBtn: true,
          btnText: "Join Our Community",
          btnUrl: '/bridger-signup'
        }}
      />
      
      <motion.div {...fadeInUp}>
        <LandingPageAboutBirthProfessionals />
      </motion.div>
      
      <SectionDivider img={sectionDivider} />
      
      <motion.div {...fadeInUp}>
        <LandingPageHowWeHelpYou />
      </motion.div>

      {/* <motion.div {...fadeInUp}>
        <BirthProfessionalTestimonials />
      </motion.div> */}
      
      <motion.div {...fadeInUp}>
        <OurServices />
      </motion.div>

    </motion.div>
  );
};

export default HomeComponent;