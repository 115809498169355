import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    Box,
    Divider
} from '@mui/material';

const LoginSignupModal = ({ open, onClose }) => {
    const navigate = useNavigate();
    const theme = useTheme();

    const handleLogin = () => {
        navigate('/login');
        onClose();
    };

    const handleProfessionalSignup = () => {
        navigate('/bridger-signup');
        onClose();
    };

    const handleFamilySignup = () => {
        navigate('/family-signup');
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                color: theme.palette.primary.main
            }}>
                Join the Community
            </DialogTitle>
            <DialogContent>
                <Typography align="center" sx={{ mb: 2 }}>
                    To message professionals, you'll need to log in or create an account first.
                </Typography>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    mt: 2
                }}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleLogin}
                        sx={{
                            backgroundColor: '#73a5b5',
                            '&:hover': {
                                backgroundColor: '#5d8a99'
                            }
                        }}
                    >
                        Log In
                    </Button>

                    <Divider sx={{ my: 2 }}>
                        <Typography variant="body2" color="text.secondary">
                            Or Create Account As
                        </Typography>
                    </Divider>

                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={handleProfessionalSignup}
                        sx={{
                            borderColor: '#73a5b5',
                            color: '#73a5b5',
                            '&:hover': {
                                borderColor: '#5d8a99',
                                backgroundColor: 'rgba(115, 165, 181, 0.04)'
                            }
                        }}
                    >
                        Birth Professional
                    </Button>

                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={handleFamilySignup}
                        sx={{
                            borderColor: '#73a5b5',
                            color: '#73a5b5',
                            '&:hover': {
                                borderColor: '#5d8a99',
                                backgroundColor: 'rgba(115, 165, 181, 0.04)'
                            }
                        }}
                    >
                        Family Member
                    </Button>
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
                <Button
                    onClick={onClose}
                    sx={{
                        color: 'text.secondary',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                    }}
                >
                    Maybe Later
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LoginSignupModal; 