import React, { useEffect, useState, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ChatList from './ChatList';
import SingleChat from './UserChat';
import moment from 'moment';
// import UserInfo from './userInfo';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteMessage, getChatRooms, getRoomChats, readMessage, socketConnection, switchMessengerToggle } from '../../redux/actions/socket.action';
import LoadingView from '../LoadingView';
import { isAuth } from '../../helpers/auth';
import { checkScrolledToTop } from '../../utils/scroll';
// import DeviceDetector from '../../utils/deviceDetector';
// import { checkScrolledToBottom, scrollToBottom } from '../../utils/scroll';
import { toast } from 'react-toastify';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './chatStyle.css';
import JSEncrypt from 'jsencrypt';
import { decryptPrivateKey } from '../../utils/aes';
// import '../../App.css';


const limitPerPage = process.env.REACT_APP_LISTING_LIMIT || 10;

const Messenger = () => {
    const isFirstLoad = useRef(true); // Track if it's the first load
    const currentUser = isAuth();
    const { chatId } = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [ chatLoadMore, setChatLoadMore ] = useState(false) 
    const [ loading, setLoading ] = useState({ initial: true, chat:true, chatLoadMore: false });
    const [ chatRooms, setChatRooms ] = useState([]);
    
    // const [ chatRoomsBackup, setChatRoomsBackup ] = useState([]);

    const [ messages, setMessages ] = useState([]);
    const [ room, setRoom ] = useState(null);
    const [ privateKey, setPrivateKey ] = useState(''); // logged in user private key

    // const [ searchRoom, setSearchRoom] = useState('');

    var message_list = document.getElementById('message__listgroup');

    const [chatTotalPages, setChatTotalPages] = useState(0);
    let [chatPage, setChatPage] = useState(0);

    const [ userMetaData, setUserMetaData] = useState(null);

    const bottomRef = useRef(null);
    const readMessagesSet = useRef(new Set()); // Use a ref to store IDs of read messages persistently across renders

    const socketState = useSelector(state => state.socket);
    const userMeta = useSelector(state => state.user);

    /** ---- Room Chats ---- */
    const getChats = () => {
        if( !loading.chatLoadMore){
            setLoading({ ...loading, chat: true });
        }
        dispatch(getRoomChats(chatId, { limit: limitPerPage, page: chatPage}));
    }

    const getRooms = (query) => {
        dispatch(getChatRooms(query));
    }

    /** --- This Condition is created incase user enters the direct url --- */
    useEffect(() => {
        if(chatId){
            getRooms(); // temp
            setChatPage(0)
            dispatch(switchMessengerToggle(true));
            getChats()
            socketConnection(chatId);
        }
    }, [chatId]);

    useEffect(() => {
        if(message_list){
            message_list.addEventListener('scroll', handleChatScroll);
            return () => {
                message_list.removeEventListener('scroll', handleChatScroll);
            };
        }
    }, [message_list])

    useEffect(() => {
        getRooms();
    }, []);

    useEffect(() => {
        if (!userMeta.error && userMeta.userMetaDetail) {
            setUserMetaData(userMeta.userMetaDetail);
          }
    }, [userMeta])

    /** --- Handle Chat Rooms --- */
    useEffect(() => {
        if(!socketState.error){
            const { rooms, chats, newMessage, deletedMessage, is_read_id, room_id } = socketState

               // Handle rooms data
            if( rooms && rooms.success){
                setChatRooms(rooms.rooms);
                // setPrivateKey(rooms.privateKey); // logged in user private key
                setPrivateKey(decryptPrivateKey(rooms.privateKey));
                // setChatRoomsBackup(rooms.rooms);
                setLoading({ ...loading, initial: false });
            }

            if( chats && chats.success && chats.room.roomId == chatId){
                setRoom(chats.room);
                // Call readMessage once on initial load
                if (chats.room && chats.room.last_message && !chats.room.last_message.is_read && isFirstLoad.current) {
                    readMessage(chats.room.roomId, chats.room.last_message._id, currentUser.aud);
                    isFirstLoad.current = false; // Set flag to false after first load
                }


                let _pa = Math.ceil(chats.totalCount/limitPerPage)
                setChatTotalPages(_pa);
                if((messages.length < chats.totalCount )){
                    const uniqueMessageIds = new Set();

                    // Filter out duplicate messages based on message IDs
                    const uniqueChatMessages = [...chats.messages, ...messages].filter(message => {
                        if (uniqueMessageIds.has(message._id)) {
                            // If the message ID already exists in the Set, it's a duplicate
                            return false;
                        } else {
                            // Add the message ID to the Set and return true to keep the message
                            uniqueMessageIds.add(message._id);
                            return true;
                        }
                    });
                    setMessages(uniqueChatMessages);
                }else{
                    setMessages(chats.messages);
                }
                setLoading({ ...loading, chat: false, initial: false, chatLoadMore: false });

                if(chatPage > 0 ){
                    const element = document.getElementById('message__listgroup');
                    if(element){
                        element.scrollTo(0, element.offsetHeight - 5);
                    }
                }
            }

            //   // Handle new message
            // if(newMessage &&  newMessage.roomId == chatId){
            //     let _messages = [...messages];
            //     _messages.push(newMessage);
            //     if(newMessage.sender !== currentUser.aud && newMessage.is_read === false){
            //         console.log('142.......')
            //         // readMessage(chatId, newMessage._id, currentUser.aud);
            //     }
            //     setMessages(_messages);
            // }

              // Handle new message
            // if (newMessage && newMessage.roomId && newMessage.roomId.toString() === chatId.toString()) {
            //     let _messages = [...messages];
            //     _messages.push(newMessage);

            //     if (newMessage.sender !== currentUser.aud && newMessage.is_read === false) {
            //         // Ensure readMessage is called only once per message
            //         if (!readMessagesSet.current.has(newMessage._id)) {
            //             console.log('Marking new message as read...');
            //             readMessage(chatId, newMessage._id, currentUser.aud);
            //             readMessagesSet.current.add(newMessage._id); // Mark the message as read
            //         }
            //     }
            //     setMessages(_messages);
            // }

            // Handle new message
            if (newMessage && newMessage.roomId) {
                setMessages((prevMessages) => {
                    if (
                        newMessage.roomId &&
                        chatId &&
                        newMessage.roomId.toString() === chatId.toString()
                    ) {
                        // Add the new message to the current active chat messages
                        const _messages = [...prevMessages, newMessage];
            
                        if (newMessage.sender !== currentUser.aud && !newMessage.is_read) {
                            // Mark the message as read
                            if (!readMessagesSet.current.has(newMessage._id)) {
                                readMessage(chatId, newMessage._id, currentUser.aud);
                                readMessagesSet.current.add(newMessage._id);
                            }
                        }
                        return _messages;
                    }
                    return prevMessages; // Leave unchanged for non-active chats
                });
            
                // // Move the associated chat room to the top
                // setChatRooms((prevRooms) => {
                //     const updatedRooms = [...prevRooms];
                //     const roomIndex = updatedRooms.findIndex(
                //         (room) => room.roomId === newMessage.roomId
                //     );
            
                //     if (roomIndex !== -1) {
                //         const [roomToMove] = updatedRooms.splice(roomIndex, 1); // Remove the room from its current position
                //         updatedRooms.unshift({
                //             ...roomToMove,
                //         }); // Add it to the top with updated details
                //     }
                //     return updatedRooms;
                // });
            }

                 // Handle message read update
            if(is_read_id){
                let _message = messages.findIndex(msg => msg._id === is_read_id);
                if(_message !== -1){
                    const _messages = messages.map((message) => {
                        // Convert message createdAt to a moment object for comparison
                        const messageCreatedAt = moment(message.createdAt);
                        const targetCreatedAt = moment(messages[_message].createdAt)
                        // Check if message's createdAt is less than or equal to the targetCreatedAt
                        if (messageCreatedAt.isSameOrBefore(targetCreatedAt)) {
                            return {
                                ...message,
                                is_read: true,  // Set is_read to true if the condition matches
                            };
                        } else {
                            return message;
                        }
                    });
                    
                    setMessages(_messages);
                }

                if(room_id){
                    let _room = chatRooms.findIndex(rms => rms.roomId === room_id)
                    if (_room !== -1) {
                        const _rooms = chatRooms.map(room => {
                            if (room.roomId === room_id) {
                                return {
                                    ...room,
                                    last_message: {
                                        ...room.last_message,
                                        is_read: true
                                    },
                                    unreadMessageCount:0,
                                };
                            }
                            return room; // Return the room as-is if it doesn't match
                        });
                        // console.log('____roooms_____', _rooms);
                        setChatRooms([..._rooms]);
                    }
                }
            }
            
            // Handle deleted message
            if(deletedMessage){
                const updatedListOfMessages = findAndReplaceRecord(messages, deletedMessage);
                setMessages(updatedListOfMessages);
            }
        }

         // Handle errors
        if(socketState.error){
            let _loading = Object.fromEntries(Object.entries(loading).map(([key, value]) => [key, false]));
            setLoading(_loading);
            toast.error(socketState.error.message || 'Oops Something goes wrong unable to load your chat');
        }

    }, [socketState])

    /** ---  This is used to open a user chat --- */
    const openChatRoom = (value, _room) => {
        // console.log(_room);
        if(_room && _room.last_message && typeof _room.last_message === 'object' && _room.last_message.is_read === false && 
            (_room.last_message?.sender?._id || _room.last_message?.sender) !== currentUser.aud){
                // console.log('Calling.....192')
            readMessage(_room.roomId, _room.last_message._id, currentUser.aud);
        }


        setMessages([]);
        dispatch(switchMessengerToggle(value))
        
        navigate(`/chat`.concat(value ? `/${_room.roomId}`: '/'));
    };

    useEffect(() => {
        if(chatPage < chatTotalPages){
            getChats();
        }else{
            setLoading({ ...loading, chatLoadMore: false  });
        }
    }, [chatPage])

    /** ---- Get More Services ---- */
    useEffect(() => {
        if(!loading.chatLoadMore || !messages.length) return;
        if(chatPage < chatTotalPages){
            setTimeout(() => {
                setChatPage(chatPage + 1)
                setChatLoadMore(true)
            }, 1200);
        }else{
            setLoading({ ...loading, chatLoadMore: false  });
            setChatLoadMore(false)
        }   
    }, [loading.chatLoadMore]);

    // const filterRecordsByUserName = (records, search) => {
    //     return records.filter(record => {
    //       return record.users.some(user => user._id !== currentUser.aud && user.name.toLowerCase().includes(search.toLowerCase()) );
    //     });
    // }

    const findAndReplaceRecord = (array, record) => {
        const index = array.findIndex(item => item._id.toString() === record._id.toString());
        if (index !== -1) {
          array[index] = record;
        }
        return array;
    }

    // useEffect(() => {
    //     if( searchRoom !== ''){
    //         const filteredRecords = filterRecordsByUserName(chatRooms, searchRoom);
    //         setChatRooms(filteredRecords);
    //     }else{
    //         setChatRooms(chatRoomsBackup);
    //     }
    // }, [searchRoom]);

    const handleChatScroll = () => {
        const scrolledToTop = checkScrolledToTop('message__listgroup');
        // console.log( 'scrolledToTop:', scrolledToTop );
        if (scrolledToTop === 0) {
            setLoading({ chatLoadMore: true});
        }
    }

    const handleDeleteMessage =  (messageId) => {
        deleteMessage({ 
            messageId, 
            roomId: chatId 
        });
    }

    // const isEncryptedMessage = (message) => {
    //     if (typeof message === 'string' && message.startsWith("ENC_")) {
    //         return true;
    //     }
    
    //     return false;
    // }

    // console.log( 'Chat Rooms', chatRooms );
    
    // const decryptMessage = (message) => {
    //     if(!privateKey) return 'Decryption Failed';
    //     if (isEncryptedMessage(message)) {
    //         // Decrypt if it's encrypted
    //         const encrypted = message.replace("ENC_", "")
    //         const decrypt = new JSEncrypt();
    //         decrypt.setPrivateKey(privateKey); // Set your private key
    //         const decrypted = decrypt.decrypt(encrypted);
    //         // console.log( 'decrypted :', decrypted )
    //         return (decrypted || 'Oops Unable to view display');
    //     }else{
    //         return message;
    //     }

    // };

    const decryptMessage = (message) => {
        if(!privateKey) return 'Oops! Something went wrong while opening the message';
        const decrypt = new JSEncrypt();
        decrypt.setPrivateKey(privateKey); // Set your private key
        const decrypted = decrypt.decrypt(message);
        // console.log( 'decrypted :', decrypted )
        return (decrypted || 'Message is unreadable. It might be corrupted or expired.');
    };



    const renderChatView = () => {
        return (
            <>
                <ChatList 
                    openChatRoom={openChatRoom} 
                    chatRooms={chatRooms}
                    currentUser={currentUser}
                    chatId={chatId}
                    getRooms={getRooms}
                    decryptMessage={decryptMessage}
                    userMeta={userMetaData}
                />
                {chatId 
                ?   <>
                        <SingleChat 
                            openChatRoom={openChatRoom} 
                            toggle={socketState.switchToggle}
                            chats={messages}
                            loading={loading} 
                            currentUser={currentUser}
                            room={room}
                            bottomRef={bottomRef}
                            // latestMessage={socketState?.message}
                            handleDeleteMessage={handleDeleteMessage}
                            chatLoadMore={chatLoadMore}
                            decryptMessage={decryptMessage}
                            userMeta={userMetaData}
                        />
                        {/* <UserInfo /> */}
                    </>
                :   
                    <div className={`empty__box d-flex align-items-center justify-content-center ${socketState.switchToggle ? 'toggleChat' : null}`}>
                        <p style={{ textAlign: 'center'}}> Start Your Chat</p>
                    </div>
                }
            </>
        )
    }
    return (
        <section className='popular--Wrapper buyer__page chat--page '>
            <Container fluid>
                <Row>
                    <Col sm={12} className='px-0'>
                        <div className="chat--box">
                        {loading.initial
                            ? <LoadingView appLoader={true}/>
                            : renderChatView()
                        }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Messenger;