import CryptoJS from 'crypto-js';

// export const decryptPrivateKey = (encryptedDataFromBackend) => {
//     const secretKey = CryptoJS.enc.Hex.parse(process.env.REACT_APP_AES_SECRET_KEY); // Secret Key 
//     const iv = CryptoJS.enc.Base64.parse('e7a9f1b4c8d6e1f3a0b2c3d4e5f6a7b8');

//     const decrypted = CryptoJS.AES.decrypt(encryptedDataFromBackend, secretKey, {
//         iv: iv,
//         mode: CryptoJS.mode.CBC,
//         padding: CryptoJS.pad.Pkcs7
//     });

//     const privateKey = decrypted.toString(CryptoJS.enc.Utf8);
//     console.log(privateKey);

//     return privateKey
// }

export const decryptPrivateKey = (encryptedPrivateKey) => {
    try {
        const parts = encryptedPrivateKey.split(':');
        const iv = CryptoJS.enc.Hex.parse(parts[0]);
        const encryptedText = CryptoJS.enc.Hex.parse(parts[1]);

        const decrypted = CryptoJS.AES.decrypt(
            { ciphertext: encryptedText },
            CryptoJS.enc.Hex.parse(process.env.REACT_APP_AES_SECRET_KEY),
            { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
        );

        return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        console.error('Decryption error:', error);
        return null;
    }
}
