import React, { useEffect, useState } from "react";
import { Col, Container, Row, Alert } from "react-bootstrap";
import { notValid, emailValidation, deepClone } from "../../../../utils/validations";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearReduxState, createSeller, requestEmailVerification, showAuthModal, showLoginModal } from "../../../../redux/actions/auth.actions";
import { userRole } from "../../../../helpers/config";
import { useLocation } from 'react-router-dom';
// import ProgressBar from "./ProgressBar";
import AuthModal from "../../AuthModal";
import { logEvent } from "../../../../googleAnalyticWrapper/analytic";

import { getCategories } from "../../../../redux/actions/categories.action";
import Swal from "sweetalert2";

import SignUpSteps from "./SignUpSteps";
import { getAllBBServices } from "../../../../redux/actions/services.action";
import { getLanguages } from "../../../../redux/actions/language.action";

function SellerSignUp() {
    // Get the current location object
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const _referralCode = queryParams.get('inviter');  // Get the value of "inviter"

    const intialValue = {
        name: '',
        email: '',
        otp: '',
        password: '',
        confirmPassowrd: '',
        role: userRole.seller,
        referralCode: _referralCode ? _referralCode : '',
        is_seller_with_staff: true,
        referralSource: _referralCode ? 'Referred By' : '',
        address: {},
        speciality: [],
        couponCode: ''
    };

    const dispatch = useDispatch();
    // const navigate = useNavigate()
    const alertValue = { message: '', show: false, variant: '' };

    const [seller, setSeller] = useState(deepClone(intialValue));
    // const [loading, setLoading] = useState(false);
    // const [alert, setAlert] = useState(deepClone(alertValue));

    // const [havePromo, setHavePromo] = useState(true);

    // const [termsChecked, setTermsChecked] = useState(false);
    // const [error, setError] = useState(false);

    const [showLogin, setLoginShow] = useState(false);

    /** -------- Store All BirthBridge Pre-define Services ---------- */
    const [ bbServiceList, setBBService] = useState([]);
    /** -------- Store Users Certification Selected Birth Bridge Services ---------- */
    const [selectedBBService, setSelectedBBService] = useState([]);
    /** -------- Store to save a flag to fetch user service or not ------------- */
    const [isUserService, setIsUserService] = useState(false);

    /** -------- Store Languages ---------- */
    const [languages, setLanguages] = useState([]);

    // const [currentStep, setCurrentStep] = useState(1);

    /** ---- Reducer State ---- */
    // const apiResult = useSelector(state => state.auth);
    const showModal = useSelector(state => state.showModal);

    /** -------- BB Services Reducer State -------- */
    const bbServices = useSelector(state => state.services);
    /** --------- Language Reducer State ---------- */
    const languageState = useSelector(state => state.language);

    /** ---- Places Reducer State ---- */
    const places = useSelector(state => state.places);

    useEffect(() => {
        logEvent('signup', 'bridger_signup', 'Bridger Signup Page Viewed', 1);
        /** ----- Specialities ----- */
        dispatch(getLanguages());
        dispatch(getAllBBServices());
    }, []);


     useEffect(() => {
        if (!languageState.error) {
          if (languageState.get && languageState.get.success) {
            setLanguages(languageState.get.languages)
          }
        }
      }, [languageState]);
    
    
      useEffect(() => {
        if (bbServices && bbServices.BBService && !isUserService) {
          setBBService(bbServices.BBService);
        //   setSelectedBBService(bbServices.BBService) // used temporary;
        }

        if (bbServices && bbServices.BBService && isUserService) {
            setSelectedBBService(bbServices.BBService)
        }
      }, [bbServices])


    // const handleNextStep = () => {
    //     if (isValid()) {
    //         setCurrentStep((prevStep) => prevStep + 1);
    //     }
    // };

    // const handlePrevStep = () => {
    //     setCurrentStep((prevStep) => prevStep - 1);
    // };

    // const handleReset = () => {
    //     setSeller(deepClone(intialValue))
    //     setAlert(deepClone(alertValue))
    //     setTermsChecked(false)
    //     setError(false)
    //     setLoading(false)
    // }

    // const isValid = () => {
    //     let isValid = true
    //     let errorMessages = {};

    //     switch (currentStep) {
    //         case 1:
    //             if (notValid(seller.email)) {
    //                 errorMessages['email'] = 'Email is required';
    //                 isValid = false;
    //             } else if (!emailValidation(seller.email)) {
    //                 errorMessages['email'] = 'Please enter a valid email address';
    //                 isValid = false;
    //             }

    //             if (seller.password && seller.password.length < 8) {
    //                 errorMessages['password'] = 'Password must be minimum 8 in length';
    //                 isValid = false;
    //             }

    //             if (notValid(seller.password)) {
    //                 errorMessages['password'] = 'Password is required';
    //                 isValid = false;
    //             }

    //             if (notValid(seller.confirmPassowrd)) {
    //                 errorMessages['confirmPassowrd'] = 'Confirm password is required'
    //                 isValid = false;
    //             }

    //             if (seller.password !== seller.confirmPassowrd) {
    //                 errorMessages['confirmPassowrd'] = ' Passwords do not match'
    //                 isValid = false;
    //             }

    //             if (!termsChecked) {
    //                 errorMessages['termsChecked'] = ' You must read and accept the Terms & Conditions before moving forward with our signup.'
    //                 isValid = false;
    //             }

    //             break;

    //         case 2:
    //             if (!seller.profilePic) {
    //                 errorMessages['profilePic'] = 'Please upload Profile Picture'
    //                 isValid = false;
    //             }

    //             if (notValid(seller.name)) {
    //                 errorMessages['name'] = 'Name is required'
    //                 isValid = false;
    //             }
    //             break;
    //         case 3:
    //             if (!seller.address) {
    //                 isValid = false;
    //                 errorMessages.location = "Address is required";
    //             }

    //             if (seller.address && typeof seller.address === 'object') {
    //                 if (Object.values(seller.address).every(add => !add)) {
    //                     isValid = false;
    //                     errorMessages.location = "Address is required";
    //                 } else {
    //                     errorMessages.address = {}
    //                     if (!seller.address.city) {
    //                         isValid = false;
    //                         errorMessages.address.city = "City is required";
    //                     }
    //                     if (!seller.address.state) {
    //                         isValid = false;
    //                         errorMessages.address.state = "State is required";
    //                     }
    //                 }
    //             }
    //             break;
    //         case 4:
    //             if (!seller.speciality || (Array.isArray(seller.speciality) && !seller.speciality.length)) {
    //                 isValid = false;
    //                 errorMessages.speciality = 'Speciality is required'
    //             }
    //             break;
    //         default: break;
    //     }
    //     if (!isValid) {
    //         setError({ ...error, ...errorMessages });
    //     }

    //     return isValid;
    // }

    // const onSubmit = async (e) => {

    //     e.preventDefault();
    //     if (isValid()) {
    //         setError(false)
    //         setLoading(true)
    //         setAlert(deepClone(alertValue))
    //         if (currentStep === 5) {
    //             dispatch(createSeller(seller))
    //         }
    //     }
    // }


    // useEffect(() => {
    //     if (apiResult.error) {
    //         let _alert = {}
    //         _alert['show'] = true
    //         _alert['message'] = apiResult.error
    //         _alert['variant'] = "danger"
    //         setAlert(_alert)
    //         // setUser(intialValue)
    //         setLoading(false)
    //     }

    //     if (apiResult.sellerAccountError) {
    //         let _alert = {}
    //         _alert['show'] = true
    //         _alert['message'] = apiResult.sellerAccountError
    //         _alert['variant'] = "danger"
    //         setAlert(_alert);
    //         setLoading(false)
    //     }

    //     if (apiResult.sellerAccountCreated) {
    //         setLoading(false)
    //         const data = { email: seller.email, mode: 'signup' }
    //         dispatch(requestEmailVerification(data))
    //         Swal.fire({
    //             position: "center",
    //             icon: "success",
    //             title: "Success! Please Verify Your Email",
    //             html: "<p>Your information has been submitted successfully.</p> <br> <p>Please check your email to verify your account.</p>",
    //             // title: "You’ve24 successfully submitted your information. Please go to your email and verify your account.",
    //             showConfirmButton: false,
    //             timer: 5000
    //         }).then((e) => {
    //             if (e.isDismissed) {
    //                 navigate('/')
    //                 handleSignInModel()
    //                 handleReset()
    //                 dispatch(clearReduxState());
    //                 dispatch(showLoginModal());
    //             }
    //         });

    //     }

    // }, [apiResult])

    useEffect(() => {
        if (showModal.authModal !== null || showModal.authModal !== undefined) {
            setLoginShow(showModal.authModal)
        }
    }, [showModal.authModal])

    // const showAlertMessage = () => {
    //     return (
    //         <Alert className="w-100" variant={alert.variant}> {alert['message']} </Alert>
    //     )
    // }

    // const handleSignInModel = () => {
    //     dispatch(showAuthModal(true))
    // }

    return (
        <section className="login--Wrapper signup--page">
            <Container style={{display:'flex'}}>
                <Row style={{display:'flex', flex:1}}>
                    <Col>
                        <div className="step--form" style={{padding: '15px'}}>
                            <SignUpSteps
                                languages={languages}
                                bbServiceList={bbServiceList}
                                selectedBBService={selectedBBService}
                                setIsUserService={setIsUserService}
                            />
                        </div>
                    </Col>
                    {/* <Col sm={12} lg={4} className="pr-lg-0 d-none d-lg-block">
                        <a className="company--brand">
                            <img src="/images/logo.webp" style={{width:'100%'}} loading='lazy' alt="BirthBridge Logo" />
                        </a>
                    </Col> */}
                </Row>
            </Container>

            {showLogin &&
                <AuthModal
                    show={showLogin}
                    userEmail={seller.email}
                    userPassword={seller.password}
                    onHide={() => dispatch(showAuthModal(false))}
                    handleReset={() => 
                        console.log('Auth Modal Reset Signup')
                        // handleReset()

                    }

                />
            }

        </section>

    )
}

export default SellerSignUp