import React, { useEffect, useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { MdClose } from "react-icons/md";
import DateCalender from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBBServices } from '../../../redux/actions/services.action';
import { getLanguages } from '../../../redux/actions/language.action';
import race from '../../../utils/race';
import { medicineApproachOptions } from '../../../utils/medicineApproach';
import { culturalExpertiseOptions } from '../../../utils/cultureExpertise';
import ShowMoreList from '../../../commonComponents/CustomShowMoreList';
import './searchFilter.css'
import * as auth from '../../../helpers/auth';

const SearchFilter = (props) => {
    const dispatch = useDispatch();

    const loggedIn = auth.isAuth();

    const [languages, setLanguages] = useState([]);

    /** --- Categories --- */
    const [categories, setCategories] = useState([]);
    /** ----BB  Products/Services  --- */
    const [bbServices, setBBServices] = useState([]);

    /** --- Redux States --- */
    const languageState = useSelector(state => state.language);
    const bbServicesState = useSelector(state => state.services);
    const categoryState = useSelector(state => state.categories);

    const {
        filter = {}, // Set default value to an empty object
        // setFilter,
        handleClearIndividualFilter,
        handleVirtual,
        // handleHasSchedules,
        // nearByOpen,
        // setNearByOpen,
        distanceRange,
        handleDistanceApply,
        // getLocationAndDistance,
        // showCalendar,
        // setShowCalendar,
        // handleRating,
        calendarRef,
        handleCalendarDate,
        currentDate,
        date,
        handleClearFilter,
        handleUpdateFilter,
        toggleSidebar,
    } = props;

    useEffect(() => {
        dispatch(getLanguages());
        dispatch(getAllBBServices());
    }, [dispatch]);

    useEffect(() => {
        if (languageState.error) {
            console.error(languageState.error.message);
        }

        if (!languageState.error && languageState.get && languageState.get.success) {
            setLanguages(languageState.get.languages);
        }
    }, [languageState]);

    /** ----- Redux State Categories ----- */
    useEffect(() => {
        if (categoryState.error) {
            console.error('[error]', 'Unable to load menus')
        }

        if (!categoryState.error && categoryState.all && categoryState.all.success) {
            setCategories(categoryState.all.categories);
        }
    }, [categoryState]);

    /** ----- Redux State bbService ------ */
    useEffect(() => {
        if (bbServicesState.BBService && bbServicesState.BBService.length) {
            setBBServices(bbServicesState.BBService)
        }
    }, [bbServicesState])

   

    const handleFilter = (name, field) => {
        const data = { ...filter };
        if (data.hasOwnProperty(field)) {
            if (Array.isArray(data[field])) {
                if (data[field].includes(name)) {
                    data[field] = data[field].filter(d => d !== name);
                    if (data[field].length === 0) {
                        delete data[field];
                    }
                } else {
                    data[field].push(name);
                }
            } else {
                if (field === 'rating' || field === 'service_type') {
                    data[field] = data[field] === name ? undefined : name;
                } else if (field === 'nearBy') {
                    data[field] = !!name;
                    if (!name) {
                        delete data[field];
                        delete data['lat'];
                        delete data['lng'];
                        delete data['distanceRange'];
                    }
                } else {
                    data[field] = data[field] === name ? undefined : [data[field], name];
                }
            }
        } else {
            data[field] = field === 'nearBy' ? true : name;
        }
        handleUpdateFilter(data);
    };

    // Convert filter.race to an array if it's a single value or null
    // const selectedRaceValues = Array.isArray(filter?.race) ? filter.race : filter?.race ? [filter.race] : [];
    // const handleFindNearMe = () => {
    //     handleDistanceApply()
    //     // setTimeout(() => {
            
    //     // }, 0)
    // }
    return (
        <div className="search-filter-sidebar">
            <div className='filter--section--title'>
                <h4>Filters</h4>
                <div className='filter--section--title--actions'>
                    <Button
                        variant="secondary"
                        className="clear-all-filter"
                        onClick={handleClearFilter}
                    >
                        Clear All
                    </Button>
                    <MdClose className='ml-2 sidebar-toggle-btn' onClick={toggleSidebar} />
                </div>
            </div>

            <div className='filter-section'>
                <div className='filter--section--subtitle'>
                    <h5>Virtual Services Available</h5>
                    <input
                        className='cursor-pointer'
                        type="checkbox"
                        onClick={() => handleVirtual()}
                        checked={['true', true].includes(filter?.virtual)}
                    />
                </div>
            </div>
            <div className="filter-section">
                <div className='filter--section--subtitle'>
                    {/* <h5>Find Near Me {(distanceRange?.distanceRange && filter?.nearBy) ? `(in ${distanceRange.distanceRange} miles)` : null}</h5> */}
                    <h5>Find Near Me {(distanceRange?.distanceRange && filter?.nearBy) ? `` : null}</h5>
                    <input
                        className='cursor-pointer'
                        type="checkbox"
                        onClick={() => handleDistanceApply()}
                        checked={['true', true].includes(filter?.nearBy)}
                    />
                </div>
            </div>
            <div className="filter-section">
                <div className='filter--section--subtitle'>
                    <h5>Cultural Background</h5>
                    <Button
                        variant="secondary"
                        className="clear-filter"
                        onClick={() => handleClearIndividualFilter('race')}
                    >
                        Clear
                    </Button>
                </div>

                <div className='filter--section--body'>
                    <ShowMoreList
                        items={race}
                        filter={filter}
                        filterKey="race"
                        handleFilter={handleFilter}
                    />
                </div>
            </div>

            <div className="filter-section">
                <div className='filter--section--subtitle'>
                    <h5>Languages Spoken</h5>
                    <Button
                        variant="secondary"
                        className="clear-filter"
                        onClick={() => handleClearIndividualFilter('language')}
                    >
                        Clear
                    </Button>
                </div>
                <div className='filter--section--body'>
                    <ShowMoreList
                        items={languages}
                        filter={filter}
                        filterKey="language"
                        handleFilter={handleFilter}
                    />
                </div>
            </div>

            {/* <div className="filter-section">
                <div className='filter--section--subtitle'>
                    <h5>
                        Highest Rated Professionals
                        {filter?.rating && 
                                <span 
                                    className='filter--count--badge'
                                > 
                                {`>= ${filter.rating}`} <FaStar size={14} style={{ marginBottom: '4px' }} />
                                </span>
                            }
                    </h5>
                    <Button
                        variant="secondary"
                        className="clear-filter"
                        onClick={() => handleClearIndividualFilter('rating')}
                    >
                        Clear
                    </Button>
                </div>
                <div className='filter--section--body'>
                    <ReverseRatingReadOnly value={filter?.rating || 0} onClick={(v) => handleRating(v)} />
                </div>
            </div> */}

            <div className="filter-section">
                <div className='filter--section--subtitle'>
                    <h5>
                        Categories
                        {/* {filter?.service && 
                        <span className='filter--count--badge'>
                            {`(${Array.isArray(filter['service']) ? filter.service.length : 1})`}
                        </span>
                        } */}
                    </h5>
                    <Button
                        variant="secondary"
                        className="clear-filter"
                        onClick={() => handleClearIndividualFilter('service')}
                    >
                        Clear
                    </Button>
                </div>
                <div className='filter--section--body'>
                    <ShowMoreList
                        items={bbServices}
                        filter={filter}
                        filterKey="service"
                        handleFilter={handleFilter}
                    />
                </div>
            </div>

            <div className="filter-section">
                <div className='filter--section--subtitle'>
                    <h5>
                        Specialties
                        {/* {filter?.category && 
                            <span className='filter--count--badge'>
                                {`(${Array.isArray(filter['category']) ? filter.category.length : 1})`}
                            </span>
                        } */}
                    </h5>
                    <Button
                        variant="secondary"
                        className="clear-filter"
                        onClick={() => handleClearIndividualFilter('category')}
                    >
                        Clear
                    </Button>
                </div>
                <div className='filter--section--body'>
                    <ShowMoreList
                        items={categories}
                        filter={filter}
                        filterKey="category"
                        handleFilter={handleFilter}
                    />
                </div>
            </div>

            <div className="filter-section">
                <div className='filter--section--subtitle'>
                    <h5>
                        Care Approach
                    </h5>
                    <Button
                        variant="secondary"
                        className="clear-filter"
                        onClick={() => handleClearIndividualFilter('medicineApproach')}
                    >
                        Clear
                    </Button>
                </div>
                <div className='filter--section--body'>
                    <ShowMoreList
                        items={medicineApproachOptions}
                        filter={filter}
                        filterKey="medicineApproach"
                        handleFilter={handleFilter}
                    />
                </div>
            </div>

            <div className="filter-section">
                <div className='filter--section--subtitle'>
                    <h5>
                        Cultural Knowledge
                    </h5>
                    <Button
                        variant="secondary"
                        className="clear-filter"
                        onClick={() => handleClearIndividualFilter('culturalExpertise')}
                    >
                        Clear
                    </Button>
                </div>
                <div className='filter--section--body'>
                    <ShowMoreList
                        items={culturalExpertiseOptions}
                        filter={filter}
                        filterKey="culturalExpertise"
                        handleFilter={handleFilter}
                    />
                </div>
            </div>


            <div className="filter-section">
                <div className='filter--section--subtitle'>
                    <h5>
                        Availability Date
                    </h5>
                    <Button
                        variant="secondary"
                        className="clear-filter"
                        onClick={() => handleClearIndividualFilter('dateFilter')}        >
                        Clear
                    </Button>
                </div>
                <div className='filter--section--body'>
                    <ListGroup className="business__list">
                        <DateCalender
                            ref={calendarRef}
                            onChange={(date) => handleCalendarDate(date)}
                            minDate={currentDate}
                            value={date.start}
                        />
                    </ListGroup>
                </div>
            </div>
        </div>
    );
};

export default SearchFilter;
