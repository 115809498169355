import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { BiMessage } from "react-icons/bi";
import RatingReadOnly from '../../components/RatingReadOnly';
import {
    displayAddress,
    generateLetterSVG,
    showOverview
} from '../../utils/validations';
import { createAndJoinRoom } from '../../redux/actions/socket.action';
import { isAuth } from '../../helpers/auth';
import { FaMapMarkerAlt, FaRegTimesCircle } from "react-icons/fa";
import Tooltip from '../Tooltip';
import { toast } from 'react-toastify';
import { RiMapPinRangeFill } from 'react-icons/ri';
import LoadingView from '../../components/LoadingView';
import { switchAppView } from '../../redux/actions/meta.action';
import { useDispatch } from 'react-redux';
import { showAuthModal } from '../../redux/actions/auth.actions';
import './style.css'
import { optimizedCloudinaryImage } from '../../utils/fileCompression';
import { nameSlugify } from '../../utils/nameHelper';

const virtualIcon = require('../../assets/images/virtual.png')

const CustomSuggestedCard = ({ userMeta, itemId, item, navigate, key, showCardRemoveIcon, removeLoader, handleRemoveSuggested }) => {
    const [loading, setLoading] = useState(false);
    const loggedIn = isAuth()
    const dispatch = useDispatch();

    const isStaff = item.associatedWithSeller && Array.isArray(item.associatedWithSeller) && item.associatedWithSeller.length
        ?
        (typeof item.associatedWithSeller[0] === 'object')
            ? item.associatedWithSeller[0]._id
            : item.associatedWithSeller[0]

        : null
    let services = (item.services && item.services.length) ? item.services.map((services) => services.category.name) : []


    // const handleTimeOutMessageLoading = () => {
    //     setTimeout(() => {
    //         if (!window.location.pathname.includes('chat')) {
    //             setLoading(false)
    //             renderToast('error', 'Could not complete your request')
    //         }
    //     }, 10000)
    // }

    const handleMessage = () => {
        const receiverId = item._id;
        let users = [receiverId, loggedIn.aud]
        setLoading(true);
        createAndJoinRoom({ users, sender: loggedIn.aud });

        // // Prevent Timeout if switching app view 
        // if (!['seller', 'staff'].includes(loggedIn.role)) {
        //     handleTimeOutMessageLoading()
        // }
    }

    const renderToast = (variant, message) => {
        toast[variant](message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const handleNavigate = () => {
        if (['seller', 'staff'].includes(loggedIn.role)) {
            dispatch(switchAppView('client'));
        }

        isStaff
            ? navigate(`/${nameSlugify(item.name)}/${isStaff}?tab=staff`)
            : navigate(`/${nameSlugify(item.name)}/${item._id}`)
    }

    return (
        <Card
            key={key}
            style={{ cursor: 'default' }}
        >
            {showCardRemoveIcon &&
                <span className="suggest-remove-card" onClick={() => (removeLoader === itemId) ? null : handleRemoveSuggested()}>{removeLoader === itemId ? <LoadingView size={12} height={24} color={'black'} /> : <FaRegTimesCircle />}</span>
            }
            <div className='card--media' style={{ padding: '0.5rem', width: '100%' }}>
                <Card.Link className='cursor-pointer' style={{ backgroundColor: 'transparent' }}>
                    {(item && item.profilePic)
                        ? <Card.Img
                            variant="top"
                            src={optimizedCloudinaryImage(item?.profilePic?.url)}
                            alt={`${item.name}`}
                        />
                        : generateLetterSVG(item.name, 36)}
                </Card.Link>
                <Card.Title>
                    <Card.Link>
                        <p className="text-capitalize" style={{ marginBottom: '5px', color: '#000' }}>
                            {item.name}
                        </p>
                        {services &&
                            // <p className='mb-1' style={{ marginBottom:'0px', color:'#000', textTransform:'capitalize', fontSize:'12px'}}>
                            //     {services.map(service => service).splice(0, 2).join(', ')} 
                            //     {services.length > 2 ? '... more' : ''}
                            // </p>
                            <p className='mb-1' style={{ marginBottom: '0px', color: '#000', textTransform: 'capitalize', fontSize: '12px' }}>
                                {services
                                    .filter(service => service !== 'Consultation')
                                    .splice(0, 2)
                                    .join(', ')}
                            </p>
                        }
                        <RatingReadOnly
                            value={item.averageRating ? item.averageRating : 0}
                            reviewsCount={true}
                            reviews={Array.from({ length: item.reviewCount }, () => [])}
                        />
                    </Card.Link>
                </Card.Title>

                {item.hasVirtualService &&
                    <Tooltip
                        placement={'auto'}
                        message={`${item.name} have virtual class`}
                    >
                        <img src={virtualIcon} alt='virtual icon' loading='lazy' style={{ width: '30px', height: '30px' }} />
                    </Tooltip>
                }

            </div>
            <Card.Body>
                <Card.Title>
                    <Card.Link style={{ cursor: 'default', marginTop: '10px' }}>
                        {(item && item.language && item.language.length) && <small className='text-capitalize'>{item.language.map(item => item.label).join(', ')}</small>}
                        {item.address &&
                            <span className='d-flex mt-2 text-capitalize'>
                                <small><FaMapMarkerAlt /> {displayAddress(item.address)}</small>
                                <small className='ml-1'><RiMapPinRangeFill /> {item.seller_serve_distance} Miles</small>
                            </span>
                        }
                        <small
                            className='mb-2 mt-2 mr-0 d-block card--desc text-capitalize'
                        >
                            {showOverview(item.seller_business_desc)}
                        </small>
                        <div className='d-flex'>
                            <Button
                                variant="primary"
                                className='w-100'
                                onClick={() => handleNavigate()}
                            // disabled
                            >
                                View Profile
                            </Button>
                            {(loggedIn.aud !== item._id) &&
                                <Tooltip
                                    placement={'auto'}
                                    message={`Reach out to ${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`}
                                >
                                    <Button
                                        variant={loading ? 'transparent' : 'secondary'}
                                        className='ml-2'
                                        onClick={() =>
                                            (loading || item?.isOutOfCountry)
                                                ? null
                                                : loggedIn
                                                    ? handleMessage(item?._id)
                                                    : dispatch(showAuthModal(true))

                                        }
                                        disabled={loading || item?.isOutOfCountry}
                                        style={{ cursor: item?.isOutOfCountry ? 'not-allowed' : 'default' }}
                                    >
                                        {loading ? <LoadingView size={12} height={24} color={'white'} /> : <BiMessage size={24} />}
                                    </Button>
                                </Tooltip>
                            }
                        </div>
                    </Card.Link>
                </Card.Title>
            </Card.Body>
        </Card>
    )
}

export default CustomSuggestedCard;