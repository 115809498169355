import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
    Box,
    Typography,
    CircularProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    Badge,
    Alert,
    Snackbar
} from "@mui/material";

import { FaChevronDown } from "react-icons/fa";
import axios from "axios";
import "./CustomSpecialtiesModal.css";

const CustomSpecialtiesModal = ({ products, handleClone, handleSetSellerProfile, filesIndex }) => {
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingSub, setLoadingSub] = useState({});
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/user/services/birth-bridge`);
            if (response.data?.bbService) {
                const sortedCategories = response.data.bbService.sort((a, b) =>
                    a.name === "Other Support Services" ? 1 : b.name === "Other Support Services" ? -1 : a.name.localeCompare(b.name)
                );
                setCategories(sortedCategories);
                sortedCategories.forEach((category) => fetchSubcategories(category._id));
            }
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
        setLoading(false);
    };

    const fetchSubcategories = async (categoryId) => {
        setLoadingSub((prev) => ({ ...prev, [categoryId]: true }));
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/user/category?bbservice=${categoryId}`);
            if (response.data?.categories) {
                setSubcategories((prev) => ({
                    ...prev,
                    [categoryId]: response.data.categories.sort((a, b) => a.name.localeCompare(b.name)),
                }));
            }
        } catch (error) {
            console.error("Error fetching subcategories:", error);
        }
        setLoadingSub((prev) => ({ ...prev, [categoryId]: false }));
    };

    // const isSubCategorySelected = useCallback(
    //     (subcategoryId) => products.some((product) => product.category.includes(subcategoryId)),
    //     [products]
    // );

    // const getSelectedSubCategoryCount = useCallback(
    //     (categoryId) => {
    //         const subCats = subcategories[categoryId] || [];
    //         return subCats.filter((subcategory) => isSubCategorySelected(subcategory._id)).length;
    //     },
    //     [subcategories, isSubCategorySelected]
    // );

    const isSubCategorySelected = (subcategoryId) => {
        return products.some((product) => product.category.includes(subcategoryId));
    };
    
    const getSelectedSubCategoryCount = (categoryId) => {
        const subCats = subcategories[categoryId] || [];
        return subCats.filter((subcategory) => isSubCategorySelected(subcategory._id)).length;
    };

    // const handleCheckboxChange = (subcategoryId, categoryId) => {
    //     const { copySellerProfileFiles, copyFile } = handleClone();
    
    //     console.log('copySellerProfileFiles', copySellerProfileFiles, 'copyFile', copyFile, categoryId, subcategoryId);
    
    //     if (!Array.isArray(copyFile.product)) {
    //         copyFile.product = [];
    //     }
    
    //     // Find product index
    //     let productIndex = copyFile.product.findIndex((product) => product.service?.toString() === categoryId?.toString());
    
    //     // console.log('Found productIndex:', productIndex);
    
    //     if (productIndex === -1) {
    //         // Add new product if not found
    //         copyFile.product.push({
    //             service: categoryId,
    //             category: [subcategoryId],
    //         });
    //     } else {
    //         let product = copyFile.product[productIndex];
    //         let selectedCategories = product?.category || [];
    //         console.log( product );
            
    //         if (selectedCategories.includes(subcategoryId)) {
    //             if (product.is_saved) {
    //                 return; // Prevent unchecking when is_saved is true
    //             }
    //             selectedCategories = selectedCategories.filter((id) => id !== subcategoryId);
    //         } else {
    //             selectedCategories.push(subcategoryId);
    //         }
    
    //         // Update product categories only if categories are not empty
    //         if (selectedCategories.length > 0) {
    //             product.category = selectedCategories;
    //         } else if (!product.is_saved) {
    //             // Remove product only if is_saved is false
    //             copyFile.product.splice(productIndex, 1);
    //         }
    //     }
    
    //     // console.log('Final Output:', copyFile);
    
    //     copySellerProfileFiles[filesIndex] = { ...copyFile };
    
    //     handleSetSellerProfile("files", [...copySellerProfileFiles]);
    // };


    const handleCheckboxChange = (subcategoryId, categoryId) => {
        const { copySellerProfileFiles, copyFile } = handleClone();
    
        if (!Array.isArray(copyFile.product)) {
            copyFile.product = [];
        }
    
        // Find product index
        let productIndex = copyFile.product.findIndex((product) => product.service?.toString() === categoryId?.toString());
    
        if (productIndex === -1) {
            // Add new product if not found
            copyFile.product.push({
                service: categoryId,
                category: [subcategoryId],
                addedThisSession: [subcategoryId], // Store newly added subcategory IDs in an array
            });
        } else {
            let product = copyFile.product[productIndex];
            let selectedCategories = product?.category || [];
    
            // Ensure addedThisSession exists as an array
            if (!Array.isArray(product.addedThisSession)) {
                product.addedThisSession = [];
            }
    
            // Check if the subcategory was recently added
            const isRecentlyAdded = product.addedThisSession.includes(subcategoryId);
    
            if (selectedCategories.includes(subcategoryId)) {
                // Prevent removal if the subcategory is saved and was not added in this session
                if (product.is_saved && !isRecentlyAdded) {
                    setShowAlert(true);
                    return; // Do nothing, as it's an original category or not dynamically added
                }
    
                // Remove subcategory from the selected categories
                selectedCategories = selectedCategories.filter((id) => id !== subcategoryId);
    
                // Remove subcategory from the addedThisSession array as well
                product.addedThisSession = product.addedThisSession.filter((id) => id !== subcategoryId);
            } else {
                // Add subcategory to the selected categories
                selectedCategories.push(subcategoryId);
    
                // Add subcategory to the addedThisSession array (only for new additions)
                if (!product.addedThisSession.includes(subcategoryId)) {
                    product.addedThisSession.push(subcategoryId);
                }
            }
    
            // Update product categories only if categories are not empty
            if (selectedCategories.length > 0) {
                product.category = selectedCategories;
            } else {
                // Remove product if no categories remain (only if it wasn’t saved)
                if (!product.is_saved) {
                    copyFile.product.splice(productIndex, 1);
                }
            }
        }
    
        // Update the files with the modified copyFile
        copySellerProfileFiles[filesIndex] = { ...copyFile };
    
        handleSetSellerProfile("files", [...copySellerProfileFiles]);
    };
 

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    return (
        <Box className="modal-container">
            <Snackbar
                open={showAlert}
                autoHideDuration={3000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleCloseAlert} severity="info" variant="filled">
                    Once you've saved a specialty, it can't be removed directly. If you need to make changes, please contact us at <a href="emailto:hello@mybirthbridge.com" style={{ textDecoration:'none', color:'#000'}}>hello@mybirthbridge.com</a> for assistance.
                </Alert>
            </Snackbar>
            {loading ? (
                <Box className="loading-container">
                    <CircularProgress />
                </Box>
            ) : (
                <Box>
                    {categories.map((category) => {
                        const selectedCount = getSelectedSubCategoryCount(category._id);
                        return (
                            <Accordion key={category._id}>
                                {/* <AccordionSummary expandIcon={<FaChevronDown />} className="accordion-summary">
                                    <Box className="accordion-header">
                                        <Typography>{category.name}</Typography>
                                        {selectedCount > 0 && (
                                            <Badge color="primary" badgeContent={selectedCount} className="badge-style" />
                                        )}
                                    </Box>
                                </AccordionSummary> */}
                                <AccordionSummary expandIcon={<FaChevronDown />} className="accordion-summary">
                                    <Box className="accordion-header">
                                        <span>{category.name}</span> {/* Changed Typography to span */}
                                        {selectedCount > 0 && (
                                            <Badge color="primary" badgeContent={selectedCount} className="badge-style" />
                                        )}
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {loadingSub[category._id] ? (
                                        <CircularProgress size={24} />
                                    ) : subcategories[category._id]?.length ? (
                                        <List className="list-container">
                                            {subcategories[category._id].map((subcategory) => (
                                                <ListItem key={subcategory._id} className="list-item">
                                                    <ListItemText primary={subcategory.name} />
                                                    <Checkbox
                                                        checked={isSubCategorySelected(subcategory._id)}
                                                        onChange={() => handleCheckboxChange(subcategory._id, category._id)}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    ) : (
                                        <Typography>No subcategories available</Typography>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </Box>
            )}
        </Box>
    );
};

export default React.memo(CustomSpecialtiesModal);