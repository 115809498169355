import React, { useState, useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { clearPlaceReduxState, getPlaceDetails, getPlaces } from '../../../../redux/actions/places.action';
import { isAddressBlank } from '../../../../utils/validations';
import { TextField } from '@mui/material';

const StepAddress = ({ seller, handleChangeInput, error, setError, progressBar, label }) => {

  const dispatch = useDispatch();

  const [showPlaces, setShowPlaces] = useState(false);
  const [locations, setLocations] = useState([]);
  const [searchLocation, setSearchLocation] = useState('');
  const [showAddressFields, setShowAddressFields] = useState(false)

  /** ---- Places Reducer State ---- */
  const places = useSelector(state => state.places);

  /** ----- Check Address ------ */
  useEffect(() => {
    // console.log( 'Address:', seller.address );
    if (!isAddressBlank(seller.address)) {
      setShowAddressFields(true)
    } else {
      setShowAddressFields(false)
    }
  }, [seller.address]);


  useEffect(() => {
    // console.log('Places', places)
    if (places.places && places.places.success && !places.error) {
      setLocations(places.places.suggestions)
    }

    if (places.placeDetail && places.placeDetail.success && !places.error) {
      // console.log( 'coming here....')
      handleChangeInput({ target: { name: 'address', value:places.placeDetail.details }}, 'business');
      dispatch(clearPlaceReduxState())
    }
  }, [places])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchLocation) {
        setShowPlaces(true)

        dispatch(getPlaces(searchLocation));
      } else {
        setShowPlaces(false)
      }
    }, 500);

    return () => clearTimeout(timer);

  }, [searchLocation]);


  const handleSelectPlace = (place) => {
    setShowPlaces(false);
    setSearchLocation('');
    setError({ ...error, location: '' })
    dispatch(getPlaceDetails(place.place_id))
  }

  // console.log('Seller', seller);
  return (
    <>
      {/* <h3 className="mb-3">Location</h3> */}
      {/* {progressBar} */}
      {/* <p className="text-left mb-3">Please start typing out your location...</p> */}

      <Form.Group className="">
        {/* <Form.Control
          type="text"
          value={searchLocation}
          onChange={(e) => setSearchLocation(e.target.value)}
          placeholder="Begin Typing"
        /> */}

        <TextField
          fullWidth
          label={label ? label : 'Type in your Business Address'}
          value={searchLocation}
          onChange={(e) => setSearchLocation(e.target.value)}
        />

        {(error && error.location) && <span className="error error-message">{error.location}</span>}
        {(showPlaces && locations.length > 0) &&
          <div className="places--list">
            {locations.map((suggestion, pIdx) => (
              <span
                key={pIdx}
                onClick={() => handleSelectPlace(suggestion)}
              >
                {suggestion.description}
              </span>
            ))}
          </div>
        }
      </Form.Group>

      {showAddressFields && (
        <div className="col-sm-12 col-md-12 mt-2 mb-3">
          <TextField
            fullWidth
            label="Street ( Optional )"
            value={seller?.address?.street || ''}
            name="address.street"
            onChange={(e) =>
              handleChangeInput(e, 'business', 'address.street')
            }
          />
          <TextField
            fullWidth
            label="Landmark (Optional)"
            name="address.landmark"
            value={seller?.address?.landmark || ''}
            onChange={(e) =>
              handleChangeInput(e, 'business', 'address.landmark')
            }
            className="mt-2"
          />
          <TextField
            fullWidth
            label="City"
            name="address.city"
            value={seller?.address?.city || ''}
            onChange={(e) =>
              handleChangeInput(e, 'business', 'address.city')
            }
            className="mt-2"
          />
          {(error?.address?.city) && <span className="error error-message">{error.address.city}</span>}
          <TextField
            fullWidth
            label="State / Province"
            name="address.state"
            value={seller?.address?.state || ''}
            onChange={(e) =>
              handleChangeInput(e, 'business', 'address.state')
            }
            className="mt-2"
          />
          {(error?.address?.state) && <span className="error error-message">{error.address.state}</span>}
          <TextField
            fullWidth
            label="Country ( Optional )"
            name="address.country"
            value={seller?.address?.country || ''}
            onChange={(e) =>
              handleChangeInput(e, 'business', 'address.country')
            }
            className="mt-2"
          />
          <TextField
            fullWidth
            label="Zip Code ( Optional )"
            name="address.postalCode"
            value={seller?.address?.postalCode || ''}
            onChange={(e) =>
              handleChangeInput(e, 'business', 'address.postalCode')
            }
            className="mt-2"
          />
        </div>
      )}
      {/* {showAddressFields &&
        <div className="col-sm-12 col-md-12 mt-2 mb-3">
          <TextField
            fullWidth
            label="Street"
            value={(seller && seller.address && seller.address.street) ? seller.address.street : ''}
            name="address.street"
            onChange={(e) => handleChangeInput('address', e.target.value, 'street')}
          />
          <TextField
            fullWidth
            label="Landmark ( Optional )"
            name="address.landmark"
            value={(seller && seller.address && seller.address.landmark) ? seller.address.landmark : ''}
            onChange={(e) => handleChangeInput('address', e.target.value, 'landmark')}
            className="mt-2"
          />
          <TextField
            fullWidth
            label="City"
            name="address.city"
            value={(seller && seller.address && seller.address.city) ? seller.address.city : ''}
            onChange={(e) => handleChangeInput('address', e.target.value, 'city')}
            className="mt-2"
          />
          {(error && error.address && error.address.city) && <span className="error error-message">{error.address.city}</span>}
          <TextField
            fullWidth
            label="State / Province"
            name="address.state"
            value={(seller && seller.address && seller.address.state) ? seller.address.state : ''}
            onChange={(e) => handleChangeInput('address', e.target.value, 'state')}
            className="mt-2"
          />
          {(error && error.address && error.address.state) && <span className="error error-message">{error.address.state}</span>}
          <TextField
            fullWidth
            label="Country"
            name="address.country"
            value={(seller && seller.address && seller.address.country) ? seller.address.country : ''}
            onChange={(e) => handleChangeInput('address', e.target.value, 'country')}
            className="mt-2"
          />
          <TextField
            fullWidth
            label="Zip Code"
            name="address.postalCode"
            value={(seller && seller.address && seller.address.postalCode) ? seller.address.postalCode : ''}
            onChange={(e) => handleChangeInput('address', e.target.value, 'postalCode')}
            className="mt-2"
          />
        </div>
      } */}
    </>
  );
};

export default StepAddress;
