import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { generateDateArray } from '../../utils/scheduleHelper';
import { isAuth } from '../../helpers/auth';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
    Box,
    Typography,
    Button,
    useTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    Card,
    CardContent,
    IconButton,
    Avatar,
    Stack,
    CircularProgress,
    Chip,
    Tooltip,
    Pagination
} from '@mui/material';
import { showAuthModal } from '../../redux/actions/auth.actions';
import { createAndJoinRoom } from '../../redux/actions/socket.action';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { switchAppView } from '../../redux/actions/meta.action';


const CustomizeCalendar = ({ start, end, schedules, onClick, handleGotoNextApptDate, associatedWithUser }) => {
    // console.log( schedules, associatedWithUser)
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [openAuthModal, setOpenAuthModal] = useState(false);
    const today = moment().format('YYYY-MM-DD');
    const [dates, setDates] = useState([]);
    const [messageLoader, setMessageLoader] = useState(false);
    const loggedIn = isAuth();
    const [userMetaData, setUserMetaData] = useState(null);

    const userMeta = useSelector(state => state.user);
    const socketState = useSelector(state => state.socket);


    useEffect(() => {
        if (!userMeta.error && userMeta.userMetaDetail) {
            setUserMetaData(userMeta.userMetaDetail);
        }
    }, [userMeta])

    useEffect(() => {
        if (socketState) {
            const { roomJoined } = socketState
            if (roomJoined) {
                // switching app view when seller initiate chat 
                if (['seller', 'staff'].includes(loggedIn.role)) {
                    dispatch(switchAppView('seller'));
                }

                navigate(`/chat/${roomJoined.roomId}`);
            }
        }
    }, [socketState.roomJoined])

    useEffect(() => {
        if (start && end) {
            setDates(generateDateArray(start, end));
        }
    }, [start, end]);

    // Group the schedules ( records ) by date
    const groupedRecords = (schedules || []).reduce((groups, record) => {
        const date = moment(record.date).format('YYYY-MM-DD'); // Convert the date to local time
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(record);
        return groups;
    }, {});

    // Sort The Group 
    const sortedGroupedRecords = Object.fromEntries(
        Object.entries(groupedRecords).sort(([date1], [date2]) => moment(date1).diff(date2))
    );

    // Convert the object of groups into an array
    const groupedRecordsArray = Object.entries(sortedGroupedRecords).map(([date, records]) => {
        return { date, records };
    });

    const scheduleInCurrentRange = groupedRecordsArray.find(obj => moment(obj.date).isSameOrAfter(today) && dates.includes(obj.date)) || null;

    function findScheduleInCurrentRange() {
        if (scheduleInCurrentRange) {
            return scheduleInCurrentRange;
        } else {
            const firstDateAfterToday = groupedRecordsArray.find(obj => moment(obj.date).isAfter(today));
            return firstDateAfterToday || null;
        }
    }


    const schedule = findScheduleInCurrentRange(groupedRecordsArray, dates);

    const renderCurrentRangeAppt = () => {
        return (
            <ul>
                {dates.map((date, dateIndex) => {
                    // Check if the date exists in the groupedRecordsArray
                    const foundRecord = groupedRecordsArray.find(record => moment(record.date).isSame(date, 'day'));
                    const className = foundRecord ? 'cursor-pointer' : 'no--appt';
                    const appointmentCount = foundRecord ? foundRecord.records.length : 0;

                    return (
                        <li
                            className={className}
                            key={dateIndex}
                            onClick={(e) => {
                                e.preventDefault();
                                onClick(date);
                            }}
                        >
                            <span>{moment(date).format('ddd')}</span>
                            <small className='mb-2'>{moment(date).format('MMM D')}</small>
                            <span>{appointmentCount > 0 ? appointmentCount : 'No'}</span>
                            <small>appts</small>
                        </li>
                    );
                })}
            </ul>
        )
    }

    /** ----- Display Next Scheduled Appt ----- */
    const renderNextScheduledAppt = () => {
        return (
            <p
                className='next--scheduled--appt cursor-pointer'
                onClick={() => handleGotoNextApptDate(schedule.date)}
            >
                Next Available, {moment(schedule.date).format('ddd, MMM D, YYYY')}
            </p>
        )
    }

    /** ----- Display When No Scheduled ------- */
    const renderNoScheduledAppt = () => {
        return (
            <p className='no--scheduled--appt cursor-pointer' onClick={() => onClick()}>
                {/* No schedule available, please click here to check other services */}
                No availability. Explore other specialties
            </p>
        )
    }

    const renderToast = (variant, message) => {
        toast[variant](message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    // const handleTimeOutMessageLoading = () => {
    //     setTimeout(() => {
    //         if (!window.location.pathname.includes('chat')) {
    //             // setLoading({ ...loader, message: false })
    //             setMessageLoader(false);
    //             console.log('CustomizeCalendar - handleTimeOutMessageLoading')
    //             renderToast('error', 'Could not complete your request')
    //         }
    //     }, 10000)
    // }

    const handleMessage = () => {
        const receiverId = associatedWithUser._id;
        let users = [receiverId, loggedIn.aud]
        // setLoading({ ...loader, message: true });
        setMessageLoader(true)
        createAndJoinRoom({ users, sender: loggedIn.aud });
        // // Prevent Timeout if switching app view 
        // if (!['seller', 'staff'].includes(loggedIn.role)) {
        //     handleTimeOutMessageLoading()
        // }
    }

    const handleLogin = () => {
        navigate('/login');
        setOpenAuthModal(false);
    };

    const handleProfessionalSignup = () => {
        navigate('/bridger-signup');
        setOpenAuthModal(false);
    };

    const handleFamilySignup = () => {
        navigate('/family-signup');
        setOpenAuthModal(false);
    };



    const renderReachOut = () => {

        return (
            <>
                <p
                    className='no--scheduled--appt cursor-pointer'
                    onClick={(e) => {
                        e.preventDefault();
                        if (messageLoader) return;

                        if (!userMetaData?.is_active) {
                            setOpenAuthModal(true);
                            return;
                        }

                        if (!loggedIn) {
                            setOpenAuthModal(true);
                            return;
                        }

                        handleMessage();
                    }}
                    style={{
                        cursor: messageLoader ? 'not-allowed' : 'pointer'
                    }}
                >
                    {messageLoader ? <CircularProgress size={20} sx={{ color: 'primary.main' }} /> : 'Check Availability & Pricing'}
                </p>


                <Dialog
                    open={openAuthModal}
                    onClose={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpenAuthModal(false);
                    }}
                    maxWidth="xs"
                    fullWidth
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <DialogTitle sx={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: theme.palette.primary.main
                    }}>
                        Log In to Continue
                    </DialogTitle>

                    <DialogContent>
                        <Typography align="center" sx={{ mb: 2 }}>
                            To take this next step—like sending a message or booking support—you’ll need to log in or create a free account.
                        </Typography>

                        {/* Login Button */}
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            mt: 2
                        }}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={handleLogin}
                                sx={{
                                    backgroundColor: '#73a5b5',
                                    '&:hover': {
                                        backgroundColor: '#5d8a99'
                                    }
                                }}
                            >
                                Log In
                            </Button>

                            <Divider sx={{ my: 2 }}>
                                <Typography variant="body2" color="text.secondary">
                                    Or Create a Free Account
                                </Typography>
                            </Divider>

                            {/* Professional Registration Button */}
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={handleProfessionalSignup}
                                sx={{
                                    borderColor: '#73a5b5',
                                    color: '#73a5b5',
                                    '&:hover': {
                                        borderColor: '#5d8a99',
                                        backgroundColor: 'rgba(115, 165, 181, 0.04)'
                                    }
                                }}
                            >
                                I’m a Birth Professional
                            </Button>

                            {/* Family Registration Button */}
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={handleFamilySignup}
                                sx={{
                                    borderColor: '#73a5b5',
                                    color: '#73a5b5',
                                    '&:hover': {
                                        borderColor: '#5d8a99',
                                        backgroundColor: 'rgba(115, 165, 181, 0.04)'
                                    }
                                }}
                            >
                                I’m a Family Member
                            </Button>
                        </Box>
                    </DialogContent>

                    <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setOpenAuthModal(false);
                            }}
                            sx={{
                                color: 'text.secondary',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                }
                            }}
                        >
                            Maybe Later
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
    return (
        <div className={`${scheduleInCurrentRange ? 'calendar--cal' : 'calendar-no-appt'}`}>
            {(schedules && schedules.length) && scheduleInCurrentRange
                ? renderCurrentRangeAppt()
                : schedule
                    ? renderNextScheduledAppt()
                    :
                    <div className='text-center'>
                        {/* {renderNoScheduledAppt()} */}
                        {(loggedIn.aud !== associatedWithUser._id) && renderReachOut()}
                    </div>
            }
        </div>
    );
};

export default CustomizeCalendar;