import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    Avatar,
    Button,
    TextField,
    Stack,
    CircularProgress,
    Tooltip,
    IconButton,
    useTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    Chip,
    Menu,
    MenuItem,
    FormControl,
    InputLabel,
    Select
} from '@mui/material';
import { FiHeart, FiMessageSquare, FiArrowLeft, FiEdit2, FiTrash2, FiMoreVertical } from 'react-icons/fi';
import { styled } from '@mui/material/styles';
import { communityService } from '../../services/communityService';
import { formatDate } from '../../utils/dateUtils';
import { initializeAds, refreshAds } from '../../utils/adUtils';
import { getUserProfile } from "../../redux/actions/user.action";
import { useDispatch, useSelector } from "react-redux";
import { FaCheckCircle, FaExternalLinkAlt } from 'react-icons/fa';
import { alpha } from '@mui/material/styles';
import SEO from "../../seo";
import { CATEGORIES_FILTER, CATEGORY_STYLES } from './postsUtils';
import * as auth from '../../helpers/auth';
import AdUnit from './AdUnit';
import { nameSlugify } from '../../utils/nameHelper';

const getCategoryStyles = (category) => {
    return CATEGORY_STYLES[category] || {
        color: '#1976d2',  // default color
        backgroundColor: '#e3f2fd',  // default background
    };
};



const StyledCard = styled(Paper)(({ theme }) => ({
    borderRadius: theme.spacing(2),
    overflow: 'hidden'
}));

const heartColor = '#73a5b5';

const ActionButton = styled(IconButton)(({ theme, isactive }) => ({
    backgroundColor: isactive === 'true' ? alpha(heartColor, 0.05) : 'transparent',
    '&:hover': {
        backgroundColor: isactive === 'true'
            ? alpha(heartColor, 0.1)
            : theme.palette.action.hover,
    },
    '& svg': {
        color: isactive === 'true' ? heartColor : theme.palette.text.secondary,
    }
}));

const CommentReply = ({
    reply,
    onLike,
    onReply,
    commentLikes,
    user,
    replyingTo,
    replyContent,
    setReplyContent,
    onReplySubmit,
    isReplySubmitting,
    renderActionMenu,
    userId,
    parentCommentId
}) => {
    const handleReplyClick = (e, replyId) => {
        e.stopPropagation();
        onReply(replyId);
    };

    const isLoading = isReplySubmitting[reply._id];
    const isLiked = commentLikes[reply._id]?.isLiked;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 2,
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                    height: '8px',
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#73a5b5',
                    borderRadius: '4px',
                    '&:hover': {
                        background: '#5d8a99',
                    },
                },
                // For Firefox
                scrollbarWidth: 'thin',
                scrollbarColor: '#73a5b5 #f1f1f1',
            }}
        >
            <Avatar
                src={reply.author?.profilePic?.url}
                alt={reply.author?.name}
                sx={{ width: 32, height: 32 }}
            />
            <Box sx={{ flex: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="subtitle2" fontWeight="600">
                            {reply.author?.name}

                        </Typography>
                        <Typography variant="subtitle2" fontWeight="600">
                            {reply.author?.is_business_approved && (
                                <Tooltip title="Verified Business with BirthBridge" arrow>
                                    <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 0.5 }}>
                                        <FaCheckCircle size={16} color="#1976d2" />
                                    </Box>
                                </Tooltip>
                            )}
                        </Typography>
                        <Typography style={{ marginLeft: 8, whiteSpace: 'pre' }} variant="caption" color="text.secondary">
                            • {formatDate(reply.createdAt)}
                        </Typography>
                    </Box>
                    {!reply.isReplyDeleted && reply.author?._id === userId && renderActionMenu('reply', reply, parentCommentId)}
                </Box>
                <Typography
                    variant="body2"
                    sx={{
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word'
                    }}
                >
                    {reply.content}
                </Typography>

                {/* Existing action buttons */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {!reply.isReplyDeleted && <ActionButton
                        onClick={() => onLike(reply._id)}
                        size="small"
                        isactive={isLiked ? 'true' : 'false'}
                    >
                        <FiHeart
                            size={16}
                            style={{
                                fill: isLiked ? heartColor : 'none',
                                color: isLiked ? heartColor : 'inherit',
                                strokeWidth: 2,
                                marginRight: 6,
                            }}
                        />
                        <Typography
                            variant="caption"
                            color={isLiked ? heartColor : 'text.secondary'}
                        >
                            {reply.likes?.length || 0}
                        </Typography>
                    </ActionButton>}
                    {!reply.isReplyDeleted && <Button
                        size="small"
                        startIcon={<FiMessageSquare size={16} />}
                        onClick={(e) => handleReplyClick(e, reply._id)}
                        sx={{
                            textTransform: 'none',
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            }
                        }}
                    >
                        Reply
                    </Button>}
                </Box>

                {/* Keep all existing reply functionality */}
                {replyingTo === reply._id && (
                    <Box sx={{ mt: 2, ml: 2 }}>
                        <TextField
                            multiline
                            minRows={3}
                            maxRows={10}
                            fullWidth
                            value={replyContent}
                            onChange={(e) => setReplyContent(e.target.value)}
                            placeholder="Write your reply..."
                            sx={{
                                '& .MuiInputBase-input': {
                                    whiteSpace: 'pre-wrap',
                                    wordBreak: 'break-word'
                                }
                            }}
                        />
                        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                            <Button
                                size="small"
                                onClick={() => onReply(null)}
                                sx={{ textTransform: 'none' }}
                                disabled={isLoading}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                onClick={() => onReplySubmit(reply._id)}
                                disabled={!replyContent.trim() || isLoading}
                                sx={{ textTransform: 'none' }}
                            >
                                {isLoading ? <CircularProgress size={20} /> : 'Reply'}
                            </Button>
                        </Box>
                    </Box>
                )}

                {/* Nested replies */}
                {reply.replies && reply.replies.length > 0 && (
                    <Box sx={{ ml: 0.5, mt: 2 }}>
                        <Stack spacing={2}>
                            {reply.replies.map((nestedReply) => (
                                <CommentReply
                                    key={nestedReply._id}
                                    reply={nestedReply}
                                    onLike={onLike}
                                    onReply={onReply}
                                    commentLikes={commentLikes}
                                    user={user}
                                    replyingTo={replyingTo}
                                    replyContent={replyContent}
                                    setReplyContent={setReplyContent}
                                    onReplySubmit={onReplySubmit}
                                    isReplySubmitting={isReplySubmitting}
                                    renderActionMenu={renderActionMenu}
                                    userId={userId}
                                    parentCommentId={reply._id}
                                />
                            ))}
                        </Stack>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const AD_SLOTS = {
    LEFT_SIDEBAR: '2920797521',
    RIGHT_SIDEBAR: '6486011166'
};


const SinglePost = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();

    const { postId } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [replies, setReplies] = useState([]);
    const [mainCommentContent, setMainCommentContent] = useState('');
    const [replyContent, setReplyContent] = useState('');
    const [replyingTo, setReplyingTo] = useState(null);
    const [commentLikes, setCommentLikes] = useState({});
    // const userId = localStorage.getItem('userId');
    const [isPostLiked, setIsPostLiked] = useState(false);
    const sellerProfileData = useSelector(state => state.user);

    const userId = sellerProfileData?.userDetail?._id;
    const user = sellerProfileData?.userDetail;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isReplySubmitting, setIsReplySubmitting] = useState({});
    const [openAuthModal, setOpenAuthModal] = useState(false);
    // const isAuthenticated = sellerProfileData?.userDetail?._id;

    // const isAuthenticated = sellerProfileData?.userDetail?.id === 'seller' || familyProfileData?.userDetail?.role === 'buyer';
    const isAuthenticated = sellerProfileData?.userDetail?._id;

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [editMode, setEditMode] = useState(null);
    const [editContent, setEditContent] = useState('');
    const [editTitle, setEditTitle] = useState('');
    const [editDescription, setEditDescription] = useState('');
    const [editCategory, setEditCategory] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isPostEditing, setIsPostEditing] = useState(false);
    const [editPostTitle, setEditPostTitle] = useState('');
    const [editPostDescription, setEditPostDescription] = useState('');
    const [editPostCategory, setEditPostCategory] = useState('');

    const isInitialLoad = useRef(true);


    const loggedIn = auth.isAuth();


    useEffect(() => {
        if (loggedIn) {
            dispatch(getUserProfile());
        }
    }, [dispatch]);




    useEffect(() => {
        if (isInitialLoad.current) {
            window.scrollTo(0, 0); // Immediate scroll without smooth behavior
            isInitialLoad.current = false;
        }
    }, []); // Empty dependency array for only on mount

    const handleMenuOpen = (event, type, item, commentId = null) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedItem({ type, item, commentId });
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedItem(null);
    };

    const handleEdit = (type, id, content, commentId = null) => {

        // Pre-populate content based on type
        if (type === 'post') {
            setEditTitle(post.title);
            setEditDescription(post.description);
            setEditCategory(post.category);
        } else {
            setEditContent(content);
        }

        setEditMode({
            type,
            id,
            content,
            commentId
        });
        handleMenuClose();
    };

    const handleSaveEditDelete = async (type, id, commentId) => {
        try {
            setIsSaving(true);

            if (type === 'post') {
                // Existing post edit logic
                const response = await communityService.deletePost(post._id);
                if (response.data.success) {
                    navigate('/community');
                }
            } else if (type === 'reply') {
                const response = await communityService.editReply(
                    post._id,
                    commentId,
                    id,
                    '[ DELETED ]',
                    true
                );

                // Only update replies state if it's a top-level comment
                if (id === commentId) {
                    const updatedReplies = response.data.data.replies;
                    setReplies(updatedReplies);
                } else {
                    // Use the response data directly for nested replies

                    setReplies(response.data.data.replies);
                }
            }

            // Clear edit mode and content
            setEditMode(null);
            setEditContent('');
            setEditTitle('');
            setEditDescription('');
            setEditCategory('');

        } catch (error) {
            console.error('[FE] Error saving edit:', error);
        } finally {
            setIsSaving(false);
        }
    };
    const handleSaveEdit = async () => {
        try {
            setIsSaving(true);

            if (editMode.type === 'post') {
                // Existing post edit logic
                const response = await communityService.editPost(post._id, {
                    title: editTitle,
                    description: editDescription,
                    category: editCategory
                });
                setPost(response.data.data);
            } else if (editMode.type === 'reply') {
                const response = await communityService.editReply(
                    post._id,
                    editMode.commentId,
                    editMode.id,
                    editContent
                );

                // Only update replies state if it's a top-level comment
                if (editMode.id === editMode.commentId) {
                    const updatedReplies = response.data.data.replies.map(reply => ({
                        ...reply,
                        replies: reply.replies?.map(nestedReply => ({
                            ...nestedReply,
                            // If author is a string (ID), use the parent reply's author object
                            author: typeof nestedReply.author === 'string' ? reply.author : nestedReply.author
                        }))
                    }));
                    setReplies(updatedReplies);
                } else {
                    // Existing nested reply logic remains unchanged
                    const updatedReplies = JSON.parse(JSON.stringify(response.data.data.replies));
                    setReplies(prevReplies => {
                        return prevReplies.map(topLevelReply => {
                            const updateNestedReplies = (replyList) => {
                                return replyList.map(reply => {
                                    if (reply._id === editMode.id) {
                                        return {
                                            ...reply,
                                            content: editContent,
                                            updatedAt: Date.now()
                                        };
                                    }
                                    if (reply.replies && reply.replies.length > 0) {
                                        return {
                                            ...reply,
                                            replies: updateNestedReplies(reply.replies)
                                        };
                                    }
                                    return reply;
                                });
                            };

                            return {
                                ...topLevelReply,
                                replies: updateNestedReplies(topLevelReply.replies || [])
                            };
                        });
                    });
                }
            }

            // Clear edit mode and content
            setEditMode(null);
            setEditContent('');
            setEditTitle('');
            setEditDescription('');
            setEditCategory('');

        } catch (error) {
            console.error('[FE] Error saving edit:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const renderActionMenu = (type, item, commentId = null) => {
        const isOwner = item.author?._id === userId;

        if (!isOwner) return null;

        return (
            <>
                <IconButton
                    size="small"
                    onClick={(e) => handleMenuOpen(e, type, item, commentId)}
                    sx={{ ml: 'auto' }}
                >
                    <FiMoreVertical />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedItem?.item._id === item._id}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={() => handleEdit(type, item._id, type === 'post' ? item.description : item.content, commentId)}>
                        <FiEdit2 size={16} style={{ marginRight: 8 }} />
                        Edit
                    </MenuItem>

                    <MenuItem
                        onClick={() => handleSaveEditDelete('reply', item._id, commentId)}
                        disabled={isSaving}
                    >
                        <div className="flex items-center">
                            {isSaving ? (
                                <>
                                    <CircularProgress size={16} style={{ marginRight: 8 }} />
                                    Deleting...
                                </>
                            ) : (
                                <>
                                    <FiTrash2 size={16} style={{ marginRight: 8 }} />
                                    Delete
                                </>
                            )}
                        </div>

                    </MenuItem>
                </Menu >
            </>
        );
    };
    const renderActionMenuPost = (type, item, commentId = null) => {
        const isOwner = item.author?._id === userId;

        if (!isOwner) return null;

        return (
            <>
                <IconButton
                    size="small"
                    onClick={(e) => handleMenuOpen(e, type, item, commentId)}
                    sx={{ ml: 'auto' }}
                >
                    <FiMoreVertical />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedItem?.item._id === item._id}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={() => handleEdit(type, item._id, type === 'post' ? item.description : item.content, commentId)}>
                        <FiEdit2 size={16} style={{ marginRight: 8 }} />
                        Edit
                    </MenuItem>

                    <MenuItem
                        onClick={() => handleSaveEditDelete('post', item._id, commentId)}
                        disabled={isSaving}
                    >
                        <div className="flex items-center">
                            {isSaving ? (
                                <>
                                    <CircularProgress size={16} style={{ marginRight: 8 }} />
                                    Deleting...
                                </>
                            ) : (
                                <>
                                    <FiTrash2 size={16} style={{ marginRight: 8 }} />
                                    Delete
                                </>
                            )}
                        </div>

                    </MenuItem>
                </Menu >
            </>
        );
    };

    const fetchPost = async () => {
        try {
            setLoading(true);
            const response = await communityService.getPostById(postId);
            const postData = response.data.data;

            setPost(postData);
            setReplies(postData.replies || []); // Top-level replies are set here
        } catch (error) {
            console.error('Error fetching post:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleLogin = () => {
        navigate('/login');
        setOpenAuthModal(false);
    };

    const handleProfessionalSignup = () => {
        navigate('/bridger-signup');
        setOpenAuthModal(false);
    };

    const handleFamilySignup = () => {
        navigate('/family-signup');
        setOpenAuthModal(false);
    };

    useEffect(() => {
        fetchPost();
    }, [postId, userId]);

    useEffect(() => {
        if (post && userId) {
            // Set initial post like state
            setIsPostLiked(post.likes?.some(like =>
                typeof like === 'string' ? like === userId : like._id === userId
            ));

            // Set initial comment likes state
            if (replies.length > 0) {
                const initialLikes = {};
                const initializeLikesRecursively = (replyList) => {
                    replyList.forEach(reply => {
                        const isLiked = reply.likes?.some(like =>
                            typeof like === 'string' ? like === userId : like._id === userId
                        );

                        initialLikes[reply._id] = {
                            likes: reply.likes || [],
                            isLiked: isLiked
                        };

                        if (reply.replies && reply.replies.length > 0) {
                            initializeLikesRecursively(reply.replies);
                        }
                    });
                };

                initializeLikesRecursively(replies);
                setCommentLikes(initialLikes);
            }
        }
    }, [post, replies, userId]);

    const handleCommentLike = async (commentId) => {
        if (!isAuthenticated) {
            setOpenAuthModal(true);
        } else {
            try {

                const response = await communityService.upvoteComment(postId, commentId);

                if (response.data.success) {

                    // Update the entire post data
                    setPost(response.data.data);
                    setReplies(response.data.data.replies || []);

                    // Find the updated reply in the response data
                    const findReply = (replies, targetId) => {
                        for (const reply of replies) {
                            if (reply._id === targetId) return reply;
                            if (reply.replies?.length) {
                                const found = findReply(reply.replies, targetId);
                                if (found) return found;
                            }
                        }
                        return null;
                    };

                    const updatedReply = findReply(response.data.data.replies, commentId);

                    // Update the specific comment's like state
                    setCommentLikes(prevLikes => {
                        const newState = {
                            ...prevLikes,
                            [commentId]: {
                                likes: updatedReply?.likes || [],
                                isLiked: !prevLikes[commentId]?.isLiked
                            }
                        };
                        return newState;
                    });
                }
            } catch (error) {
                console.error('Error liking comment:', error);
            }
        }
    };

    const handleReplySubmit = async (replyToId = null) => {
        if (!isAuthenticated) {
            setOpenAuthModal(true);
        } else {
            try {
                const content = replyToId ? replyContent : mainCommentContent;

                if (!content?.trim()) return;

                // Set loading state
                if (replyToId) {
                    setIsReplySubmitting(prev => ({ ...prev, [replyToId]: true }));
                } else {
                    setIsSubmitting(true);
                }

                const payload = {
                    content: content.trim(),
                    parentId: replyToId
                };

                const response = await communityService.addComment(postId, payload);

                if (response.data.success) {
                    const newComment = response.data.data;

                    if (!replyToId) {
                        // Adding a new top-level comment
                        setReplies(prevReplies => [newComment, ...prevReplies]);
                    } else {
                        // Adding a reply to an existing comment
                        setReplies(prevReplies => {
                            const updateRepliesRecursively = (replies) => {
                                return replies.map(reply => {
                                    if (reply._id === replyToId) {
                                        return {
                                            ...reply,
                                            replies: [...(reply.replies || []), newComment]
                                        };
                                    }
                                    if (reply.replies && reply.replies.length > 0) {
                                        return {
                                            ...reply,
                                            replies: updateRepliesRecursively(reply.replies)
                                        };
                                    }
                                    return reply;
                                });
                            };

                            const updatedReplies = updateRepliesRecursively(prevReplies);

                            return updatedReplies;
                        });
                    }

                    // Reset form states
                    if (replyToId) {
                        setReplyContent('');
                        setReplyingTo(null);
                    } else {
                        setMainCommentContent('');
                    }

                    // Initialize likes for the new comment
                    setCommentLikes(prevLikes => ({
                        ...prevLikes,
                        [newComment._id]: {
                            likes: [],
                            isLiked: false
                        }
                    }));
                }
            } catch (error) {
                console.error('Error in handleReplySubmit:', error);
            } finally {
                // Reset loading state
                if (replyToId) {
                    setIsReplySubmitting(prev => ({ ...prev, [replyToId]: false }));
                } else {
                    setIsSubmitting(false);
                }
            }
        }
    };



    const handlePostLike = async () => {
        if (!isAuthenticated) {
            setOpenAuthModal(true);
        } else {
            try {
                const response = await communityService.upvotePost(postId);
                if (response.data.success) {
                    setPost(prevPost => ({
                        ...prevPost,
                        likes: isPostLiked
                            ? prevPost.likes.filter(like => like._id !== userId)
                            : [...(prevPost.likes || []), { _id: userId }] // Add minimal user object
                    }));
                    setIsPostLiked(!isPostLiked);
                }
            } catch (error) {
                console.error('Error liking post:', error);
            }
        }
    };

    const handleReplyClick = (replyId) => {
        if (!isAuthenticated) {
            setOpenAuthModal(true);
        } else {
            setReplyingTo(prevId => prevId === replyId ? null : replyId); // Toggle reply box
        }
    };



    if (loading) {
        return (
            <Box
                sx={{
                    p: 3,
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '50vh'
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (!post) {
        return <Box sx={{ p: 3 }}>Post not found</Box>;
    }

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
            maxWidth: '1400px',
            margin: '0 auto',
            paddingBottom:'12',
            px: { xs: 1, md: 3 }
        }}>
            {/* Left sidebar ad */}
            {/* <Box sx={{
                display: { xs: 'none', lg: 'block' },
                width: '300px',
                minHeight: '600px',
                position: 'sticky',
                top: '100px'
            }}>
                <AdUnit slot={AD_SLOTS.LEFT_SIDEBAR} />
            </Box> */}

            {/* Main content */}
            <Box sx={{
                flex: 1,
                maxWidth: '800px',
                width: '100%',
                marginBottom:'16px'
            }}>
                {post && (
                    <SEO
                        title={post.title}
                        description={post.description?.substring(0, 155) || "Join the discussion in our birth professionals community."}
                        url={`/community/posts/${postId}`}
                        keywords={`birth discussion, ${post.category}, birth professionals, community forum, ${post.title.toLowerCase()}`}
                        canonical={`${window.location.origin}/community/posts/${postId}`}
                        author={post.author?.name}
                    />
                )}

                <Button
                    startIcon={<FiArrowLeft />}
                    onClick={() => navigate('/community')}
                    sx={{
                        mb: 3,
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                    }}
                >
                    Back to All Posts
                </Button>

                <StyledCard>
                    <Box sx={{ p: 4 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                            <Avatar
                                src={post.author?.profilePic?.url}
                                alt={post.author?.name}
                            />
                            <Box sx={{ flex: 1 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                        <Typography style={{ marginRight: '4px' }} variant="subtitle1" fontWeight="600">
                                            {post.author?.name}
                                        </Typography>
                                        <Typography variant="subtitle1" fontWeight="600">
                                            {post.author?.is_business_approved && (
                                                <>
                                                    <Tooltip title="Verified Business" arrow>
                                                        <Box component="span" sx={{}}>
                                                            <FaCheckCircle
                                                                size={16}
                                                                color="#1976d2"
                                                            />
                                                        </Box>
                                                    </Tooltip>
                                                    <Tooltip title={`View ${post.author?.name}'s Business Profile`} arrow>
                                                        <Box
                                                            component="span"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                window.open(`${window.location.origin}/${nameSlugify(post.author?.name)}/${post.author?._id}`, '_blank');
                                                            }}
                                                            sx={{
                                                                display: 'inline-flex',
                                                                alignItems: 'center',
                                                                ml: 1,
                                                                cursor: 'pointer',
                                                                '&:hover': {
                                                                    opacity: 0.8
                                                                }
                                                            }}
                                                        >
                                                            <FaExternalLinkAlt
                                                                size={12}
                                                                color="#1976d2"
                                                            />
                                                        </Box>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </Typography>
                                        <Chip
                                            label={post.category}
                                            size="small"
                                            sx={{
                                                ml: 2,
                                                height: 'auto !important',
                                                '& .MuiChip-label': {
                                                    whiteSpace: 'normal',
                                                    overflow: 'visible',
                                                    display: 'block',
                                                    padding: '4px 8px'
                                                },
                                                ...getCategoryStyles(post.category)
                                            }}
                                        />
                                    </Box>
                                    {/* {post.author?._id === userId && renderActionMenu('post', post)} */}
                                </Box>
                                <Typography variant="caption" color="text.secondary" display="block">
                                    {formatDate(post.createdAt)}
                                </Typography>
                            </Box>
                        </Box>
                        <Typography variant="h5" gutterBottom>
                            {post.title}
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                whiteSpace: 'pre-line',  // Changed from pre-wrap to pre-line
                                wordBreak: 'break-word',
                                '& br': {
                                    display: 'block',
                                    content: '""',
                                    marginTop: '1em'
                                }
                            }}
                        >
                            {post.description?.split('\n').map((line, i) => (
                                <React.Fragment key={i}>
                                    {line}
                                    {i < post.description.split('\n').length - 1 && <br />}
                                </React.Fragment>
                            ))}
                        </Typography>

                        {/* Post Stats and Actions */}
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 3,
                            mt: 2,
                            pb: 2,
                            borderBottom: 1,
                            borderColor: 'divider'
                        }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <IconButton onClick={handlePostLike} size="small">
                                        <FiHeart
                                            size={18}
                                            style={{
                                                fill: isPostLiked ? heartColor : 'none',
                                                color: isPostLiked ? heartColor : 'inherit',
                                                strokeWidth: 2
                                            }}
                                        />
                                    </IconButton>
                                    <Typography variant="body2" color="text.secondary">
                                        {post.likes?.length || 0}
                                    </Typography>
                                    {renderActionMenuPost('post', post)}
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {/* Comments Section */}
                    <Box sx={{ bgcolor: '#F8F9FA', p: 1.5 }}>
                        <Typography variant="h6" gutterBottom sx={{ mb: 3, fontWeight: 600 }}>
                            Comments ({replies?.length || 0})
                        </Typography>

                        {/* Main Comment Input for Post */}
                        <Box sx={{ mt: 3, mb: 4 }}>
                            <TextField
                                fullWidth
                                placeholder="Write a comment..."
                                value={mainCommentContent}
                                onChange={(e) => setMainCommentContent(e.target.value)}
                                multiline
                                rows={3}
                                disabled={isSubmitting}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                                <Button
                                    variant="contained"
                                    onClick={() => handleReplySubmit()}
                                    disabled={!mainCommentContent.trim() || isSubmitting}
                                    sx={{ textTransform: 'none' }}
                                >
                                    {isSubmitting ? <CircularProgress size={20} /> : 'Comment'}
                                </Button>
                            </Box>
                        </Box>

                        {/* Comments List (remains the same) */}
                        <Stack spacing={3}>
                            {replies?.map((reply) => (
                                <Paper
                                    key={reply._id}
                                    elevation={0}
                                    sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 2 }}
                                >
                                    <CommentReply
                                        reply={reply}
                                        onLike={handleCommentLike}
                                        onReply={handleReplyClick}
                                        commentLikes={commentLikes}
                                        user={user}
                                        replyingTo={replyingTo}
                                        replyContent={replyContent}
                                        setReplyContent={setReplyContent}
                                        onReplySubmit={handleReplySubmit}
                                        isReplySubmitting={isReplySubmitting}
                                        renderActionMenu={renderActionMenu}
                                        userId={userId}
                                        parentCommentId={reply._id}
                                    />
                                </Paper>
                            ))}
                        </Stack>
                    </Box>
                </StyledCard>
            </Box>

            {/* Right sidebar ad */}
            {/* <Box sx={{
                display: { xs: 'none', lg: 'block' },
                width: '300px',
                minHeight: '600px',
                position: 'sticky',
                top: '100px'
            }}>
                <AdUnit slot={AD_SLOTS.RIGHT_SIDEBAR} />
            </Box> */}

            <Dialog
                open={openAuthModal}
                onClose={() => setOpenAuthModal(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle sx={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: theme.palette.primary.main
                }}>
                    Join the Community
                </DialogTitle>
                <DialogContent>
                    <Typography align="center" sx={{ mb: 2 }}>
                        To create a post, you'll need to log in or create an account first.
                    </Typography>

                    {/* Login Button */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        mt: 2
                    }}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={handleLogin}
                            sx={{
                                backgroundColor: '#73a5b5',
                                '&:hover': {
                                    backgroundColor: '#5d8a99'
                                }
                            }}
                        >
                            Log In
                        </Button>

                        <Divider sx={{ my: 2 }}>
                            <Typography variant="body2" color="text.secondary">
                                Or Create Account As
                            </Typography>
                        </Divider>

                        {/* Professional Registration Button */}
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={handleProfessionalSignup}
                            sx={{
                                borderColor: '#73a5b5',
                                color: '#73a5b5',
                                '&:hover': {
                                    borderColor: '#5d8a99',
                                    backgroundColor: 'rgba(115, 165, 181, 0.04)'
                                }
                            }}
                        >
                            Birth Professional
                        </Button>

                        {/* Family Registration Button */}
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={handleFamilySignup}
                            sx={{
                                borderColor: '#73a5b5',
                                color: '#73a5b5',
                                '&:hover': {
                                    borderColor: '#5d8a99',
                                    backgroundColor: 'rgba(115, 165, 181, 0.04)'
                                }
                            }}
                        >
                            Family Member
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
                    <Button
                        onClick={() => setOpenAuthModal(false)}
                        sx={{
                            color: 'text.secondary',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            }
                        }}
                    >
                        Maybe Later
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={Boolean(editMode)}
                onClose={() => !isSaving && setEditMode(null)}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>
                    Edit {editMode?.type?.charAt(0).toUpperCase() + editMode?.type?.slice(1)}
                </DialogTitle>
                <DialogContent>
                    {editMode?.type === 'post' ? (
                        <Stack spacing={2} sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label="Title"
                                value={editTitle}
                                onChange={(e) => setEditTitle(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                label="Description"
                                value={editDescription}
                                onChange={(e) => setEditDescription(e.target.value)}
                            />
                            <FormControl fullWidth>
                                <InputLabel>Category</InputLabel>
                                <Select
                                    value={editCategory}
                                    onChange={(e) => setEditCategory(e.target.value)}
                                    label="Category"
                                >
                                    {CATEGORIES_FILTER.map((category) => (
                                        <MenuItem key={category.value} value={category.value}>
                                            {category.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    ) : (
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            value={editContent}
                            onChange={(e) => setEditContent(e.target.value)}
                            sx={{ mt: 2 }}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setEditMode(null)}
                        disabled={isSaving}
                        sx={{ color: 'text.secondary' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSaveEdit}
                        disabled={isSaving}
                        variant="contained"
                        sx={{
                            bgcolor: '#73a5b5',
                            '&:hover': { bgcolor: '#5d8a99' },
                            minWidth: 100,
                            position: 'relative'
                        }}
                    >
                        {isSaving ? (
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <CircularProgress size={20} />
                            </Box>
                        ) : (
                            'Save'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default SinglePost; 