import React, { useEffect, useState } from "react";
import { Container, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Swal from "sweetalert2";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isAuth } from "../../../helpers/auth";
import { getAllBBServices } from "../../../redux/actions/services.action";
// import { getPaymentMode, getStripeAccount } from '../../../redux/actions/stripe.action';
import { formatPrice, momentFormat, validateNumber } from "../../../utils/validations";
import SelectCategorySuggestions from "./SelectCategorySuggestions";
import { AiOutlinePlus } from "react-icons/ai";
import { uploadFilesToCloud } from "../../../redux/actions/cloudinary.action";
import { IoClose } from "react-icons/io5";
import FileViewModal from "../../../commonComponents/FileViewer";
import API from '../../../helpers/api';
import '../../../styles/editStyle.css';
import FormTitle from "../../SharedStyledComponents/FormTitle";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '../../../App.css';
import { FiHeart, FiMessageSquare, FiArrowLeft, FiEdit2, FiTrash2, FiMoreVertical } from 'react-icons/fi';
import CustomButton from "../../sharedComponents/Button";
import { Alert, Snackbar, Chip, Stack } from '@mui/material';

function AddUpdateService() {



    const loggedUser = isAuth();

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [isEdit, setIsEdit] = useState(false);
    const [bbServiceList, setBBService] = useState([]);
    // const [payment_mode, setPaymentMode] = useState(null);
    // const [isStripeConnected, setConnected] = useState(false);
    const [loading, setLoader] = useState({ submit: false, init: true });
    const [uploadProgress, setUploadProgress] = useState({ disclaimer_policy_document: false });
    const [fileLoading, setFileLoading] = useState({ disclaimer_policy_document: false });

    const [showFileViewer, setShowFileViewer] = useState(false);
    const [certificateToShow, setCertificateToShow] = useState(null);

    /** -------- Reducer State -------- */
    const bbServices = useSelector(state => state.services?.BBService)
    // const paymentMode = useSelector(state => state.paymentMode);
    // const stripe = useSelector(state => state.stripe);


    const [sevice_data, setsevice_data] = useState({
        amount: "",
        desc: "",
        max_capacity: 20,
        category: '',
        bbservice: "",
        service_type: '',
        is_virtual: false,
        disclaimer_policy: false,
        disclaimer_policy_document: ''
    })

    const [errors, setErrors] = useState({
        name: "",
        amount: "",
        bbservice: "",
        category: '',
    });

    useEffect(() => {
        dispatch(getAllBBServices({ userId: loggedUser.user }))
        // dispatch(getPaymentMode());
        // dispatch(getStripeAccount());
    }, [])

    useEffect(() => {
        if (bbServices) {
            setBBService(bbServices)
        }
    }, [bbServices])


    useEffect(() => {
        if (id) {
            setIsEdit(true)
            setLoader({ init: true })
            getService()
        } else {
            setIsEdit(false)
        }
    }, [id])


    // useEffect(() => {
    //     if (!paymentMode.error) {
    //         if (paymentMode.get && paymentMode.get.success) {
    //             if (paymentMode.get.paymentMode) {
    //                 setPaymentMode(paymentMode.get.paymentMode.type);
    //             }
    //         }
    //     }
    // }, [paymentMode])

    // useEffect(() => {
    //     if (!stripe.error) {
    //         if (stripe.account && stripe.account.success) {
    //             if (stripe.account.account) {
    //                 setConnected(stripe.account.account.status);
    //             }
    //         }
    //     }
    // }, [stripe])


    /** ----- Fetch Service to Update ---- */
    const getService = async () => {
        try {
            const response = await API.apiGet('services', `/${id}`);
            if (response.data && response.data.success) {
                let data = {
                    amount: response.data.service.amount,
                    desc: response.data.service.desc,
                    category: typeof response.data.service.category === 'object' ? response.data.service.category._id : response.data.service.category,
                    bbservice: typeof response.data.service.bbservice === 'object' ? response.data.service.bbservice._id : response.data.service.bbservice,
                    service_type: response.data.service.service_type,
                    is_virtual: response.data.service.is_virtual,
                    disclaimer_policy: response.data.service.disclaimer_policy,
                    amount_type: response.data.service.amount_type,
                    disclaimer_policy_document: response.data.service.disclaimer_policy_document
                }

                if (response.data.service.service_type === 'class') {
                    data.max_capacity = response.data.service.max_capacity
                }

                setsevice_data(data);
                setLoader({ init: false })
            } else {
                handleAlertMessage(response.data.message || 'Unable to fetch service record', 'error')
                setLoader({ init: false })
            }
        } catch (error) {
            handleAlertMessage(error.message || 'Unable to fetch service record', 'error')
            setLoader({ init: false })
        }
    }

    const handleChange = (e) => {
        let { name, value } = e.target;

        if ((name === 'amount') && value.trim() !== '') {
            value = validateNumber(value)
        }

        if (name === 'max_capacity' && value.trim() !== '') {
            value = validateNumber(value);
            // if( value > 20) return ;
        }

        setsevice_data({ ...sevice_data, [name]: value })
        setErrors({ ...errors, [name]: '' })
    }


    const handleFileChange = async (event, field) => {
        setErrors({ ...errors, [field]: '' });

        const files = event.target.files;
        const formData = new FormData();
        let hasError = false;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (field === 'files' && !['application/pdf'].includes(file.type)) {
                setErrors({ ...errors, [field]: file.name + ' file type is not supported' });
                hasError = true; // Set hasError to true if there's an error
                break; // Exit the loop early if there's an error
            }

            if (file.size > (1024 * 1024) * 10) {
                setErrors({ ...errors, [field]: file.name + ' size should be less than 10mb' });
                hasError = true; // Set hasError to true if there's an error
                break; // Exit the loop early if there's an error
            }

            formData.append('files', file);
        }

        if (!hasError && formData.has('files')) { // Proceed with upload only if there are no errors

            const response = await uploadFilesToCloud(formData, setUploadProgress, setFileLoading, field);
            //   console.log('Disclaimer Policy Response',  response )
            if (response && response.data && response.data.success) {
                setsevice_data((prevData) => ({
                    ...prevData,
                    [field]: { ...response.data.files[0], last_updated: moment().toDate() }
                }));
            }
        }
    };

    const handleAlertMessage = (message, variant) => {
        toast[variant](message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { max_capacity, ...rest } = sevice_data;
        let data = {}
        if (['class'].includes(sevice_data.service_type)) {
            data = sevice_data
        } else {
            data = rest
        }

        if (handleValidation()) {
            setLoader({ submit: true })
            try {
                var response;
                if (isEdit) {
                    response = await API.apiPostUrl('services', `/edit/${id}`, data);
                } else {
                    response = await API.apiPostUrl('services', `/add`, data);
                }
                if (response.data && response.data.success) {

                    setLoader({ submit: false });
                    if (response.data.showSetupSchedulePopup) {
                        Swal.fire({
                            title: 'Great Job! Your Services Are Set Up.',
                            text: `Congratulations on setting up your services for the first time! You're almost done! Next, please head over to your scheduler to complete your availability for the families! `,
                            icon: 'success',
                            confirmButtonText: 'Lets go!',
                            showCancelButton: true,
                            cancelButtonText: 'Add another specialty',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            preConfirm: () => {
                                return new Promise((resolve) => {
                                    navigate('/scheduler') // `Yes`Redirect to Schedules Page
                                    resolve();
                                });
                            }
                        })
                    } else {
                        navigate('/service');
                        handleAlertMessage(response.data.message, 'success');
                    }
                } else {
                    handleAlertMessage(response.data.message, 'error');
                    setLoader({ submit: false })
                }

            } catch (error) {
                if (error?.response?.data?.message) {
                    handleAlertMessage(error?.response?.data?.message, 'error');
                    setLoader({ submit: false });
                }
            }
        }
    };


    const handleChangeServiceType = (e, parameter) => {
        const value = e.target.value;

        const selectedOption = e.target.selectedOptions[0];
        const serviceType = selectedOption.getAttribute('data-service-type');

        setsevice_data({ ...sevice_data, [parameter]: value, ['service_type']: serviceType, amount_type: serviceType === 'service' ? 'fixed' : sevice_data.amount_type || 'fixed' });
        setErrors({ ...errors, bbservice: '' })
    }

    /** ---------- Validations ---------- */

    const handleValidation = () => {
        let fields = sevice_data;
        let formIsValid = true;

        if (!fields.amount) {
            formIsValid = false;
            errors.amount = "Please Enter an Amount";
        }
        if (!fields.bbservice) {
            formIsValid = false;
            errors.bbservice = "Please select a Specialty";
        }

        if (!fields.amount_type) {
            formIsValid = false;
            errors.amount_type = "Select Your Preferred Pricing Option";
        }
        if (!fields.category) {
            //** Error for category  */
            formIsValid = false;
            errors.category = "Please Select a Category";
        }

        if (['class'].includes(fields.service_type)) {
            if (!fields.max_capacity) {
                formIsValid = false;
                errors.max_capacity = 'Please enter max capacity';
            }

            if (fields.max_capacity && isNaN(fields.max_capacity)) {
                formIsValid = false
                errors.max_capacity = 'Please enter valid number'
            }
        }

        if (fields.disclaimer_policy) {
            if (!fields.disclaimer_policy_document) {
                formIsValid = false;
                errors.disclaimer_policy_document = 'Please upload a document'
            }
        }

        setErrors({ ...errors });
        return formIsValid;
    }


    const handleDisclaimer = (e) => {
        const { name, value } = e.target
        // console.log('disclaimer data', name, value);
        setsevice_data({ ...sevice_data, [name]: ['true', true].includes(value) ? true : false });
    }


    const handleRemoveFile = (field) => {
        setsevice_data({ ...sevice_data, [field]: null });
    };


    const handleShowFileView = (file) => {
        setCertificateToShow(showFileViewer ? null : file);
        setShowFileViewer(!showFileViewer);
    }



    const [activeStep, setActiveStep] = useState(1);


    const renderErrorSummary = () => {
        const errorMessages = [];

        if (errors.bbservice) errorMessages.push({ step: 1, message: errors.bbservice });
        if (errors.amount_type || errors.amount) errorMessages.push({ step: 2, message: errors.amount_type || errors.amount });
        if (errors.max_capacity && ['class'].includes(sevice_data.service_type)) errorMessages.push({ step: 3, message: errors.max_capacity });
        if (errors.desc) errorMessages.push({ step: 4, message: errors.desc });
        if (errors.disclaimer_policy_document) errorMessages.push({ step: 5, message: errors.disclaimer_policy_document });

        if (errorMessages.length === 0) return null; // Hide error summary when no errors

        return (
            <div style={{ marginBottom: 24 }} className="error-summary">
                <p style={{ color: 'red', marginBottom: 8 }}>Action Required: Please Review the Following Steps</p>

                {errorMessages.map((err, index) => (
                    <div key={index} className="error-summary-item">
                        <Button
                            variant="link"
                            onClick={() => setActiveStep(err.step)}
                            style={{ padding: 0, textDecoration: 'underline' }}
                        >
                            Step {err.step}: {err.message}
                        </Button>
                    </div>
                ))}
            </div>
        );
    };


    const handleNext = () => {
        let nextStep = activeStep + 1;

        // Skip steps based on condition
        while (
            nextStep <= 5 &&
            (nextStep === 3 && !['class'].includes(sevice_data.service_type))
        ) {
            nextStep++;
        }

        if (nextStep <= 5) setActiveStep(nextStep);
    };

    const handleBack = () => {
        let prevStep = activeStep - 1;

        // Skip steps based on condition
        while (
            prevStep >= 1 &&
            (prevStep === 3 && !['class'].includes(sevice_data.service_type))
        ) {
            prevStep--;
        }

        if (prevStep >= 1) setActiveStep(prevStep);
    };


    const renderStepContent = () => {
        switch (activeStep) {
            case 1:
                return (
                    <>
                        <Form.Group>
                            <FormTitle>Category</FormTitle>
                            <Form.Control
                                as="select"
                                value={sevice_data?.bbservice}
                                onChange={(e) => handleChangeServiceType(e, 'bbservice')}
                            >
                                <option>Select Category</option>
                                {bbServiceList?.length ? (
                                    bbServiceList.map((item, bbIndex) => (
                                        <option
                                            value={item._id}
                                            data-service-type={item.service_type}
                                            key={bbIndex}
                                        >
                                            {item.name}
                                        </option>
                                    ))
                                ) : (
                                    <option disabled>Category Not Found</option>
                                )}
                            </Form.Control>
                            <div className="error-container">
                                {errors.bbservice && <span className="error-msg">{errors.bbservice}</span>}
                            </div>
                        </Form.Group>

                        {sevice_data?.bbservice && (
                            <Form.Group className={`form-group col-sm-${['class'].includes(sevice_data.service_type) ? 6 : 12}`}>
                                <FormTitle>Specialty</FormTitle>
                                <SelectCategorySuggestions
                                    tags={sevice_data.category}
                                    setTags={(data) => setsevice_data({ ...sevice_data, category: data })}
                                    bbservice={sevice_data?.bbservice}
                                    tagsError={errors.category}
                                    setErrors={(v) => setErrors({ ...errors, category: v })}
                                    loggedUser={loggedUser}
                                />
                            </Form.Group>
                        )}
                    </>
                );
            case 2:
                return (
                    <div style={{ }}>
                        <div style={{ width: '300px', marginRight: 16 }}>
                            <Form.Group>
                                <FormTitle>Pricing</FormTitle>
                                <Stack direction="row" spacing={1} sx={{ marginTop: 1 }}>
                                    <Chip
                                        label="Fixed"
                                        onClick={() => sevice_data.service_type !== 'service' && handleChange({
                                            target: {
                                                name: 'amount_type',
                                                value: 'fixed'
                                            }
                                        })}
                                        color={sevice_data.amount_type === 'fixed' ? "primary" : "default"}
                                        variant={sevice_data.amount_type === 'fixed' ? "filled" : "outlined"}
                                        sx={{
                                            cursor: sevice_data.service_type === 'service' ? 'not-allowed' : 'pointer',
                                            opacity: sevice_data.service_type === 'service' ? 0.7 : 1
                                        }}
                                    />
                                    <Chip
                                        label="Hourly"
                                        onClick={() => sevice_data.service_type !== 'service' && handleChange({
                                            target: {
                                                name: 'amount_type',
                                                value: 'hour'
                                            }
                                        })}
                                        color={sevice_data.amount_type === 'hour' ? "primary" : "default"}
                                        variant={sevice_data.amount_type === 'hour' ? "filled" : "outlined"}
                                        sx={{
                                            cursor: sevice_data.service_type === 'service' ? 'not-allowed' : 'pointer',
                                            opacity: sevice_data.service_type === 'service' ? 0.7 : 1
                                        }}
                                    />
                                </Stack>
                                <div className="error-container">
                                    {errors.amount_type && <span className="error-msg">{errors.amount_type}</span>}
                                </div>
                            </Form.Group>
                        </div>

                        <div style={{ width: '300px' }}>
                            <Form.Group>
                                <FormTitle>
                                    {sevice_data.amount_type === 'fixed' ? 'Fixed' : 'Hourly'}
                                </FormTitle>
                                <InputGroup>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Control
                                        aria-label="Amount (to the nearest dollar)"
                                        type="text"
                                        name="amount"
                                        value={formatPrice(sevice_data.amount)}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </InputGroup>
                                <div className="error-container">
                                    {errors.amount && <span className="error-msg">{errors.amount}</span>}
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                );
            case 3:
                if (!['class'].includes(sevice_data.service_type)) return null;
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <div style={{ width: '300px', marginRight: 16 }}>
                            <Form.Group >
                                <FormTitle>Capacity</FormTitle>
                                <Form.Control
                                    type="text"
                                    name="max_capacity"
                                    value={sevice_data.max_capacity}
                                    onChange={(e) => handleChange(e)}
                                />
                                {errors?.max_capacity && <span className="error-msg">{errors?.max_capacity}</span>}
                            </Form.Group>
                        </div>
                        <div style={{ width: '300px' }}>
                            {/* <Form.Group >
                                <FormTitle>Will this be a virtual class?</FormTitle>
                                <Stack direction="row" spacing={1} sx={{ marginTop: 1 }}>
                                    <Chip
                                        label="Yes, it's virtual"
                                        onClick={() => handleChange({
                                            target: {
                                                name: 'is_virtual',
                                                value: true
                                            }
                                        })}
                                        color={sevice_data.is_virtual === true ? "primary" : "default"}
                                        variant={sevice_data.is_virtual === true ? "filled" : "outlined"}
                                        sx={{
                                            cursor: 'pointer'
                                        }}
                                    />
                                    <Chip
                                        label="No, it's in person"
                                        onClick={() => handleChange({
                                            target: {
                                                name: 'is_virtual',
                                                value: false
                                            }
                                        })}
                                        color={sevice_data.is_virtual === false ? "primary" : "default"}
                                        variant={sevice_data.is_virtual === false ? "filled" : "outlined"}
                                        sx={{
                                            cursor: 'pointer'
                                        }}
                                    />
                                </Stack>
                            </Form.Group> */}
                             {/* Hidden input for virtual status - always false */}
                             <input 
                                type="hidden" 
                                name="is_virtual" 
                                value={false} 
                                onChange={(e) => handleChange({
                                    target: {
                                        name: 'is_virtual',
                                        value: false
                                    }
                                })}
                            />
                        </div>
                    </div>
                );
            case 4:
                return (
                    <Row>
                        <Form.Group className="col-sm-12">
                            <FormTitle>Description</FormTitle>
                            <Form.Control
                                name="desc"
                                as="textarea"
                                rows={3}
                                placeholder="Specialty Description/Detail"
                                value={sevice_data.desc}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                );
            case 5:
                return (
                    <>
                        <Row>
                            <Form.Group className="col-sm-12">
                                <FormTitle>
                                    Would you like to include a waiver policy document for your customer to review and sign before finalizing the product?
                                </FormTitle>
                                <Form.Check
                                    type="radio"
                                    label="Yes"
                                    name="disclaimer_policy"
                                    value={true}
                                    id="yes_policy_document"
                                    onChange={(e) => handleDisclaimer(e)}
                                    checked={['true', true].includes(sevice_data.disclaimer_policy)}
                                />
                                <Form.Check
                                    type="radio"
                                    label="No"
                                    name="disclaimer_policy"
                                    value={false}
                                    id="no_policy_document"
                                    onChange={(e) => handleDisclaimer(e)}
                                    checked={['false', false].includes(sevice_data.disclaimer_policy)}
                                />
                            </Form.Group>
                        </Row>
                        {sevice_data.disclaimer_policy && (
                            <Row>
                                <Form.Group className="col-sm-12">
                                    <Form.Label>Waiver Policy Document</Form.Label>
                                    {sevice_data.disclaimer_policy_document ? (
                                        <p className="cert__title mb-3 d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
                                            <span className="mr-2">
                                                {sevice_data.disclaimer_policy_document.name}{' '}
                                                {sevice_data.disclaimer_policy_document.last_updated && (
                                                    <strong>
                                                        {`(Last Updated: ${momentFormat.dateTimeFormat(
                                                            sevice_data.disclaimer_policy_document.last_updated
                                                        )})`}
                                                    </strong>
                                                )}
                                            </span>

                                            <span
                                                className="ml-auto badge-success px-2"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleShowFileView(sevice_data.disclaimer_policy_document)}
                                            >
                                                View
                                            </span>
                                            <span
                                                className="cerificate--files--close badge-danger ml-1 px-1"
                                                onClick={() => handleRemoveFile('disclaimer_policy_document')}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <IoClose />
                                            </span>
                                        </p>
                                    ) : (
                                        <>
                                            <input
                                                type="file"
                                                id="upload--file"
                                                hidden
                                                onChange={(e) =>
                                                    fileLoading.disclaimer_policy_document
                                                        ? null
                                                        : handleFileChange(e, 'disclaimer_policy_document')
                                                }
                                                accept={['application/pdf']}
                                                disabled={fileLoading.disclaimer_policy_document}
                                            />
                                            <label htmlFor="upload--file">
                                                {fileLoading.disclaimer_policy_document ? (
                                                    <>
                                                        {' '}
                                                        {uploadProgress.disclaimer_policy_document > 0 &&
                                                            `Uploaded ${uploadProgress.disclaimer_policy_document}%`}{' '}
                                                        Please Wait...{' '}
                                                    </>
                                                ) : (
                                                    <>
                                                        {' '}
                                                        <AiOutlinePlus /> Upload Waiver Policy Document in ( pdf ),
                                                        must be less than 10mb in size
                                                    </>
                                                )}
                                            </label>
                                        </>
                                    )}
                                    {errors?.disclaimer_policy_document && (
                                        <span className="error-msg">{errors?.disclaimer_policy_document}</span>
                                    )}
                                </Form.Group>
                            </Row>
                        )}
                    </>
                );
            default:
                return <h1>Invalid Step</h1>;
        }
    };




    return (
        <>
            <Container fluid>

                <CustomButton
                    startIcon={<FiArrowLeft />}
                    variant="outline-secondary"
                    onClick={() => navigate('/service')}
                    style={{ marginBottom: '1rem' }}
                >
                    Back to your Specialty List
                </CustomButton>


                {/* <Button onClick={() => navigate('/service')}></Button> */}
                <Form className="p-4 bg-white mt-2">
                    <div>
                        <div style={{ textAlign: 'right' }}>
                            {renderErrorSummary()}
                        </div>
                        {renderStepContent()}

                        <div style={{marginTop:16, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <CustomButton
                                style={{ marginRight: 12 }}
                                onClick={handleBack}
                                disabled={activeStep === 1}
                                variant="outline-secondary"
                            >
                                Back
                            </CustomButton>

                            {activeStep < 5 && (
                                <CustomButton
                                    onClick={handleNext}
                                    disabled={activeStep === 5 || (activeStep === 3 && !['class'].includes(sevice_data.service_type))}
                                    variant="primary"
                                >
                                    Next
                                </CustomButton>
                            )}

                            {activeStep === 5 && (
                                <CustomButton
                                    variant="primary"
                                    type="submit"
                                    disabled={loading.submit || fileLoading.disclaimer_policy_document}
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    {loading.submit
                                        ? 'Please Wait...'
                                        : `${isEdit ? 'Update' : 'Add'} Specialty`
                                    }
                                </CustomButton>
                            )}
                        </div>
                    </div>
                </Form>
            </Container>
            {showFileViewer &&
                <FileViewModal
                    show={showFileViewer}
                    onHide={handleShowFileView}
                    type={certificateToShow.file_type}
                    url={certificateToShow.url}
                    title={certificateToShow.name}
                />
            }
        </>
    );
}

export default AddUpdateService;