import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store/';
import App from './App';
import Maintainance from './Maintainance';
import reportWebVitals from './reportWebVitals';
import { initGA } from './googleAnalyticWrapper/analytic';
import Hotjar from '@hotjar/browser';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './App.css'; // Import global custom CSS here
import { createTheme, ThemeProvider } from '@mui/material/styles';

const loadGoogleAnalytics = () => {
  const script1 = document.createElement('script');
  script1.async = true;
  script1.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8268178276012102';
  script1.crossOrigin = 'anonymous';
  document.head.appendChild(script1);

  const script2 = document.createElement('script');
  script2.async = true;
  script2.src = 'https://www.googletagmanager.com/gtag/js?id=G-E1190C55F9';
  document.head.appendChild(script2);

  const inlineScript = document.createElement('script');
  inlineScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-E1190C55F9');
  `;
  document.head.appendChild(inlineScript);
};

// IP addresses to exclude
const excludedIPs = ['223.233.77.196', '70.122.1.255', '173.63.68.41', '174.193.12.172'];

// Function to get user's IP
const getUserIP = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.warn('Could not fetch IP address:', error);
    return null;
  }
};

// Initialize analytics only if IP is not excluded
const initAnalytics = async () => {
  const userIP = await getUserIP();
  // console.log('userIP', userIP);
  if (userIP && !excludedIPs.includes(userIP)) {
    if (
      process.env.REACT_APP_MODE === 'production' &&
      window.location.hostname === "mybirthbridge.com"
    ) {

      console.log('LOADING SCRIPTS = PRODUCTION & MYBIRTHBRIDGE.COM');
      // Initialize Google Analytics
      initGA();
      loadGoogleAnalytics();

      // Initialize Hotjar
      Hotjar.init({
        hjid: 5283904,
        hjsv: 6
      });

    }
  } else {
    console.log('Analytics disabled for this IP address');
  }
};

// Call the initialization
if (window.location.hostname === "mybirthbridge.com") {
  initAnalytics();
}

const mode = 'live'
// const mode = 'maintainance'
const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
  palette: {
    // text: {
    //   primary: '#fff',
    //   secondary: '#73a5b5',
    // },
    primary: {
      main: '#5b6e74',
    },
    secondary: {
      main: '#73a5b5',
    },
    lightBlue: {
      main: '#d1eff9',
    },
    darkTxt: {
      main: '#d1eff9',
    },
    greyTxt: {
      main: '#747474',
    },
    lightTxt: {
      main: '#fff',
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'lightText' },
          style: {
            color: '#fff',
            backgroundColor: '#747474',
            '&:hover': {
              backgroundColor: '#606060',
            },
          },
        },
      ],
    },
  },
});


root.render(
  ['maintainance'].includes(mode)
    ? <Maintainance />
    : <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
