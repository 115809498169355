import React from 'react';
import { Button, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
// import Link from 'next/link';
import './header.css';





export default function Header({ title, subTitle, btnDetails = true }) {
  return (
    <section className="hero-section" aria-label="main banner">
      <div className="outsideContainerHeader">
        <div 
          className="insideContainerColumnHeader"
          style={{
            position: 'relative',
            minHeight: '600px', // Increased height for more content
            padding: '60px 20px'
          }}
        >
          {/* Dark overlay */}
          <div 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              borderRadius: '10px'
            }}
          />
          
          <div 
            style={{ 
              position: 'relative',
              zIndex: 1,
              maxWidth: '900px',
              margin: '0 auto'
            }}
          >
            {/* Trust Badge */}
            <div
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                color: '#2C3E50',
                padding: '8px 16px',
                borderRadius: '20px',
                display: 'inline-block',
                marginBottom: '1.5rem',
                fontSize: '0.9rem',
                fontWeight: '500'
              }}
            >
              A Home for Birth Professionals of Every Specialty and Story
            </div>

            <h1 
              className="hero-title"
              style={{
                fontSize: 'clamp(2.5rem, 5vw, 4rem)',
                lineHeight: 1.2,
                marginBottom: '1.5rem',
                fontWeight: '700',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)'
              }}
            >
              {title}
            </h1>

            <p 
              className="hero-subtitle"
              style={{
                fontSize: 'clamp(1.1rem, 2vw, 1.5rem)',
                lineHeight: 1.6,
                marginBottom: '1rem',
                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                maxWidth: '700px',
                margin: '0 auto 1rem'
              }}
            >
              {subTitle}
            </p>

            {/* Additional SEO-rich content */}
            <p
              style={{
                fontSize: '1.1rem',
                marginBottom: '2rem',
                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)'
              }}
            >
              Offer your care. Share your schedule. Get discovered by families who need you—while connecting with fellow providers who understand your journey.
            </p>

            {/* Multiple CTAs */}
            <Stack 
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ mb: 4 }}
            >
              {/* Primary CTA */}
              <Button
                href="/local-birth-professionals"
                startIcon={<SearchIcon />}
                style={{
                  backgroundColor: '#7FA1B3',
                  color: 'white',
                  padding: '16px 32px',
                  fontSize: '1.1rem',
                  borderRadius: '8px',
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
                  textTransform: 'none',
                  boxShadow: '0 4px 14px rgba(0, 0, 0, 0.2)'
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = '#6B8A9D';
                  e.currentTarget.style.transform = 'translateY(-2px)';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = '#7FA1B3';
                  e.currentTarget.style.transform = 'translateY(0)';
                }}
              >
                Find Birth Professionals Near Me
              </Button>

              {/* Secondary CTA */}
              <Button
                href="/bridger-signup"
                startIcon={<InfoIcon />}
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  color: '#2C3E50',
                  padding: '16px 32px',
                  fontSize: '1.1rem',
                  borderRadius: '8px',
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
                  textTransform: 'none',
                  boxShadow: '0 4px 14px rgba(0, 0, 0, 0.2)'
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 1)';
                  e.currentTarget.style.transform = 'translateY(-2px)';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.9)';
                  e.currentTarget.style.transform = 'translateY(0)';
                }}
              >
                Sign up for Free Today!
              </Button>
            </Stack>

            {/* Trust Indicators */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '2rem',
                color: 'white',
                fontSize: '0.9rem'
              }}
            >
              <span>✓ HIPAA Compliant</span>
              <span>✓ Verified Professionals</span>
              <span>✓ 100% Satisfaction Guaranteed</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}