import React, { useEffect, useState} from "react";
import { ListGroup, Form } from "react-bootstrap";
// import { displayOwner } from "../../../utils/nameHelper";
// import { toast } from "react-toastify";
import { createAndJoinRoom } from "../../../redux/actions/socket.action";
import API from '../../../helpers/api';
import { generateLetterSVG } from "../../../utils/validations";
import SEO from "../../../seo";
import { optimizedCloudinaryImage } from "../../../utils/fileCompression";
import LoadingView from "../../LoadingView";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import '../chatStyle.css';


const ChatList = ({ userMeta, openChatRoom, chatRooms = [], currentUser, chatId, getRooms, decryptMessage }) => {
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [searchLoader, setSearchLoader] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [connectLoader, setConnectLoader] = useState(false);

    const socketState = useSelector(state => state.socket);

    useEffect(() => {
        if (socketState) {
            const { roomJoined } = socketState
            if (roomJoined) {
                setConnectLoader(false);
                if(getRooms !== undefined){
                    getRooms()
                }
                navigate(`/chat/${roomJoined.roomId}`);
                handleResetSearch();
            }
        }
    }, [socketState.roomJoined])



    // const chatRoom = (_room, i) => {
    //     // console.log(_room );
    //     if(!_room) return ;
    //     let recipient = _room.users.find(u => u._id !== currentUser.aud);

    //     // console.log( 'recipient ----- ', _room.last_message )
    //     let showMessage = ''
    //     if (_room.last_message) {
    //         showMessage += _room.last_message.is_deleted
    //             ? 'deleted'
    //             : (_room.last_message.message)
    //                 ? decryptMessage(_room.last_message.message)
    //                 : (_room.last_message.file)
    //                     ? 'Attachment'
    //                     : ''
    //     }

    //     if (!recipient) {
    //         return null
    //     }

    //     const currentChatOpen = chatId && chatId.toString() === _room.roomId.toString()
        
    //     const sender = _room.last_message && typeof _room.last_message.sender === 'object' 
    //     ? _room.last_message.sender._id 
    //     : _room?.last_message?.sender || null; // Use null if last_message is not available
    
    //     const isCurrentSender = sender === currentUser.aud;
        
    //     return (
    //         <ListGroup.Item 
    //             className={currentChatOpen ? 'user__active' : ''}
    //             onClick={() => openChatRoom(true, _room)} 
    //             key={i}
    //         >
    //             <div 
    //                 className="chat__list__item"
    //                 style={{ 
    //                     display: 'flex', 
    //                     alignItems: 'center', 
    //                     flexDirection: 'row', 
    //                     width:'100%' 
    //                 }}
    //             >
    //                 <span className="chat__uicon">
    //                     {(recipient && recipient.profilePic && recipient.profilePic.url) ?
    //                         <img
    //                             loading='lazy'
    //                             src={optimizedCloudinaryImage(recipient.profilePic.url)}
    //                             alt="Profile"
    //                         />
    //                         : generateLetterSVG(recipient.name, 28)
    //                     }
    //                 </span>
    //                 <div style={{ width:'100%'}}>
    //                     <p style={{ marginLeft: 8, lineBreak: 'anywhere' }}>
    //                         {recipient.name.length > 25 ? `${recipient.name.substring(0, 15)}...` : recipient.name}
    //                     </p>
    //                     <p 
    //                         style={{ 
    //                             // width:'100%',
    //                             marginLeft: 8, 
    //                             lineBreak: 'anywhere',
    //                             display: 'flex',
    //                             alignItems: 'center',
    //                             justifyContent: 'space-between',
    //                             fontWeight:(!_room.last_message?.is_read && !currentChatOpen && !isCurrentSender)? 'bolder': 'inherit',
    //                             color:(!_room.last_message?.is_read && !currentChatOpen && !isCurrentSender) ? 'black': 'grey'
    //                         }}
    //                     >   
    //                         <span>
    //                             <>
    //                                 {isCurrentSender ? 'You: ' : null}
    //                             </>   
    //                                 {showMessage?.length > 15
    //                                     ? `${showMessage.substring(0, 15)}...`
    //                                     : showMessage 
    //                                         ? showMessage 
    //                                         : 'Start a conversation'
    //                                 }
    //                         </span>
    //                         {(_room.unreadMessageCount > 0 && !currentChatOpen && !isCurrentSender) && 
    //                         <span className="nav--count--badge">
    //                             {_room.unreadMessageCount}
    //                         </span>}
    //                     </p>
    //                 </div>
    //             </div>
    //         </ListGroup.Item>
    //     )
    // }

    const chatRoom = (_room, i) => {
        if (!_room) return;
        let recipient = _room.users.find(u => u._id !== currentUser.aud);
        if (!recipient) return null;
    
        const currentChatOpen = chatId && chatId.toString() === _room.roomId.toString();
    
        const lastMessage = _room.last_message || null;
        const senderId = typeof lastMessage?.sender === 'object' ? lastMessage.sender._id : lastMessage?.sender || null;
        const isCurrentSender = senderId === currentUser.aud;
    
        // Determine what to show as the message preview

        // console.log( lastMessage )
        let showMessage = '';
        if (lastMessage) {
            if (lastMessage.is_deleted) {
                showMessage = 'Deleted';
            } else if (lastMessage.file) {
                showMessage = 'Attachment';
            } else if (lastMessage.message) {
                const encryptedMessage = isCurrentSender ? lastMessage.message.sender : lastMessage.message.receiver;
                showMessage = encryptedMessage ? decryptMessage(encryptedMessage) : '';
            }
        } else {
            showMessage = 'Start a conversation';
        }
    
        return (
            <ListGroup.Item
                className={currentChatOpen ? 'user__active' : ''}
                onClick={() => openChatRoom(true, _room)}
                key={i}
            >
                <div
                    className="chat__list__item"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        width: '100%'
                    }}
                >
                    <span className="chat__uicon">
                        {recipient?.profilePic?.url ? (
                            <img
                                loading="lazy"
                                src={optimizedCloudinaryImage(recipient.profilePic.url)}
                                alt="Profile"
                            />
                        ) : (
                            generateLetterSVG(recipient.name, 28)
                        )}
                    </span>
                    <div style={{ width: '100%' }}>
                        <p style={{ marginLeft: 8, lineBreak: 'anywhere' }}>
                            {recipient.name.length > 25 ? `${recipient.name.substring(0, 15)}...` : recipient.name}
                        </p>
                        <p
                            style={{
                                marginLeft: 8,
                                lineBreak: 'anywhere',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                fontWeight:
                                    !lastMessage?.is_read && !currentChatOpen && !isCurrentSender
                                        ? 'bolder'
                                        : 'inherit',
                                color:
                                    !lastMessage?.is_read && !currentChatOpen && !isCurrentSender
                                        ? 'black'
                                        : 'grey'
                            }}
                        >
                            <span>
                                {isCurrentSender ? 'You: ' : ''}
                                {showMessage?.length > 15 ? `${showMessage.substring(0, 15)}...` : showMessage}
                            </span>
                            {_room.unreadMessageCount > 0 && !currentChatOpen && !isCurrentSender && (
                                <span className="nav--count--badge">{_room.unreadMessageCount}</span>
                            )}
                        </p>
                    </div>
                </div>
            </ListGroup.Item>
        );
    };

    const getBirthProfessionals = async (searchText) => {
        try {
            setSearchLoader(true);
            const response = await API.apiGetByKey('buisnesses', `?professionals=true&suggestionSearch=${searchText}`);
            if(response.data && response.data.success){
                setSearchResult(response.data.buisnesses);    
            }
        } catch (error) {
            console.error('Error:getBirthProfessionals', error?.response?.data?.message || error?.message || 'Unknown Error');
        } finally {
            setSearchLoader(false);
        }
    }

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearch(value);

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        const timeout = setTimeout(() => {
            if (value.trim()) {
                getBirthProfessionals(value);
            } else {
                setSearchResult([]);
            }
        }, 500); // Adjust the delay (in milliseconds) as needed
        setDebounceTimeout(timeout);
    };

    const handleResetSearch = () => {
        setSearch('');
        setSearchResult([]);
        setConnectLoader(false);
        setSearchLoader(false);
    }

    const handleMessage = (professional) => {
        const receiverId = professional._id;
        let users = [receiverId, currentUser.aud]
        setConnectLoader(professional._id);
        createAndJoinRoom({ users, sender: currentUser.aud });
        
        // // Prevent Timeout if switching app view 
        // if (!['seller', 'staff'].includes(currentUser.role)) {
        //     handleTimeOutMessageLoading()
        // }
    }

    // const handleTimeOutMessageLoading = () => {
    //     setTimeout(() => {
    //         if (!window.location.pathname.includes('chat')) {
    //             setConnectLoader(false);
    //             toast.error('Could not complete your request')
    //         }
    //     }, 10000)
    // }

    // console.log('chat rooms', chatRooms);

    return (
        <>
            <SEO
                title="Messaging"
                description="Communicate securely with your birth professional on BirthBridge. Stay connected and get the support you need for your birth journey."
                url="/chat"
                keywords="messaging, BirthBridge, communicate with birth professional, secure messaging, birth support, prenatal care, postpartum care, family support"
            />
            <div className="chat__list">
                <Form.Group>
                    <Form.Control
                        type="text"
                        placeholder="Search Birth Professionals..."
                        value={search}
                        onChange={handleSearchChange}
                    />
                    {searchLoader 
                    ? (
                        <div className="search-loader">
                            <LoadingView size={16}/>
                        </div>
                    ) : (
                        searchResult.length > 0 && (
                            <div className="chat__search__list__wrapper">
                                {searchResult.map((professional, index) => (
                                    <div 
                                        style={{ cursor: (professional.isOutOfCountry) ? 'not-allowed':'default' }}
                                        className="chat__search__list__item"
                                        key={index}
                                        onClick={() => 
                                            ((connectLoader === professional._id) || professional.isOutOfCountry)
                                            ? null 
                                            : handleMessage(professional)}
                                        disabled={((connectLoader === professional._Id) || professional.isOutOfCountry)}
                                    >
                                        <div className="chat__search__list__item__left">
                                            <span className="chat__search__uicon">
                                                {(professional && professional.profilePic && professional.profilePic.url) ?
                                                    <img
                                                        loading='lazy'
                                                        src={optimizedCloudinaryImage(professional.profilePic.url)}
                                                        alt="Profile"
                                                        
                                                    />
                                                    : generateLetterSVG(professional.name, 28)
                                                }
                                            </span>
                                            <span 
                                                className="chat__search__uname"
                                            >
                                                {professional.name}
                                            </span>
                                        </div>
                                        {(connectLoader === professional._id) && 
                                        <div className="chat__search__list__item__right">
                                            <LoadingView size={14}/>
                                        </div>}
                                    </div> 
                                ))}
                            </div>
                        )
                    )}
                </Form.Group>
                {(chatRooms && chatRooms.length > 0) && <h6 className="chat__list__heading">Conversations</h6>}
                <ListGroup>
                    {(chatRooms && chatRooms.length > 0)
                        ?   chatRooms.map((_room, i) => (
                                chatRoom(_room, i)
                            ))
                        : <h6 className="no_result_h6">No Conversations Found</h6>
                    }
                </ListGroup>
            </div>
        </>
    )
}

export default ChatList;