import React, { useEffect, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import { BiSearchAlt2 } from 'react-icons/bi';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getListToSearch } from "../../redux/actions/search.action";
import { useState } from "react";
import { generateLetterSVG, objectToSearchParams } from "../../utils/validations";
import { getPlaces } from "../../redux/actions/places.action";
import LoadingView from "../LoadingView";
import { optimizedCloudinaryImage } from "../../utils/fileCompression";

// import 'bootstrap/dist/css/bootstrap.min.css';
import './searchBar.css';
import { nameSlugify } from "../../utils/nameHelper";
// import '../../App.css';

function SearchBar(props) {
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();


    /** ---- Get URL Search Query Values ---- */
    const searchParams = new URLSearchParams(location.search); // Extract filter values from the URL query parameters
    const _search = searchParams.get('search');
    const searchLocation = searchParams.get('location');

    const [isInputChange, setInputChange] = useState(false);
    const [list, setList] = useState(null)

    const [showList, setShowList] = useState(false);
    const [onSelect, setSelect] = useState(false);

    const [showPlaces, setShowPlaces] = useState(false);
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState({ search: false, location: false });

    const [search, setSearch] = useState({ search: '', location: '' });


    const searchState = useSelector(state => state.search);
    const places = useSelector(state => state.places);

    useEffect(() => {
        if (search.search && isInputChange) {
            // inputRef.current.focus();

            // Declare a variable to store the timeout ID
            let timeoutId;

            // Set the timeout and store its ID
            timeoutId = setTimeout(() => {
                //  console.log('calling....');
                setShowList(true)
                setLoading({ ...loading, search: true })
                handleSearchRequest()
            }, 250);

            // Return a cleanup function to clear the timeout
            return () => {
                clearTimeout(timeoutId);
            };
        } else {
            setShowList(false)
        }
    }, [search.search]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (search.location && isInputChange) {
                setShowPlaces(true)
                setLoading({ ...loading, location: true })
                handleSearchRequest()
            } else {
                setShowPlaces(false)
            }
        }, 500);

        return () => clearTimeout(timer);

    }, [search.location]);


    useEffect(() => {
        if (searchState.error) {
            setLoading({ ...loading, search: false })
        }

        if (!searchState.error) {
            if (searchState.searchList && searchState.searchList.success) {
                setLoading({ ...loading, search: false })

                if (searchState.searchList.results.bbServices.length
                    || searchState.searchList.results.businesses.length
                    || searchState.searchList.results.categories.length
                ) {
                    setList(searchState.searchList.results)
                } 
                
                // else {
                //     setShowList(false)
                // }
            }
        }

    }, [searchState])

    useEffect(() => {
        if (places.error) {
            setLoading({ ...loading, location: false })
        }
        if (places.places && places.places.success && !places.error) {
            setLocations(places.places.suggestions)
            setLoading({ ...loading, location: false })
        }
    }, [places])


    useEffect(() => {
        if (onSelect) {
            let url = `/search`
            let searchObj = {}

            if (props.filter) {
                searchObj = { ...props.filter, ...searchObj }
            }

            searchObj = { ...searchObj, ...search };

            // console.log( 'search params object ', searchObj);

            const searchParamsString = objectToSearchParams(searchObj);

            if(props && props.handleUpdateFilter){
                // props.handleClearIndividualFilter('nearBy');
                props.handleUpdateFilter(search)
            }else{
                // props.handleClearIndividualFilter('nearBy');
                navigate(url.concat(`?${searchParamsString}`))
            }
        }

        return () => setSelect(false)
    }, [onSelect])


    useEffect(() => {
        setSearch((prevState) => {
            // Create a copy of the previous state to avoid mutation
            const newState = { ...prevState };
    
            // Update or remove fields based on conditions
            if (!_search && !searchLocation) {
                // If both `_search` and `searchLocation` are missing, clear both fields
                delete newState.search;
                delete newState.location;
            } else {
                if (_search) {
                    newState.search = _search; // Update `search` if `_search` exists
                } else {
                    delete newState.search; // Remove `search` if `_search` is missing
                }
    
                if (searchLocation) {
                    newState.location = searchLocation; // Update `location` if `searchLocation` exists
                } else {
                    delete newState.location; // Remove `location` if `searchLocation` is missing
                }
            }
    
            return newState;
        });
    }, [_search, searchLocation]);
    

    const handleSearchRequest = () => {
        if (search.search) {
            dispatch(getListToSearch({ search: search.search }));
        }

        if (search.location) {
            dispatch(getPlaces(search.location));
        }
    }

    const handleSearchInputChange = (value, label) => {
        setInputChange(true);
        setSearch({ ...search,[label]: value });
        if(value === ''){
            setSelect(true);
        }
    }

    const handleOnSelect = (value, label) => {
        setInputChange(false);
        setSearch({ ...search, search:'', [label]: value });
        if (label === 'location') {
            setShowPlaces(false)
        } else {
            setShowList(false)
        }
        setSelect(true);
    
    }

    const onHandleSearchClick = () => {
        // if(props && props.handleClearIndividualFilter){
        //     props.handleClearIndividualFilter('nearBy');
        // }
        setSelect(true)
        setShowList(false)
        setShowPlaces(false)
    }

    return (
        <>
            <Form className="search--bar--form">
                <Form.Group className="form-group">
                    <Form.Control
                        type="text"
                        placeholder="Specialty, Birth Workers..."
                        name="search"
                        value={search.search || ""}
                        onChange={(e) => handleSearchInputChange(e.target.value, 'search')}
                        ref={inputRef}
                    />
                    {
                        (showList)
                        &&
                        <>
                            <div className="search--bar--list">
                                {loading.search
                                    ? <LoadingView size={30} />
                                    : list
                                        ?
                                        <>
                                            {(list.categories.length > 0)
                                                &&
                                                <>
                                                    {/* These are categories in the database  */}
                                                    <h4>Specialties</h4>
                                                    <ul className="search--bar--list--categories">
                                                        {list.categories.map((cat, catIndex) => {
                                                            return (
                                                                <li key={catIndex} onClick={() => handleOnSelect(cat.name, 'category')}>
                                                                    {(cat.profile && cat.profile.url) 
                                                                    ?   <img 
                                                                            alt="profile image" 
                                                                            loading="lazy" 
                                                                            src={optimizedCloudinaryImage(cat.profile.url)} 
                                                                        />
                                                                    :    generateLetterSVG(cat.name, 14)}
                                                                    <span>{cat.name}</span>
                                                                </li>
                                                            )
                                                        })
                                                        }
                                                    </ul>
                                                </>
                                            }
                                            {list.bbServices.length > 0
                                                &&
                                                <>
                                                    {/* These are services in the database  */}
                                                    <h4>Categories</h4>
                                                    <ul className="search--bar--list--bbs">
                                                        {list.bbServices.map((service, serviceIndex) => {
                                                            return (
                                                                <li key={serviceIndex} onClick={() => handleOnSelect(service.name, 'service')}>
                                                                    {(service.profile && service.profile.url)
                                                                    ?   <img 
                                                                            alt="categories profile image"
                                                                            loading="lazy" 
                                                                            src={optimizedCloudinaryImage(service.profile.url)} 

                                                                        />
                                                                    : generateLetterSVG(service.name, 14)}
                                                                    <span>{service.name}</span>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </>
                                            }

                                            {list.businesses.length > 0
                                                &&
                                                <>
                                                    <h4>Professionals</h4>
                                                    <ul className="search--bar--list--business">
                                                        {list.businesses.map((business, businessIndex) => {
                                                            return (
                                                                <li key={businessIndex} onClick={() => navigate(`/${nameSlugify(business.name)}/${business._id}`)}>
                                                                    {(business.profilePic && business.profilePic.url)
                                                                        ?    <img alt="business profile picture" loading="lazy" src={ optimizedCloudinaryImage(business.profilePic.url)} />
                                                                        :   generateLetterSVG(business.name, 14)
                                                                    }
                                                                   
                                                                    <span>{business.name}</span>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </>
                                            }
                                        </>
                                        : <p className="text-center mt-5">No Record Found</p>
                                }
                            </div>

                        </>
                    }
                </Form.Group>
                <Form.Group>
                    <Form.Control
                        type="text"
                        className="border-right-0"
                        placeholder="City/State, Zipcode..."
                        name="location"
                        value={search.location || ''}
                        onChange={(e) => handleSearchInputChange(e.target.value, 'location')}
                    />
                    {(showPlaces) &&
                        <>
                            <ul className="search--bar--location">
                                {loading.location
                                    ? <LoadingView size={20} />
                                    : locations.length
                                        ? locations.map((suggestion, pIdx) => (
                                            <li
                                                key={pIdx}
                                                onClick={() => handleOnSelect(suggestion.description, 'location')}
                                            >
                                                {suggestion.description}
                                            </li>
                                        ))
                                        : <p className="text-center mt-5">No Location Found</p>
                                }
                            </ul>
                        </>
                    }

                </Form.Group>
                <Button  
                    aria-label="Search" 
                    variant="primary" 
                    onClick={() => onHandleSearchClick()}>
                        <BiSearchAlt2 />
                </Button>
            </Form>
        </>
    );
}

export default SearchBar;