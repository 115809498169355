import React, { useState, useEffect, Suspense } from 'react';
import { Container, Typography, Box, Button, TextField, Autocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';

import SEO from '../../seo';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import '../../App.css';

import SearchBar from '../SearchBar';
import LoadingView from '../LoadingView';
import sectionDivider from './section_divider.avif';
const ListByService = React.lazy(() => import('../ListByService'));
const LandingPageAboutBirthBridge = React.lazy(() => import('../LandingPageAboutBirthBridge'));
const LandingPageSupportFamilies = React.lazy(() => import('../LandingPageSupportFamilies'));
const HowBirthBridgeWorks = React.lazy(() => import('../HowBirthBridgeWorks'));
const Testimonials = React.lazy(() => import('../Testimonials'));
const LandingPageBlogs = React.lazy(() => import('../LandingPageBlogs'));
const DiscoverBirthProfessionals = React.lazy(() => import('../DiscoverBirthProfessionals'));

const SectionDivider = ({ img }) => {
    return <section className="custom-section">
        <div className="image-container">
            <img src={img} loading="lazy" alt="Section Divider" />
        </div>
    </section>
}
const SectionDivider2 = ({ img }) => {
    return <section className="custom-section2">
        <div className="image-container2">
            <img src={img} loading="lazy" alt="Section Divider" />
        </div>
    </section>
}

const HeroSection = styled(Box)(({ theme }) => ({
    background: 'linear-gradient(to right, #E6F3FF, #FFFFFF)',  // Light sky blue to white
    padding: theme.spacing(12, 0),
    position: 'relative',
    overflow: 'hidden',
}));

const HeroContent = styled(Box)(({ theme }) => ({
    position: 'relative',
    zIndex: 2,
    textAlign: 'center',
    maxWidth: '800px',
    margin: '0 auto',
}));

const SearchContainer = styled(Box)(({ theme }) => ({
    background: '#FFFFFF',
    borderRadius: '12px',
    padding: theme.spacing(3),
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
    marginTop: theme.spacing(4),
}));

const Hero = () => {
    return (
        <HeroSection>
            <Container>
                <HeroContent>
                    <Typography
                        variant="h1"
                        sx={{
                            fontSize: { xs: '2.5rem', md: '3.5rem' },
                            fontWeight: 700,
                            color: '#2A2A2A',
                            marginBottom: 2,
                            lineHeight: 1.2
                        }}
                    >
                        Find Your Perfect Birth Team
                    </Typography>

                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: { xs: '1.25rem', md: '1.5rem' },
                            color: '#666',
                            marginBottom: 4,
                            fontWeight: 400
                        }}
                    >
                        Connect with trusted doulas, midwives, fitness instructors, and many more birth professionals who will support your unique journey
                    </Typography>

                    <SearchContainer>
                        <Container>
                            <section className="banner__search">
                                <div style={{ margin: '0 auto' }} className="Inner--Content">


                                    <SearchBar />
                                </div>
                            </section>
                        </Container>

                    </SearchContainer>

                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 3 }}>
                        <Typography
                            variant="body1"
                            sx={{
                                color: 'text.secondary',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1
                            }}
                        >
                            ⭐ Certified Birth Professionals
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: 'text.secondary',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1
                            }}
                        >
                            💝 Your Birth Journey, Supported
                        </Typography>
                    </Box>
                </HeroContent>

                {/* Decorative elements */}
                <Box
                    component="img"
                    src="placeholder-birth-professional.jpg" // Replace with actual image
                    sx={{
                        position: 'absolute',
                        right: -100,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        maxWidth: '600px',
                        display: { xs: 'none', lg: 'block' },
                        opacity: 0.9,
                        borderRadius: '20px'
                    }}
                />
            </Container>
        </HeroSection>
    );
};

const HomeComponent = () => {

    const [loadAdditionalComponents, setLoadAdditionalComponents] = useState(false);

    // User interaction detection
    useEffect(() => {
        const handleUserInteraction = () => {
            setLoadAdditionalComponents(true);
            window.removeEventListener('click', handleUserInteraction);
            window.removeEventListener('scroll', handleUserInteraction);
        };

        window.addEventListener('click', handleUserInteraction);
        window.addEventListener('scroll', handleUserInteraction);

        return () => {
            window.removeEventListener('click', handleUserInteraction);
            window.removeEventListener('scroll', handleUserInteraction);
        };
    }, []);



    return (
        <>
            <SEO
                title="Find Trusted Birth Professionals Near You | BirthBridge"
                description="Looking for pregnancy, birth, or postpartum support? Search trusted birth professionals near you—from doulas and midwives to newborn care specialists, mental health support, and more. Compare reviews, check availability, and book with confidence."
                keywords="birth professionals near me, pregnancy support, postpartum help, find a doula, midwife, lactation consultant, newborn care, birth support team, mental health for moms, pregnancy services, prenatal care, holistic birth care"
                url="/"
            />

            <Hero />

            {/* <Container>
                <section className="banner__search">
                    <div style={{ margin: '0 auto' }} className="Inner--Content">


                        <SearchBar />
                    </div>
                </section>
            </Container> */}



            <Suspense
                fallback={
                    <div style={{ height: '500px' }}>
                        <LoadingView />
                    </div>
                }
            >
                <DiscoverBirthProfessionals />
            </Suspense>


            <Suspense
                fallback={
                    <div style={{ height: '500px' }}>
                        <LoadingView />
                    </div>
                }
            >
                <HowBirthBridgeWorks />
            </Suspense>




            <Suspense
                fallback={
                    <div style={{ height: '500px' }}>
                        <LoadingView />
                    </div>
                }
            >
                <LandingPageSupportFamilies />
            </Suspense>





            {/* Conditionally load ListByService and ListByCategories based on user interaction */}
            {loadAdditionalComponents && (
                <>
                    <SectionDivider img={sectionDivider} />
                    <Suspense
                        fallback={
                            <div style={{ height: '400px' }}>
                                <LoadingView />
                            </div>
                        }
                    >
                        <Testimonials />
                    </Suspense>




                    {/* <Suspense 
                        fallback={
                            <div style={{ height: '400px' }}>
                            <LoadingView />
                            </div>
                            }
                            >
                            <ListByCategories />
                            </Suspense> */}
                    <SectionDivider2 img={'https://res.cloudinary.com/fureverhomed/image/upload/f_auto,f_avif/v1730090248/dopuoqfpmkwoa9ckom6v.jpg'} />

                    <Suspense
                        fallback={
                            <div style={{ height: '500px' }}>
                                <LoadingView />
                            </div>
                        }
                    >
                        <LandingPageAboutBirthBridge />
                    </Suspense>



                    <Suspense
                        fallback={
                            <div style={{ height: '500px' }}>
                                <LoadingView />
                            </div>
                        }
                    >
                        <ListByService />
                    </Suspense>



                    <Suspense
                        fallback={
                            <div style={{ height: '500px' }}>
                                <LoadingView />
                            </div>
                        }
                    >
                        <LandingPageBlogs />
                    </Suspense>
                </>
            )}
        </>
    );
};

export default HomeComponent;