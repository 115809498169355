import React, { useEffect, useState } from 'react';
import { Container, Table, Row, Button, Form, Modal } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
import { momentFormat, formatPrice, isAppointmentOverToCompleteOrder, displayAddress } from '../../../utils/validations';
import { approveOrder, cancelOrder, completeOrder } from '../../../redux/actions/orders.action';
import { useDispatch, useSelector } from 'react-redux';
import LoadingView from '../../LoadingView';
import { isAuth } from '../../../helpers/auth';
import { getSellerStaffOrders } from '../../../redux/actions/orders.action';
import { toast } from 'react-toastify';
// import moment from 'moment';
import CancelOrderReason from '../../CancelOrderReason/index';
import RatingReadOnly from '../../RatingReadOnly';
// import { checkScrolledToBottom } from '../../../utils/scroll';
import { getOrderStatus, orderStatus, transactionStatus } from '../../../utils/status';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "../../../App.css"


const limitPerPage = process.env.REACT_APP_LISTING_LIMIT;

const Orders = () => {
    const now = new Date();
    const dispatch = useDispatch();
    const [orders, setOrders] = useState([]);
    const loggedIn = isAuth();
    // const [showReviewModel, setshowReviewModel] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [order, setOrder] = useState(null);
    const [checkToApprove, setCheckToApprove] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [cancelReason, setCancelReason] = useState('');
    const [loading, setLoading] = useState({ orders: true, approve: false, cancel: false, complete: false, loadMore: false });
    const [filter, setFilter] = useState({ service_type: '', search: '', filterByDate: '', filterByStatus: '', filterByMode: '' });
    const [status, setStatus] = useState('');

    const [filterDate, setFilterDate] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const [totalPages, setTotalPages] = useState(0);
    let [page, setPage] = useState(0);

    const ordersState = useSelector(state => state.orders);

    const handleGetOrders = () => {
        let _filter = filter;
        if (_filter.filterByDate) {
            _filter.startDate = filterDate.startDate
            _filter.endDate = filterDate.endDate
        }

        if (_filter.filterByStatus) {
            _filter.status = status
        }
        dispatch(getSellerStaffOrders({ limit: limitPerPage, page, ..._filter }));
    }

    /** ---Add Event Listener --- */
    // useEffect(() => {
    //     window.addEventListener("scroll", handleScroll);
    //     return () => window.removeEventListener("scroll", handleScroll);
    // }, []);

    /** --- Get Business Orders --- */
    useEffect(() => {
        handleGetOrders()
    }, [])

    /** ----- Calls when Reducer State Updates ----- */
    useEffect(() => {
        // console.log( ordersState );
        if (ordersState.error) {
            handleCreateAlert('error', ordersState.error.message);
            setLoading({
                orders: false,
                approve: false,
                cancel: false,
                complete: false,
                loadMore: false
            });
        }

        if (!ordersState.error) {
            if (ordersState.sellerOrders && ordersState.sellerOrders.success) {
                setOrders(ordersState.sellerOrders.orders);
                // if((orders.length < ordersState.sellerOrders.totalCount)){
                //     setOrders([...orders, ...ordersState.sellerOrders.orders]);
                // }else{
                //     setOrders(ordersState.sellerOrders.orders);
                // }

                setTotalPages(Math.ceil(ordersState.sellerOrders.totalCount / limitPerPage));

                setLoading({ orders: false, loadMore: false });

                // if(page > 0 ){
                //     window.scrollTo(0, document.documentElement.offsetHeight - 20);
                // }

                if (order) {
                    let _order = ordersState.sellerOrders.orders.find(o => order._id.toString() === o._id.toString())
                    setOrder(_order);
                }
            }

            if (ordersState.cancelOrder && ordersState.cancelOrder.success) {
                handleCreateAlert('success', ordersState.cancelOrder.message || 'Request to cancel order has been sent');
                setLoading({ cancel: false })
                handleGetOrders()
                handleCloseAlert()
            }

            if (ordersState.approve && ordersState.approve.success) {
                handleCreateAlert('success', ordersState.approve.message || 'Request to approve order has been sent');
                setLoading({ approve: false })
                handleGetOrders()
                handleCloseAlert()
            }

            if (ordersState.complete && ordersState.complete.success) {
                handleCreateAlert('success', ordersState.complete.message || 'Request to complete order has been sent');
                setLoading({ complete: false })
                handleGetOrders();
                handleCloseAlert();
            }
        }
    }, [ordersState])

    const handleCloseAlert = () => {
        setTimeout(() => {
            // setAlert('')
            toast.dismiss()
        }, 5000)
    }

    const handleCreateAlert = (variant, message) => {
        toast[variant](message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    // useEffect(() => {
    //     if(page < totalPages){
    //         handleGetOrders()
    //     }else{
    //         setLoading({ ...loading, loadMore: false  });
    //     }
    // }, [page])

    /** ---- Get More Services ---- */
    // useEffect(() => {
    //     if(!loading.loadMore) return;

    //     if(page < totalPages){
    //         setTimeout(() => {
    //             setPage(page + 1)
    //         }, 1200);
    //     }else{
    //         setLoading({ ...loading, loadMore: false  });
    //     }   
    // }, [loading.loadMore]);

    useEffect(() => {
        setPage(0);
        setTotalPages(0)
        setOrders([])
        setLoading({ ...loading, orders: true })
        // Define a delay for debouncing (e.g., 500 milliseconds)
        const delay = 700;
        // Use a timer to delay the API call after typing stops
        const timerId = setTimeout(() => {
            handleGetOrders();
        }, delay);

        // Clear the timer on component unmount or when input changes
        return () => clearTimeout(timerId);
    }, [
        filter.search,
        filter.service_type,
        filter.filterByMode,
        filterDate,
        status
    ]);

    const handleCancel = async (id) => {
        setLoading({ cancel: id ? id : order._id })
        // console.log('Id', id);
        dispatch(cancelOrder({ order_id: id ? id : order._id, rejection_reason: cancelReason }))
        handleCloseCancelModal()
    }

    const handleApprove = (orderId) => {
        setLoading({ approve: orderId })
        dispatch(approveOrder(orderId))
    }

    const handleCompleteOrder = (orderId) => {
        setLoading({ complete: orderId });
        dispatch(completeOrder(orderId));
    }

    const handleShowModal = (order) => {
        setShowModal(true);
        setOrder(order);

        let _checkToApprove = ['cancelled', 'pending', 'completed'].includes(order.status)
        setCheckToApprove(_checkToApprove)
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setOrder(null);
    }

    const handleCloseCancelModal = () => {
        setShowCancelModal(false);
        setCancelReason('');
        // setOrder(null)
    }

    /** ---- Handle Scroll ---- */
    // function handleScroll() {
    //     /** --- Detect Bottom Window --- */
    //     const bottomOfWindow =  checkScrolledToBottom() ; 
    //     /** --- Detect Top Of Window */
    //     if (bottomOfWindow) {
    //         setLoading({...loading, orders:false, ['loadMore']: true});
    //     }
    // }
    const renderOrdersTable = () => {
        return (
            <>
                <Table striped responsive>
                    <thead>
                        <tr>
                            <th scope="col">Order Id</th>
                            <th scope="col">Order Date</th>
                            <th scope="col">Appointment Date</th>

                            <th scope="col">Customer</th>
                            {/* <th scope="col">Service Type</th> */}
                            <th scope='col'>Category</th>
                            <th scope="col">Specialty</th>
                            <th scope="col">Price</th>
                            {/* <th scope="col">BirthBridge Service Fees</th> */}
                            <th scope="col">Stripe Fees</th>
                            {loggedIn.role === 'staff' && <th scope="col">Owners Fees</th>}
                            {/* <th scope="col">Donation</th> */}
                            {/* <th scope="col">Total</th> */}
                            <th scope="col">Payout</th>

                            <th scope="col">Order Status</th>
                            <th scope="col">Payment Status</th>
                            <th scope='col'>Rating</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((_order, orderIndex) => {
                            const startTime = new Date(_order.timeSlot.start_time);
                            const endTime = new Date(_order.timeSlot.end_time);

                            const isUpcoming = now < startTime;
                            const isInProgress = now >= startTime && now <= endTime;
                            return (
                                <tr
                                    className={_order.status === 'initiated' ? 'awaiting-approval-row' : ''}
                                    key={orderIndex}
                                >
                                    <td aria-description='order_id'>{_order.order_id}</td>
                                    <td aria-description='order_date'>
                                        {momentFormat.dateTimeFormat(_order.createdAt)}
                                    </td>
                                    <td aria-description='Appointment Date'>
                                        {momentFormat.fullDate(_order.timeSlot.date)}
                                        {(_order.timeSlot.start_time || _order.timeSlot.end_time) &&
                                            <p>
                                                {`${momentFormat.timIn12Format(_order.timeSlot.start_time)} - ${momentFormat.timIn12Format(_order.timeSlot.end_time)}`}
                                            </p>}

                                        {(_order.timeSlot.start_time && _order.timeSlot.end_time && isInProgress) &&
                                            <p>
                                                <span className={`status-services ${isInProgress ? 'status--progress' : ''}`}> Class In-Progress </span>
                                            </p>
                                        }
                                    </td>
                                    <td aria-description='customer'>{_order?.associatedWithUser?.name || 'N/A'}</td>
                                    {/* <td aria-description='service_type'>{_order.associatedWithService.bbservice.service_type}</td> */}
                                    <td>{_order?.associatedWithService?.bbservice?.name}</td>
                                    <td>{typeof _order.associatedWithService.category === 'object' ? _order.associatedWithService.category.name : _order.associatedWithService.category}</td>

                                    <td aria-description="price">
                                        <strong>{`$${formatPrice(_order.price)}`}</strong>
                                    </td>
                                    {/* <td aria-description="bbfees">
                                        {_order.bbd_fees_amount 
                                        ? typeof _order.bbd_fees_amount === 'object'
                                            ?   `$${_order.bbd_fees_amount.buisness_fees}`
                                            
                                            :   `$${_order.bbd_fees_amount}`

                                        : 'N/A'
                                        }
                                    </td> */}

                                    <td aria-description="stripe-fees">
                                        {(_order.stripe_fees && ['online'].includes(_order.mode)) ? '$' + _order.stripe_fees.fees : 'N/A'}
                                    </td>

                                    {loggedIn.role === 'staff' &&
                                        <td aria-description='owner fees'>
                                            {`$${_order.payout.seller || 0}`}
                                        </td>
                                    }
                                    {/* <td aria-description="donation_amount">${formatPrice(_order.donation_amount)}</td> */}
                                    {/* <td aria-description="total">${formatPrice(_order.total)}</td> */}

                                    <td aria-description="payout">
                                        {
                                            _order.payout
                                                ? (loggedIn.role === 'staff')
                                                    ? `$${_order.payout.staff || 'N/A'}`
                                                    : `$${_order.payout?.seller || 'N/A'}`
                                                : 'N/A'
                                        }
                                    </td>

                                    <td style={{ width: '300px', padding: '8px' }}> <span className={`status-services status--${_order.status}`}>{getOrderStatus(_order.status)}</span></td>
                                    <td>
                                        {['offline'].includes(_order.mode)
                                            ? <span style={{ padding: 0 }} className='status-services'>Payment will be taken off platform</span>
                                            : <span className={`status-services status--${_order.tnxId?.status || 'failed'}`}>
                                                {_order.tnxId?.status === 'refund_initiated' ? 'Refund Initiated' : _order.tnxId?.status || 'failed'}
                                            </span>
                                        }
                                    </td>

                                    <td>{(_order.rating && _order.isFeedbackGiven) ? <RatingReadOnly value={_order.rating.rating} /> : 'N/A'}</td>

                                    <td style={{ display: 'flex', paddingRight: '20px' }}>
                                        <Button
                                            className='mr-1'
                                            onClick={() => handleShowModal(_order)}
                                        >
                                            View
                                        </Button>

                                        {renderActions(_order)}
                                    </td>
                                    {/* <td>
                                        <strong>5<small>(61 votes)</small></strong>
                                    </td> */}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {/* { loading.loadMore ? <h6> Loading...</h6> : null } */}
            </>
        )
    }


    const handleSelect = (ranges) => {
        setPage(0);
        setTotalPages(0);
        setOrders([]);
        setFilterDate(ranges.selection);
    }

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        let _filter = filter;
        if ((['filterByDate', 'filterByStatus'].includes(name) && value === '') || !['filterByDate', 'filterByStatus'].includes(name)) {
            setPage(0);
            setTotalPages(0);
            setOrders([]);
        }

        if (name === 'filterByMode' && value === 'offline') {
            _filter.filterByStatus = ''
            delete _filter.status
            setStatus('');
        }

        setFilter({ ..._filter, [name]: value });
    }

    const handleStatusChange = (e) => {
        const { name, value } = e.target;
        setStatus(value);
    }

    const renderFilter = () => {
        return (
            <div className='orders--filter'>
                <div className="filter--orders--service--type">
                    <Form.Label>Service Type</Form.Label>

                    <Form.Control
                        as={'select'}
                        aria-label="service type"
                        name="service_type"
                        className="filter--service--type"
                        onChange={(e) => handleFilterChange(e)}
                        value={filter.service_type}

                    >
                        <option value="">None</option>
                        <option value="service">Doula</option>
                        <option value="class">Class</option>
                    </Form.Control>
                </div>

                <div className='filter--order--by--mode'>
                    <Form.Label>Filter by Payment Mode</Form.Label>
                    <Form.Control
                        as={'select'}
                        aria-label="filter--by--mode"
                        name="filterByMode"
                        className="filter--by--mode"
                        onChange={(e) => handleFilterChange(e)}
                        value={filter.filterByMode}
                    >
                        <option value="">None</option>
                        <option value="online">Online</option>
                        <option value="offline">Offline</option>
                    </Form.Control>
                </div>

                <div className='filter--order--by--status'>
                    <Form.Label>Filter by Status</Form.Label>
                    <Form.Control
                        as={'select'}
                        aria-label="filter--by--status"
                        name="filterByStatus"
                        className="filter--by--status"
                        onChange={(e) => handleFilterChange(e)}
                        value={filter.filterByStatus}
                    >
                        <option value="">None</option>
                        {filter.filterByMode !== 'offline' && <option value="paymentStatus">Payment Status</option>}
                        <option value="orderStatus">Order Status</option>
                    </Form.Control>
                </div>

                {filter.filterByStatus
                    &&
                    <div className='filter--order--status'>
                        <Form.Control
                            as={'select'}
                            aria-label="filter--status"
                            name="status"
                            className="filter--status"
                            onChange={(e) => handleStatusChange(e)}
                            value={status}
                        >
                            <option value="">Choose</option>
                            {(filter.filterByStatus === 'paymentStatus' ? transactionStatus : orderStatus).map((_status, _statusIndex) => {
                                return (
                                    <option key={_statusIndex} value={_status.value}>{_status.label}</option>
                                )
                            })}
                        </Form.Control>
                    </div>
                }

                <div className="filter--order--search">
                    <Form.Label>Search</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Search"
                        name="search"
                        value={filter.search || ''}
                        onChange={(e) => handleFilterChange(e)}
                    />
                </div>
                <div className='filter--order--by--date'>
                    <Form.Label>Filter By Date</Form.Label>
                    <Form.Control
                        as={'select'}
                        aria-label="filter--by--date"
                        name="filterByDate"
                        className="filter--by--date"
                        onChange={(e) => handleFilterChange(e)}
                        value={filter.filterByDate}
                    >
                        <option value="">None</option>
                        <option value="appointmentDate">Appointment Date</option>
                        <option value="orderDate">Order Date</option>
                    </Form.Control>
                </div>
                {filter.filterByDate &&
                    <div className='calendar--filter'>
                        <DateRangePicker
                            ranges={[filterDate]}
                            onChange={handleSelect}
                        />
                    </div>}
            </div>
        )
    }

    const handleCancelModel = (order) => {
        setShowCancelModal(true)
        setOrder(order)
    }

    const renderActions = (_order) => {
        return (
            <>

                {_order.status === 'initiated' &&
                    <Button
                        // className='disablebutton'
                        className='mr-1'
                        onClick={() => (checkToApprove || (loading['approve'] === _order._id)) ? null : handleApprove(_order._id)}
                        disabled={checkToApprove || (loading['approve'] === _order._id) || isAppointmentOverToCompleteOrder(_order)}
                    >
                        {loading['approve'] === _order._id ? 'wait...' : 'Approve Order'}
                    </Button>
                }
                {_order.status === 'accepted' &&
                    <Button
                        // className='disablebutton'
                        className='mr-1'
                        onClick={() => (loading['complete'] === _order._id) ? null : handleCompleteOrder(_order._id)}
                        disabled={(loading['complete'] === _order._id) || !isAppointmentOverToCompleteOrder(_order)}
                    >
                        {loading['complete'] === _order._id ? 'Wait...' : 'Complete Order'}
                    </Button>
                }
                {!['pending', 'completed', 'cancelled'].includes(_order.status) &&
                    <Button
                    style={{backgroundColor:'red', color:'white'}}
                        // className='disablebutton'
                        className='mr-1'
                        onClick={() => (checkToApprove || (loading['approve'] === _order._id) || (loading['cancel'] === _order._id)) ? null : handleCancelModel(_order)}
                        disabled={checkToApprove || (loading['approve'] === _order._id) || (loading['cancel'] === _order._id) || isAppointmentOverToCompleteOrder(_order)}
                    >
                        {loading['cancel'] === _order._id ? 'wait...' : 'Cancel Order'}
                    </Button>
                }
            </>
        )
    }

    return (
        <Container fluid>
            <Row>
                <div className="services-main-wraper-order">
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                        <h3>Orders</h3>
                        {/* <Button onClick={() => setShowFilter(!showFilter)}>{showFilter ? 'Hide' : 'Show'} Filter</Button> */}
                    </div>
                    {/* {showFilter && renderFilter()} */}
                    <div className='table-wallet-v2'>
                        {(loading['orders'])
                            ? <LoadingView />
                            : (orders.length > 0)
                                ? renderOrdersTable()
                                : <h5>No orders have been placed yet</h5>
                        }
                    </div>
                </div>
                {showModal &&
                    <Modal
                        size="lg"
                        show={showModal}
                        onHide={() => handleCloseModal()}
                        backdrop="static"
                        keyboard={false}
                    >
                        {/* <Modal.Header closeButton closeLabel='Close' title={order._id}/> */}
                        <Modal.Header closeButton>
                            <Modal.Title className='order-id-popup-o'>Order #{order.order_id}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h6><strong> Order Details </strong></h6>
                            <div className='table-model-popup'>
                                <table className='striped table'>
                                    <tr>
                                        <td>Status</td>
                                        <td><span className={`status-services status--${order.status}`}>{getOrderStatus(order.status)} </span></td>
                                    </tr>
                                    <tr>
                                        <td>Specialty</td>
                                        <td>{typeof order.associatedWithService.category === 'object' ? order.associatedWithService.category.name : order.associatedWithService.category}</td>
                                    </tr>
                                    <tr>
                                        <td>Category</td>
                                        <td>{order.associatedWithService.bbservice.name}</td>
                                        {/* <td>{typeof order.associatedWithService.category === 'object' ? 
                                        order.associatedWithService.category.name :
                                         order.associatedWithService.category} / {order.associatedWithService.bbservice.name}</td> */}
                                    </tr>
                                    <tr>
                                        <td>Order Date/Time</td>
                                        <td>{momentFormat.dateTimeFormat(order.createdAt)}</td>
                                    </tr>
                                    <tr>
                                        <td>Appointment Date/Time</td>
                                        <td>
                                            <span className='mr-3'>
                                                {momentFormat.fullDate(order.timeSlot.date)}
                                            </span>
                                            {(order.timeSlot.start_time && order.timeSlot.end_time)
                                                && <span>
                                                    {momentFormat.timIn12Format(order.timeSlot.start_time)} - {momentFormat.timIn12Format(order.timeSlot.end_time)}
                                                </span>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Order Price</td>
                                        <td>${order.price}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Updated</td>
                                        <td>{momentFormat.dateTimeFormat(order.updatedAt)}</td>
                                    </tr>
                                </table>
                                <h6><strong>Customer details</strong></h6>
                                <table className='striped table'>
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td style={{ textTransform: 'capitalize' }}>{order.associatedWithUser.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>{order.associatedWithUser.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Contact Number</td>
                                            <td>{order.associatedWithUser.phoneNumber}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Address
                                            </td>
                                            <td>
                                                {Object.values(order.associatedWithUser.address).every(v => v === null || v === '')
                                                    ? 'N/A'
                                                    : displayAddress(order.associatedWithUser.address)
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                {(order.payout && Object.keys(order.payout).length > 0) &&
                                    <>
                                        <h6><strong>Payout Details</strong></h6>
                                        <table className='table striped'>
                                            <tbody>
                                                <tr>
                                                    <td>You will receive</td>

                                                    <td>
                                                        {(order.associatedWithService.associatedWithUser.role === 'staff')
                                                            ? `$${order.payout.staff || 'N/A'}`
                                                            : `$${order.payout.seller || 'N/A'}`
                                                        }

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </>
                                }

                                {(order.rating && order.isFeedbackGiven) &&
                                    <>
                                        <h6><strong>Customer Reviews</strong></h6>
                                        <table className='table striped'>
                                            <tbody>
                                                <tr>
                                                    <td>Rating</td>
                                                    <td>
                                                        <RatingReadOnly value={order.rating.rating} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Feedback</td>
                                                    <td>{order.rating.review || 'N/A'}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </>
                                }
                                {/* <h6><strong>Payment Details</strong></h6>
                                <table className='table striped'>
                                    {['offline'].includes(order.mode) 
                                    ?   <tbody>
                                            <tr>
                                               <td>Payment Mode</td>
                                               <td>Offline</td>
                                            </tr>
                                        </tbody>
                                    :   <tbody>
                                            <tr>
                                                <td>Transaction Id</td>
                                                <td>{order.tnxId.payment_tnx_id}</td>
                                            </tr>
                                            <tr>
                                                <td>Transaction Date</td>
                                                <td>{momentFormat.dateTimeFormat(order.tnxId.createdAt)}</td>
                                            </tr>
                                            <tr>
                                                <td>Amount</td>
                                                <td>${formatPrice(order.tnxId.total)}</td>
                                            </tr>
                                            <tr>
                                                <td>Status</td>
                                                <td>
                                                    <span className={`status-services status--${order.tnxId.status}`} >
                                                        {order.tnxId.status === 'refund_initiated' ? 'Refund Initiated' : order.tnxId.status}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Last Updated</td>
                                                <td>{momentFormat.dateTimeFormat(order.tnxId.updatedAt)}</td>
                                            </tr>
                                        </tbody>
                                    }
                                </table> */}
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='button-order-deatils-v'>
                            {renderActions(order)}
                        </Modal.Footer>
                    </Modal>
                }

                {showCancelModal &&
                    <CancelOrderReason
                        showCancelModal={showCancelModal}
                        handleCloseCancelModal={handleCloseCancelModal}
                        handleChangeReason={(e) => setCancelReason(e.target.value)}
                        cancelReason={cancelReason}
                        handleSubmitCancelRequest={handleCancel}
                        loading={loading['cancel']}
                        order={order}
                    />}


            </Row>
        </Container>
    )
}

export default Orders;