import { get } from 'lodash';
import API from '../../helpers/api';
import { 
    GET_BUSINESS_NAMELOCATION_SUCCESS, 
    GET_BUSINESS_NAMELOCATION_FAILED,
    GET_SEARCHED_BUSINESSES_SUCCESS,
    GET_SEARCHED_BUSINESSES_FAILED,
    GET_SEARCH_RESULTS_SUCCESS,
    GET_SEARCH_RESULTS_FAILED,
    GET_LIST_TO_SEARCH_SUCCESS,
    GET_LIST_TO_SEARCH_FAILED,
    LIST_DISCOVER_FAILED,
    LIST_DISCOVER_SUCCESS, 
} from './types';


function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    // console.log( 'data error', data)
    if (data.error) {
        dispatch({
            type: type,
            payload: { message: data.error.message },
        });
    } else {
        dispatch({
            type: type,
            payload: { message: data.message },
        });
    }
}

/** ------- Get Business Name & Location ------ */
// export const getBusinessLocation = (query) => {
//     return async (dispatch) => {
//         try {
//             const response = await API.apiGet('search', `/businesses-location?nameLocation=${query}`);
//             if (response.data && response.data.success) {
//                 await dispatch({ type: GET_BUSINESS_NAMELOCATION_SUCCESS, payload: response.data });
//             } else {
//                 await dispatch({ type: GET_BUSINESS_NAMELOCATION_FAILED, payload: response.data });
//             }
//         } catch (error) {
//             errorRequest(error, dispatch, GET_BUSINESS_NAMELOCATION_FAILED);
//         }
//     }
// }

// export const searchBusinesses = (query) => {
//     return async (dispatch) => {
//         try {
//             const response = await API.apiGet('search', `/businesses?${query}`);
//             if( response.data && response.data.success){
//                 await dispatch({ type: GET_SEARCHED_BUSINESSES_SUCCESS, payload: response.data });
//             }else{
//                 await dispatch({ type: GET_SEARCHED_BUSINESSES_FAILED, payload: response.data });
//             }
//         } catch (error) {
//             errorRequest(error, dispatch, GET_SEARCHED_BUSINESSES_FAILED);
//         }
//     }
// }

export const getSearchResults = (query) => {
    return async (dispatch) => {
        try {
            const response = await API.apiGetByKey('search', `/results`, query);
            if( response.data && response.data.success){
                await dispatch({ type: GET_SEARCH_RESULTS_SUCCESS, payload: response.data });
            }else{
                await dispatch({ type: GET_SEARCH_RESULTS_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_SEARCH_RESULTS_FAILED);
        }
    }
}

export const getDiscoveryResults = (query) => {
    return async (dispatch) => {
        try {
            const response = await API.apiGet('discovery', query);
            if( response.data && response.data.success){
                await dispatch({ type: LIST_DISCOVER_SUCCESS, payload: response.data });
            }else{
                await dispatch({ type: LIST_DISCOVER_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, LIST_DISCOVER_FAILED);
        }
    }
}

export const getListToSearch = (query) => {
    return async (dispatch) => {
        try {
            const response = await API.apiGet('search', query);
            if( response.data && response.data.success){
                await dispatch({ type: GET_LIST_TO_SEARCH_SUCCESS, payload: response.data });
            }else{
                await dispatch({ type: GET_LIST_TO_SEARCH_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_LIST_TO_SEARCH_FAILED);
        }
    }
}

export const debugSearch = (query) => {
    return async (dispatch) => {
        try {
            const response = await API.apiGetByKey('search', `/debug-results`, query);
            console.log('Debug Search Results:', response.data);
            return response.data; // Return directly for debugging
        } catch (error) {
            console.error('Debug Search Error:', error);
            return error;
        }
    }
}

export const getSearchResultsV2 = (query) => {
    return async (dispatch) => {
        try {
            const response = await API.apiGetByKey('search', `/v2/results`, query);
            if (response.data && response.data.success) {
                await dispatch({ type: GET_SEARCH_RESULTS_SUCCESS, payload: response.data });
                
            } else {
                await dispatch({ type: GET_SEARCH_RESULTS_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_SEARCH_RESULTS_FAILED);
        }
    }
}