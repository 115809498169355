import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Box, Typography, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Import images with meaningful names for SEO
import growWithUs from './growwithus.jpg';
import startBusiness from './start-a-business-with-no-money.jpg';
import whyLoveUs from './whyloveus.jpg';

const FeatureSection = ({ image, title, features, isReversed, ctaButton }) => (
  <Box
    component="section"
    sx={{
      py: { xs: 6, md: 10 },
      backgroundColor: isReversed ? 'rgba(115,165,181,0.05)' : 'white'
    }}
  >
    <Container>
      <Row className={`align-items-center ${isReversed ? 'flex-row-reverse' : ''}`}>
        <Col xs={12} md={6}>
          <motion.div
            initial={{ opacity: 0, x: isReversed ? 20 : -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <Box
              component="img"
              src={image}
              alt={title}
              sx={{
                width: '100%',
                borderRadius: '20px',
                boxShadow: '0 20px 40px rgba(115,165,181,0.15)',
                transform: 'scale(1)',
                transition: 'transform 0.6s ease',
                '&:hover': {
                  transform: 'scale(1.02)'
                }
              }}
            />
          </motion.div>
        </Col>

        <Col xs={12} md={6}>
          <Box sx={{ pl: { md: isReversed ? 0 : 5 }, pr: { md: isReversed ? 5 : 0 }, mt: { xs: 4, md: 0 } }}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: '2rem', md: '2.5rem' },
                  fontWeight: 700,
                  mb: 3,
                  color: '#2c3e50'
                }}
              >
                {title}
              </Typography>

              <Box sx={{ mb: 4 }}>
                {features.map((feature, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      mb: 2.5,
                      '&:hover': {
                        transform: 'translateX(8px)',
                        transition: 'transform 0.3s ease'
                      }
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '1.5rem',
                        mr: 2,
                        color: '#73a5b5'
                      }}
                    >
                      {feature.icon}
                    </Box>
                    <Typography
                      sx={{
                        fontSize: '1.1rem',
                        color: '#596776',
                        lineHeight: 1.5
                      }}
                    >
                      {feature.text}
                    </Typography>
                  </Box>
                ))}
              </Box>

              {ctaButton && (
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Button
                    variant="contained"
                    href={ctaButton.url}
                    endIcon={<ArrowForwardIcon />}
                    sx={{
                      backgroundColor: '#73a5b5',
                      px: 4,
                      py: 1.5,
                      borderRadius: 2,
                      '&:hover': {
                        backgroundColor: '#5a8a9a'
                      }
                    }}
                  >
                    {ctaButton.text}
                  </Button>
                </motion.div>
              )}
            </motion.div>
          </Box>
        </Col>
      </Row>
    </Container>
  </Box>
);

const LandingPageHowWeHelpYou = () => {
  const sections = [
    {
      image: growWithUs,
      title: "Simplify & Scale Your Practice",
      features: [
        { icon: '📅', text: "Smart scheduling that works around *your* life" },
        { icon: '💳', text: "Fast, secure payments—no awkward follow-ups" },
        { icon: '⭐', text: "Earn trust with verified client reviews that convert" },
        { icon: '🤝', text: "Get discovered by the right clients—on autopilot" }
      ]
    },
    {
      image: startBusiness,
      title: "Start Without the Stress",
      features: [
        { icon: '✨', text: "No subscription fees—only pay when you earn" },
        { icon: '🚀', text: "Set up your profile in minutes, not hours" }
      ],
      ctaButton: {
        text: "Join Free Today!",
        url: "/bridger-signup"
      },
      isReversed: true
    },
    {
      image: whyLoveUs,
      title: "Why Birth Pros Love BirthBridge",
      features: [
        { icon: '🎯', text: "Reach more families who are already looking for you" },
        { icon: '⚡', text: "All-in-one tools to run your practice with ease" },
        { icon: '💬', text: "Friendly, responsive support—real humans, not bots" }
      ],
      ctaButton: {
        text: "Learn More",
        url: "/innovative-birth-marketplace-what-sets-birthbridge-apart"
      }
    }
  ];


  return (
    <>
      {sections.map((section, index) => (
        <FeatureSection key={index} {...section} />
      ))}
    </>
  );
};

export default LandingPageHowWeHelpYou;