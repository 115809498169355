import React, { useState, useEffect } from 'react';
import { Form, FormControl, ListGroup, Image, Button } from 'react-bootstrap';
import API from '../../helpers/api';
import { generateLetterSVG } from '../../utils/validations';
import LoadingView from '../../components/LoadingView';
import { toast } from 'react-toastify';
import { FaRegTimesCircle } from "react-icons/fa";
import { FaRegCopy } from "react-icons/fa";
import './CustomSuggestedSearch.css';
import { Alert, Snackbar } from '@mui/material';
import { optimizedCloudinaryImage } from '../../utils/fileCompression';
import CustomButton from '../../components/sharedComponents/Button';
const CustomSuggestedSearch = ({ userMeta, suggestedList, getSavedSuggestedList }) => {
    let searchTimeout;
    const [searchTerm, setSearchTerm] = useState('');
    const [loader, setLoader] = useState(false);
    const [users, setUsers] = useState([]);
    const [collectedUserId, setCollectedUserId] = useState([]);
    const [showList, setShowList] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);

    const [referralCode, setReferralCode] = useState('');

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [copiedService, setCopiedService] = useState('');

    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

    // Debounce logic: Update debouncedSearchTerm after 500ms of inactivity
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 800); // wait for 500ms

        if (!searchTerm) {
            setLoader(false);
        }
        // Cleanup timer if the user keeps typing
        return () => clearTimeout(timer);
    }, [searchTerm]);

    // Effect to trigger API call when debouncedSearchTerm changes
    useEffect(() => {
        if (debouncedSearchTerm) {
            // Call the API here
            getBirthProfessionals(debouncedSearchTerm);
        }
    }, [debouncedSearchTerm]);


    const handleChange = (event) => {
        setLoader(true)
        setSearchTerm(event.target.value);
        // clearTimeout(searchTimeout);

        // searchTimeout = setTimeout(async () => {
        //     await getBirthProfessionals(event.target.value);
        // }, 800);
    };

    useEffect(() => {
        if (userMeta) {
            setReferralCode(userMeta.referralCode);
        }
    }, [userMeta]);

    useEffect(() => {
        (async () => {
            await getBirthProfessionals();
        })();
    }, [suggestedList]);

    const getBirthProfessionals = async (_searchTerm) => {
        try {
            // setLoader(true);
            let payload = {
                professionals: true,
                limit: 200,
            };
            if (_searchTerm) {
                payload.suggestionSearch = _searchTerm;
            }
            const response = await API.apiGetByKey('buisnesses', payload);
            if (response.data && response.data.success) {
                const filteredUsers = response.data.buisnesses.filter((user) => {
                    return suggestedList.every((filterItem) => (filterItem.suggestedUser._id !== user._id)) && (userMeta._id !== user._id) && (user.suggestMe)
                });
                setUsers(filteredUsers);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setLoader(false);
        }
    };

    const handleOnSuggestionSelect = (userId) => {
        setCollectedUserId((prevState) => {
            if (prevState.includes(userId)) {
                return prevState.filter((id) => id !== userId);
            } else {
                return [...prevState, userId];
            }
        });
    };

    const handleSave = async () => {
        try {
            setSaveLoader(true);
            const payload = { suggestedUserId: collectedUserId };
            const response = await API.apiPost('suggested', payload);
            if (response.data && response.data.success) {
                setCollectedUserId([]);
                toast.success(response.data.message);
                if (getSavedSuggestedList) {
                    getSavedSuggestedList()
                }
            }
        } catch (error) {
            toast.error(error.message || error.response.data.message);
        } finally {
            setSaveLoader(false);
            setShowList(false);
        }
    };

    const handleFocus = () => {
        setShowList(true);
    };

    // const handleBlur = (event) => {
    //     // if (searchTerm.trim() === '') {
    //     //     setShowList(false);
    //     // }

    //     if (!event.relatedTarget || !event.relatedTarget.closest('.custom--suggested--search')) {
    //         setShowList(false);
    //     }
    // };

    const handleCopyLink = (link, serviceName) => {
        navigator.clipboard.writeText(link);
        setCopiedService(serviceName);
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <>
            <div className="custom--suggested--search mt-4">
                <p style={{ maxWidth: '800px', width: '100%' }}>
                    Looking to build your birth team? Add trusted professionals you know and recommend to help families confidently connect with the support they need.
                    Can’t find someone? Invite them to join with your personal link—<a
                        onClick={() => handleCopyLink(`${process.env.REACT_APP_URL}/invite?mode=bridger signup&inviter=${referralCode}`)}
                        style={{ textDecoration: 'none', cursor: 'pointer' }}>
                        just click to copy and share! <FaRegCopy style={{ cursor: 'pointer' }} />
                    </a>
                </p>
                <Form inline>
                    <FormControl
                        type="text"
                        placeholder="Search Birth Professionals..."
                        value={searchTerm}
                        onChange={handleChange}
                        onFocus={handleFocus}
                    />
                    {showList &&
                        <span
                            className="cross-icon"
                            onClick={() => {
                                setShowList(false)
                                setSearchTerm('')
                            }}
                        >
                            <FaRegTimesCircle />
                        </span>
                    }
                </Form>
                <div className='text-center mt-3 mb-3'>
                    {/* <p>
                        Can't find someone? Invite a birth professional to join by sharing your invite link! <a onClick={() => handleCopyLink(`${process.env.REACT_APP_URL}/invite?mode=bridger signup&inviter=${referralCode}`)} style={{ textDecoration: 'none', cursor: 'pointer' }}> Click here </a> to copy.<ClipboardCopy textToCopy={`${process.env.REACT_APP_URL}/invite?mode=bridger signup&inviter=${referralCode}`} />
                    </p> */}
                </div>
                {showList && (
                    <>
                        <ListGroup className="mt-3 custom--suggested--list">
                            {loader
                                ? (
                                    <div className="text-center">
                                        <LoadingView size={22} />
                                    </div>
                                ) : (
                                    users.length > 0
                                        ? users.map((user) => {
                                            const filteredServices = user.services
                                                .filter(service =>
                                                    // service.service_type !== 'subscription' && 
                                                    service.category?.name !== 'Consultation'
                                                    // !service.is_deleted
                                                )
                                                .map(service => `${service.bbservice?.name} - ${service.category?.name}`);

                                            return <ListGroup.Item key={user._id}>
                                                <label htmlFor={user._id} className="d-flex align-items-center w-100 mb-0" style={{ cursor: "pointer" }}>
                                                    <Image
                                                        src={
                                                            user.profilePic
                                                                ? optimizedCloudinaryImage(user.profilePic.url)
                                                                : generateLetterSVG(user.name)
                                                        }
                                                        roundedCircle
                                                        className="suggestion--search--profile mr-3"
                                                    />
                                                    <div className="suggestion--search--content">
                                                        <div style={{display:'flex', alignItems:'center'}}>
                                                            <span style={{marginRight:'10px'}}className="suggestion--search--name d-flex align-items-center">
                                                                {user.name}

                                                            </span>
                                                            <Form.Check
                                                                type="checkbox"
                                                                checked={collectedUserId.includes(user._id)}
                                                                onChange={() => handleOnSuggestionSelect(user._id)}
                                                                id={user._id}
                                                            />
                                                        </div>
                                                        {filteredServices.length > 0 && (
                                                            <small className="text-muted d-block">
                                                                {filteredServices.slice(0, 2).join(', ')}
                                                                {/* {filteredServices.length > 2 ? '... more' : ''} */}
                                                            </small>
                                                        )}
                                                    </div>
                                                </label>
                                            </ListGroup.Item>
                                        })
                                        : searchTerm ? (
                                            <div className='text-center'>
                                                <p>
                                                    No birth Professional's Found
                                                </p>
                                            </div>
                                        ) : null
                                )}

                            {(!loader && users.length > 0) && (
                                <div className='custom--suggested--button'>
                                    <CustomButton
                                        onClick={handleSave}
                                        // onBlur={handleBlur}
                                        className="mt-3"
                                        disabled={saveLoader}
                                    >
                                        {saveLoader ? 'Saving...' : 'Save Recommendations'}
                                    </CustomButton>
                                </div>
                            )}
                        </ListGroup>
                    </>
                )}

            </div>
            {/* {(!(users.length > 0) && !loader && searchTerm) &&
            <div className='text-center mt-3 mb-3'>
                <p>
                    No birth Professional's Found
                </p>
            </div>
        } */}

            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '500px', maxWidth: '100%' }}>
                    {`Your invite link has been copied! Share it with your network, family, and friends, or add it to your website to spread the word.`}
                </Alert>
            </Snackbar>
        </>
    );
};

export default CustomSuggestedSearch;
