import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Modal, Form, Col, Row, Dropdown, InputGroup } from 'react-bootstrap';
import CustomButton from '../../sharedComponents/Button';
import { Calendar, Views, DateLocalizer, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdAdd } from "react-icons/md";
import BookedUsersModal from '../Schedules/bookedUsers';
import { createSchedule, deleteSchedule, getSchedules, updateSchedule } from '../../../redux/actions/schedule.action';
import DateCalender from 'react-calendar';
import { formatPrice, generateTimeOptions, isOverlapping, momentFormat, validateNumber } from '../../../utils/validations';
import { toast } from 'react-toastify';
import EventWrapper from './eventWrapper';
import { calculateClassPrice, calculateHourlyPrice } from '../../../utils/scheduleHelper';
import LoadingView from '../../LoadingView';
import { useSearchParams, useLocation } from "react-router-dom";
import 'react-calendar/dist/Calendar.css'; // DateCalender CSS 
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './style.css';
import Swal from 'sweetalert2';
import { Alert, Snackbar, Chip, Stack } from '@mui/material';
import './EventModal.css';
import API from '../../../helpers/api';
import { getMetaUser } from '../../../redux/actions/user.action';
import ReactDOM from 'react-dom/client';
import FormTitle from '../../SharedStyledComponents/FormTitle';


const localizer = momentLocalizer(moment) // or globalizeLocalizer 

const Scheduler = (props) => {
  const customOption = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  }
  const eventParams = {
    title: '',
    service: '',
    startDate: '',
    endDate: '',
    startTime: '',
    date: '',
    endTime: '',
    allDay: true,
    schedule: 'no_repeat',
    days: -1,
    scheduleId: '',
    description: ''
  }

  const scheduleOptions = [
    { value: 'no_repeat', label: 'Do Not Repeat' },
    { value: 'daily', label: 'Daily Repeat' },
    { value: 'custom', label: 'Custom' },
  ]

  /** ----- Start / JEnd Date Picker ----  */
  const endDatePickerRef = useRef(null);
  const startDatePickerRef = useRef(null);
  const minimumDate = new Date();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const searchQueryValue = searchParams.get("redirect");

  const location = useLocation();
  const { redirect } = location.state || {};

  const [loader, setLoader] = useState(true);
  const [paymentMode, setPaymentMode] = useState(null);
  const [isStripeConnected, setConnected] = useState(false);
  /** ----- Start Calendar ----- */
  const [showStartCalendar, setShowStartCalendar] = useState(false);
  /** ----- End Calendar ----- */
  const [showEndCalendar, setShowEndCalendar] = useState(false);

  /** ----- Past Schedules Not Editable ----- */
  const [isPastSchedule, setPastSchedule] = useState(false);

  const [classHourPrice, setClassHourPrice] = useState(0);
  const [serviceAmountType, setServiceAmountType] = useState('');

  /** ---- Show Event Modal ----- */
  const [showModal, setShowModal] = useState({ event: false, bookedUsers: false });
  /** ---- Show Custom Option Modal ---- */
  const [showCustomOptions, setShowCustomOptions] = useState(false);

  const [copiedService, setCopiedService] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  /** ----- All Events ----- */
  const [events, setEvents] = useState([]);

  /** ----- Single Event to Create ----- */
  const [event, setEvent] = useState({ ...eventParams });

  /** ---- Time Options ---- */
  const [showTime, setShowTime] = useState(false);

  /** ----- Array Of Times for Start----- */
  const [startTimeOptions, setStartTimeOptions] = useState([]);

  /** ----- Array of Time for End  */
  const [endTimeOptions, setEndTimeOptions] = useState([]);

  /** ----- Repeat Period ----- */
  const [repeatPeriod, setRepeatPeriod] = useState(null);

  /** ---- Is Used for Save Event Button ---- */
  const [isDisable, setIsDisable] = useState(false);

  /** ---- IS Editable ---- */
  const [isEdit, setIsEdit] = useState(false);

  /** ------------------------------[ Redux State ]------------------------------- */
  const scheduleState = useSelector(state => state.schedule);


  /** -------------------------------------------------------------------------- */

  useEffect(() => {
    if (props && props.paymentMode) {
      setPaymentMode(props.paymentMode)
    }

    if (props && props.isStripeConnected) {
      setConnected(props.isStripeConnected)
    }
  }, [
    props?.paymentMode,
    props?.isStripeConnected
  ])

  const handleGetSchedules = () => {
    dispatch(getSchedules());
  }

  const handleUpdateFirstVisit = async () => {
    try {
      const response = await API.apiPostUrl('seller', '/schedule-visit');
      if (response.data && response.data.success) {
        dispatch(getMetaUser())
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSelectSlot = useCallback(
    ({ start, end }) => {

      /** ----- Blocks to select past date from bookintg ---- */
      if (moment().isAfter(start, 'day')) {
        return;
      }

      if (moment(end).isSame(moment(start).add(1, 'days').startOf('day'), 'minute') || moment(end).isSame(moment(start).add(1, 'days').endOf('day'), 'minute')) {
        end = moment(end).subtract(1, 'days').endOf('day').toDate(); // Set end time to the last moment of the previous day
      } else {
        // Subtract one second to make sure it stays within the selected range
        end = moment(end).subtract(1, 'second').toDate();
      }

      let offlineMode = (paymentMode !== 'online' || !isStripeConnected);

      const data = {
        startDate: start,
        endDate: end,
        // endDate: momentFormat.correctDate(start, end),
        // days: length,
        schedule: 'no_repeat',
        allowed_payment: offlineMode ? 'offline' : 'online'
      };

      setEvent(data);
      openEventModal();
    },
    [event] // Dependency added to ensure the correct events array is used
  );

  useEffect(() => {
    handleGetSchedules();
  }, []);

  useEffect(() => {
    if (scheduleState.error) {
      toast.error(scheduleState.error.message);
      setLoader(false);
    }

    if (!scheduleState.error) {
      if (scheduleState.post && scheduleState.post.success) {
        if (scheduleState.post && scheduleState.post.showSetupServicePopup) {

          const firstSchedule = scheduleState.post.schedule || null

          Swal.fire({
            title: 'Success! Your Schedule is Ready.',
            text: `Your availability is now live! Share your link on your website or social media so families can easily connect with you.`,
            icon: 'success',
            confirmButtonText: 'Copy Link',
            showCancelButton: true,
            cancelButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            preConfirm: () => {
              return new Promise((resolve) => {
                if (firstSchedule) {
                  handleCopyLink(`${window.location.host}/book-appointments/${firstSchedule.service._id}`, firstSchedule.title);
                  handleOnSkipSchedule() // stay on same page and change the URL
                } else {
                  navigate('/invite')
                }
                resolve();
              });
            }
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.cancel) {
              handleOnSkipSchedule(); // stay on same page and change the URL
            }
          });
        }
        handleGetSchedules();
      }

      if (scheduleState.get && scheduleState.get.success) {
        setEvents([...scheduleState.get.schedules]);
        setTimeout(() => {
          setLoader(false);
        }, 1500)
      }

      if (scheduleState.delete && scheduleState.delete.success) {
        handleGetSchedules();
      }

      if (scheduleState.put && scheduleState.put.success) {
        toast.success(scheduleState.put.message)
        handleGetSchedules();
      }
    }
  }, [scheduleState]);

  /** -----------------------------------------------  Flow on Initial Signup Steps for Schedule Availability ------------------------ */

  useEffect(() => {


    if (searchQueryValue === 'profile' && redirect === 'profile') {
      handleUpdateFirstVisit();

      Swal.fire({
        title: 'Welcome to BirthBridge!',
        icon: 'success',
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: false,
        customClass: {
          closeButton: 'swal2-close-button',
          container: 'custom-swal-container',
          popup: 'custom-swal-popup',
          title: 'custom-swal-title',
          actions: 'custom-swal-actions'
        },
        html: `
          <div class="swal2-text">
          
          <p>You're all set! Now that your account is ready to go, you can schedule your availability, explore the platform, and connect with the BirthBridge community.</p>
          <p>Let’s get started! 🚀</p>


          </div>
          <div id="custom-buttons-container">
            <div id="custom-confirm-button"></div>
          </div>
        `,
        didRender: () => {
          const container = document.getElementById('custom-confirm-button');
          const root = ReactDOM.createRoot(container);
          root.render(
            <div style={{ marginTop: '16px' }}>
              <CustomButton
                variant="primary"
                onClick={() => {
                  handleOnClickSchedule();
                  Swal.close();
                }}
              >
                Schedule Your Specialty
              </CustomButton>
            </div>
          );
        }
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.close) {
          Swal.close();
        }
      });


    }

    if (searchQueryValue && !redirect) {
      navigate('/scheduler')
    }
  }, [searchQueryValue, redirect])

  const handleOnSkipSchedule = () => {
    navigate('/scheduler', { state: null });
  }



  const handleCopyLink = (link, serviceName) => {
    navigator.clipboard.writeText(link);
    setCopiedService(serviceName);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  /** ------ Used to get scheduled events details to update / view  ----- */
  const handleSelectEvent = useCallback(
    (e) => {
      if (moment().isAfter(moment(e.start), 'day')) {
        // Dates after maxDate are not selectable
        setPastSchedule(true);
        // return;
      }

      setIsEdit(true)
      let data = {}
      // data.date = moment(e.start).startOf('day').toDate()
      data.startDate = moment(e.start).startOf('day').toDate() //momentFormat.formatReactCalendarDate() 
      data.endDate = moment(e.end).endOf('day').toDate()//momentFormat.formatReactCalendarDate(e.end)
      data.schedule = e.schedule
      data.amount = e.amount
      data.service_type = e.service.service_type;

      setShowTime(e.service.service_type === 'class' ? true : false);

      if (e.service.service_type === 'class') {
        data.is_virtual = e.is_virtual

        if (e.is_virtual) {
          data.class_information = e.class_information
        }

        data.required_approval = e.required_approval
        data.startTime = momentFormat.formatReactCalendarTime(e.start)
        data.endTime = momentFormat.formatReactCalendarTime(e.end)
        let amt = calculateHourlyPrice(data.startDate, data.startTime, data.endDate, data.endTime, e.amount, e.service.amount_type)
        setClassHourPrice(amt);
        setServiceAmountType(e.service.amount_type);
      }

      setEvent({
        ...e,
        ...data
      });
      openEventModal();
    },
    []
  )


  /** ----- Update Disable ----- */
  useEffect(() => {
    const hasStartTime = showTime && !event.startTime;
    const hasEndTime = showTime && !event.endTime;
    const hasNoService = !event.service;
    const date1 = moment(event.startDate).isAfter(event.endDate)
    const date2 = moment(event.endDate).isBefore(event.startDate)
    setIsDisable(hasStartTime || hasEndTime || hasNoService || date1 || date2);
  }, [event, showTime]);

  /** ------ Handle Close Modal ------ */
  // const closeEventModal = () => {
  //   setShowModal({ event: false, bookedUsers: false })
  //   setShowTime(false);
  //   setShowStartCalendar(false);
  //   setShowEndCalendar(false);

  //   // setTimeOptions([])

  //   setStartTimeOptions([]);
  //   setEndTimeOptions([]);

  //   setIsDisable(false);
  //   setIsEdit(false);

  //   setShowCustomOptions(false);
  //   setPastSchedule(false);
  //   setEvent({ ...eventParams })
  // };

  /** ----- Open Event Modal ----- */
  const openEventModal = () => setShowModal({ event: true });

  /** --- To Hide Calendar --- */
  const handleHideCalendar = () => {
    setShowStartCalendar(false);
    setShowEndCalendar(false);
  }
  /** ----- Handle Date Label Click ------- */
  const handleDateLabelClick = (dateField) => {
    if (dateField === 'startDate') {
      setShowStartCalendar(!showStartCalendar);
      setShowEndCalendar(false);
    }

    if (dateField === 'endDate') {
      setShowEndCalendar(!showEndCalendar);
      setShowStartCalendar(false);
    }
  };

  /** ------ Handle Date Picker ----- */
  const handleCalendarDate = (date, field) => {
    let _event = event;

    if (field === 'startDate') {
      const _st = moment(date);
      const _et = moment(_event.endDate);
      if (_et.isBefore(_st)) {
        _event.endDate = date
      }
    }

    _event[field] = date;

    setIsDisable(false)
    setEvent({ ..._event })
    handleHideCalendar();
  }

  useEffect(() => {
    if (showTime) {
      handleGenerateTimeOptions()
    }
  }, [event.startDate, event.endDate, event.service, event.startTime])

  /** ----- Updating the Amount for class based on hourly payment defined in service ---- */
  useEffect(() => {
    if (showTime && event.startTime && event.endTime) {
      setEvent({
        ...event,
        amount: calculateClassPrice(event.startDate, event.startTime, event.endDate, event.endTime, classHourPrice, serviceAmountType)
        // amount: calculateClassPrice(event.startTime, event.endTime, classHourPrice, serviceAmountType)
      })
    }
  }, [event.startTime, event.endTime, event.startDate, event.endDate]);


  const handleGenerateTimeOptions = () => {
    setStartTimeOptions(generateTimeOptions(events, event.startDate));
    if (event.startTime) {
      setEndTimeOptions(generateTimeOptions(events, event.endDate, event.startTime, event.startDate));
    }
  }

  const renderStartDatePicker = () => {
    return (
      <DateCalender
        ref={(ref) => startDatePickerRef.current = ref}
        onChange={(date) => handleCalendarDate(date, 'startDate')}
        minDate={minimumDate}

      />
    )
  }

  /** ---- Handle Date Picker ---- */
  const renderEndDatePicker = () => {
    return (
      <DateCalender
        ref={(ref) => endDatePickerRef.current = ref}
        onChange={(date) => handleCalendarDate(date, 'endDate')}
        minDate={event.startDate || minimumDate}
      />
    )
  }

  /** ---- Handle Fields Changes ---- */
  const handleModalInput = (e) => {
    let { name, value, checked } = e.target;

    let data = {};

    if (name === 'max_capacity') {
      if (value.trim() !== '') {
        value = validateNumber(value);
        // if( value > 20) return ;
      }
    }
    /** ---- If Custom Options otherwise ----- */
    if (Object.keys(customOption).includes(name)) {
      data.customOption = { ...event.customOption }
      data.customOption[name] = checked
    }

    if (name === "service") {
      const selectedOption = e.target.selectedOptions[0];
      const serviceType = selectedOption.getAttribute('data-service-type');
      const amountType = selectedOption.getAttribute('data-amount-type');

      const serviceTitle = selectedOption.getAttribute('data-service-title');
      const category = selectedOption.getAttribute('data-service-tags');

      const serviceDescription = selectedOption.getAttribute('data-service-description');
      var amount = selectedOption.getAttribute('data-service-amount');

      data.amount = amount
      data.service_type = serviceType
      if (serviceType === 'class') {
        data.max_capacity = selectedOption.getAttribute('data-service-max-capacity');
        data.is_virtual = JSON.parse(selectedOption.getAttribute('data-service-is-virtual'));
        data.required_approval = false
        setServiceAmountType(amountType);
        setClassHourPrice(amount);


      } else {
        delete data.max_capacity
      }

      data.title = category ? category.concat('/' + serviceTitle) : serviceTitle;
      // data.tags = serviceTags
      data.description = serviceDescription
      // data.allDay = serviceType === 'class' ? false : true;

      setShowTime(serviceType === 'class');
    }

    if (name === 'schedule') {
      if (value !== 'custom') {
        // if (event.days >= 0) {
        //   data[name] = 'daily'
        // }
        setShowCustomOptions(false);
        data.customOption = { ...customOption }
      }

      if (value === 'custom') {
        setShowCustomOptions(true);
        data.customOption = { ...customOption }
      }
    }

    /** ------------- Checking For Overlapping ---------------- */

    if (name === 'startTime') {
      data.endTime = ''
    }

    if (name === 'allowed_payment') {
      if (value === 'online' && (paymentMode !== 'online' || !isStripeConnected)) {
        value = 'offline'
        toast.info(`Please enable online payments in the 'Wallet' section to proceed`)
      }

    }

    if (name === 'required_approval') {
      value = JSON.parse(value);
    }

    if (name === 'is_virtual') {
      if (!['true', true].includes(value)) {
        delete data.class_information
      }
      value = ['true', true].includes(value) ? true : false
    }
    data[name] = value
    setEvent({ ...event, ...data });
  }


  const handleProcessSubmitEvent = () => {
    var scheduleId = Date.now();
    var weekdays = null;
    var weekDaysToRepeat = [];
    var totalDays = 0;
    const hourDifference = moment(event.endDate).diff(moment(event.startDate), 'hours');
    if (event.schedule === 'no_repeat') {
      totalDays = 1
    }

    if (['daily', 'custom'].includes(event.schedule)) {
      // const lastDate = moment(event.startDate).add(1, 'years').toDate();
      const lastDate = moment(repeatPeriod).endOf("day").toDate();
      totalDays = Math.ceil(moment(lastDate).diff(moment(event.startDate).startOf("day"), 'days', true));
      if (event.schedule === 'custom') {

        const allCustomOptionsFalse = Object.values(event.customOption).every((value) => !value);

        console.log( "allCommonOptionsFalse", allCustomOptionsFalse);

        if(allCustomOptionsFalse) {
          weekDaysToRepeat = [0, 1, 2, 3, 4, 5, 6]
        }else{
          weekdays = event.customOption;
          for (const day in weekdays) {
            if (weekdays[day]) {
              const dayOfWeek = moment().day(day).day();
              weekDaysToRepeat.push(dayOfWeek);
            }
          }
        }

      } else {
        weekDaysToRepeat = [0, 1, 2, 3, 4, 5, 6]
      }
    } else {
      weekDaysToRepeat = [0, 1, 2, 3, 4, 5, 6]
    }

    const _evt = [];
    for (let i = 0; i < totalDays; i++) {
      const currentDate = moment(event.startDate).add(i, 'days');
      const dayOfWeek = currentDate.day();
      if (weekDaysToRepeat.includes(dayOfWeek)) {
        const endDate = moment(currentDate).add(hourDifference, 'hours').toDate();
        endDate.setHours(23)
        endDate.setMinutes(59)
        endDate.setSeconds(59)

        const newEvent = {
          ...event,
          associatedWithUser: props?.loggedUser.user,
          service: event.service,
          title: event.title,
          schedule: event.schedule,
          scheduleId: scheduleId + i,
          // allDay: event.allDay,
          date: currentDate.startOf('d').toDate(),
          start: momentFormat.combineDateTime(currentDate.toDate(), event.startTime),
          end: momentFormat.combineDateTime(endDate, event.endTime),
        };
        _evt.push(newEvent);
      }
    }

    setEvents((prevEvents) => [...prevEvents, ..._evt]);
    dispatch(createSchedule({ schedules: _evt }));
    closeEventModal();
  }

  /**
   * Handle Submit Event 
   * 
   * @function handleSubmitEvent
   * @param {object} e - event object
   * @returns {void}
   * 
   * Check if the event is overlapping with existing events.
   * If overlap, show a warning modal to confirm.
   * If not overlap, submit the event.
   */
  const handleSubmitEvent = async (e) => {
    if (event.startDate && event.endDate) {
      
      const result = isOverlapping(event.startDate, event.endDate, events, event);
      if (result) {
        Swal.fire({
          title: 'Oops Time Conflict! ',
          text: `You already have ${result.title} scheduled, are you sure you want to add this too?`,
          icon: 'warning',
          confirmButtonText: 'Allow',
          showCancelButton: true,
          cancelButtonText: 'Deny',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          preConfirm: () => {
            return new Promise((resolve) => {
              handleProcessSubmitEvent() // Open Schedule Modal to create and redirect after creating
              resolve();
            });
          }
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.cancel) {
            closeEventModal(); // Call your skip button action here
          }
        });
      } else {
        handleProcessSubmitEvent()
      }
    }else {
      toast.error('Please select start and end date')
    }
  };

  /** ------ When Updating a Schedules ------ */

  const updatingSchedules = (type) => {
    var data = {
      service: typeof event.service === 'object' ? event.service._id : event.service,
      title: event.title,
      description: event.description,
      // allDay: event.allDay,
      service_type: event.service_type,
      max_capacity: event.max_capacity,
      allowed_payment: event.allowed_payment
    }

    /** ------ These Fields gets updated when updating the class ------- */
    if (showTime) {
      data.amount = event.amount
      data.is_virtual = event.is_virtual
      if (event.is_virtual) {
        data.class_information = event.class_information
      }
      data.required_approval = event.required_approval

      data.start = momentFormat.combineDateTime(event.startDate, event.startTime)
      data.end = momentFormat.combineDateTime(event.endDate, event.endTime)
    }

    // also add fields in the update api in backend 
    dispatch(updateSchedule(type === 'current' ? event._id : event.scheduleId, data, type));
    if (type === 'all') {
      toast.info('Update is in progress')
    }
    closeEventModal()
  }

  const handleDelete = () => {
    dispatch(deleteSchedule({ serviceSchedulerId: event._id }))
    closeEventModal()
  }

  const handleDeleteMultiPle = (field) => {
    if (field === 'all') {
      dispatch(deleteSchedule({ scheduleId: event.scheduleId }))
    } else {
      dispatch(deleteSchedule({ serviceSchedulerId: event._id }))
    }
    closeEventModal()
  }

  const [currentStep, setCurrentStep] = useState(1);


  const closeEventModal = () => {
    setShowModal({ event: false, bookedUsers: false })
    setShowTime(false);
    setShowStartCalendar(false);
    setShowEndCalendar(false);

    // setTimeOptions([])

    setStartTimeOptions([]);
    setEndTimeOptions([]);

    setIsDisable(false);
    setIsEdit(false);
    setCurrentStep(1);
    setShowCustomOptions(false);
    setPastSchedule(false);
    setEvent({ ...eventParams })

    // if(searchQueryValue === 'profile' && redirect === 'profile'){
    //   navigate('/profile', { currentStep:6, currentQuestionIndex:-1, redirect:'scheduler'})
    // }
  };

  const eventModal = () => {


    const handleNextStep = () => {
      let nextStep = currentStep + 1;

      while (
        (nextStep === 4 && !showTime) ||
        (nextStep === 6 && !showTime) ||
        (nextStep === 7 && !showTime)
      ) {
        nextStep++;
      }
      if (nextStep <= 8) setCurrentStep(nextStep);
    };

    const handlePreviousStep = () => {
      let prevStep = currentStep - 1;
      while (
        (prevStep === 4 && !showTime) ||
        (prevStep === 6 && !showTime) ||
        (prevStep === 7 && !showTime)
      ) {
        prevStep--;
      }
      if (prevStep >= 1) setCurrentStep(prevStep);
    };



    const renderStepContent = () => {
      switch (currentStep) {
        case 1: // Step 1: Specialty
          return (
            <div className="step-content">
              <Form.Group controlId="formSpecialty">
                <FormTitle className="step-label">Specialty</FormTitle>
                <Form.Control
                  as="select"
                  name="service"
                  value={
                    event.service
                      ? typeof event.service === 'object'
                        ? event.service._id
                        : event.service
                      : ''
                  }
                  onChange={(e) => handleModalInput(e)}
                >
                  <option value="">Choose Specialty</option>
                  {props &&
                    props.services.map((service, serviceIndex) => (
                      <option
                        value={service._id}
                        key={serviceIndex}
                        data-service-type={service.bbservice.service_type}
                        data-amount-type={service.amount_type}
                        data-service-title={service.bbservice.name}
                        data-service-tags={
                          typeof service.category === 'object'
                            ? service.category.name
                            : null
                        }
                        data-service-max-capacity={service.max_capacity}
                        data-service-is-virtual={service.is_virtual}
                        data-service-description={
                          service.desc || service.bbservice.description
                        }
                        data-service-amount={service.amount}
                      >
                        {typeof service.category === 'object'
                          ? service.category.name
                          : service.category}{' '}
                        | <strong>{`$${service.amount}${service.amount_type === 'hour' ? '/hour' : ' Flat'}`}</strong>
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </div>
          );

        case 2: // Step 2: Date / Repeated
          return (
            <div className="step-content">
              <Row>
                <Col sm={6}>
                  <FormTitle>Start Date</FormTitle>
                  <Form.Group aria-label='startDatePicker' className='date__select'>
                    <Form.Label
                      onClick={() => isEdit ? null : handleDateLabelClick('startDate')}
                      // className={isEdit ? 'cursor-blocked' : null}
                      style={{ cursor: isEdit ? 'not-allowed' : 'pointer' }}
                    >
                      {momentFormat.formatWeekDate(event.startDate)}
                    </Form.Label>
                    {showStartCalendar && renderStartDatePicker()}
                    {showTime &&
                      <Form.Control
                        as="select"
                        name="startTime"
                        onChange={(e) => handleModalInput(e)}
                        value={event.startTime}
                        className='time--options'
                      >
                        <option value="">Start Time</option>
                        {startTimeOptions.map((option, optionIndex) => {
                          return <option
                            key={optionIndex}
                            value={option.time}
                            disabled={option.disabled}
                          >
                            {option.time12}
                          </option>
                        })}
                      </Form.Control>
                    }
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <FormTitle>End Date</FormTitle>

                  <Form.Group aria-label='endDatePicker' className='date__select'>
                    <Form.Label
                      onClick={() => isEdit ? null : handleDateLabelClick('endDate')}
                      // className={isEdit ? 'cursor-blocked' : null}
                      style={{ cursor: isEdit ? 'not-allowed' : 'pointer' }}
                    >
                      {momentFormat.formatWeekDate(event.endDate)}
                    </Form.Label>
                    {showEndCalendar && renderEndDatePicker()}
                    {showTime &&
                      <Form.Control
                        as="select"
                        name='endTime'
                        onChange={(e) => handleModalInput(e)}
                        value={event.endTime}
                        className='time--options'
                      >
                        <option value="">End Time</option>
                        {endTimeOptions.map((option, optionIndex) => {
                          return <option
                            key={optionIndex}
                            value={option.time}
                            disabled={option.disabled}
                          >
                            {option.time12}
                          </option>
                        })}
                      </Form.Control>
                    }
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="formSchedule">
                <FormTitle className="step-label">Repeat</FormTitle>
                <Stack direction="row" spacing={1} sx={{ marginTop: 1 }}>
                  {scheduleOptions.map((option) => (
                    <Chip
                      key={option.value}
                      label={option.label}
                      onClick={() => !isEdit && handleModalInput({
                        target: {
                          name: 'schedule',
                          value: option.value
                        }
                      })}
                      color={event.schedule === option.value ? "primary" : "default"}
                      variant={event.schedule === option.value ? "filled" : "outlined"}
                      sx={{
                        cursor: isEdit ? 'not-allowed' : 'pointer',
                        opacity: isEdit ? 0.7 : 1
                      }}
                    />
                  ))}
                </Stack>
              </Form.Group>
              {showCustomOptions && (
                <Form.Group>
                  <FormTitle className="step-label">Repeat Every</FormTitle>
                  <div>
                    {Object.entries(event?.customOption).map(([key, value]) => (
                      <Form.Check
                        inline
                        key={key}
                        type="checkbox"
                        id={key}
                        label={key}
                        checked={value}
                        name={key}
                        onChange={(e) => handleModalInput(e)}
                        style={{ textTransform: 'capitalize' }}
                        disabled={isEdit}
                      />
                    ))}
                  </div>
                </Form.Group>
              )}
              {(['daily', 'custom'].includes(event.schedule)) &&
                <Form.Group>
                  <FormTitle className="step-label">End Repeat Date</FormTitle>
                  <div>
                    <DateCalender
                      // ref={(ref) => endDatePickerRef.current = ref}
                      onChange={(date) => setRepeatPeriod(date)}
                      minDate={event.endDate || minimumDate}
                      value={repeatPeriod}
                    />
                  </div>
                </Form.Group>
              }

            </div>
          );

        case 3: // Step 3: Total Amount
          return (
            <div className="step-content">
              <Form.Group controlId="formTotalAmount">
                <FormTitle className="step-label">Total Amount</FormTitle>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    aria-label="Amount (to the nearest dollar)"
                    type="text"
                    disabled
                    value={formatPrice(event.amount)}
                    className="cursor-blocked"
                  />
                </InputGroup>
              </Form.Group>
            </div>
          );

        case 4: // Step 4: Max Capacity
          return (
            <div className="step-content">
              {showTime && (
                <Form.Group controlId="formMaxCapacity">
                  <FormTitle className="step-label">Capacity</FormTitle>
                  <Form.Control
                    type="text"
                    placeholder="Max Capacity"
                    value={event.max_capacity}
                    name="max_capacity"
                    onChange={(e) => handleModalInput(e)}
                  />
                </Form.Group>
              )}
            </div>
          );

        case 5: // Step 5: Will Payment Be Accepted
          return (
            <div className="step-content">
              <Form.Group controlId="formAllowedPayment">
                <FormTitle className="step-label">
                  Will the payment be accepted on BirthBridge?
                </FormTitle>
                <small className="form-text text-muted">
                  For you to use this and/or our virtual experience, please make
                  sure you've connected your Stripe account in the "Get Paid"
                  navigation.
                </small>
                <Stack direction="row" spacing={1} sx={{ marginTop: 1 }}>
                  <Chip
                    label="Yes"
                    onClick={() => handleModalInput({
                      target: {
                        name: 'allowed_payment',
                        value: 'online'
                      }
                    })}
                    color={event.allowed_payment === 'online' ? "primary" : "default"}
                    variant={event.allowed_payment === 'online' ? "filled" : "outlined"}
                    sx={{
                      cursor: 'pointer',
                      opacity: 1
                    }}
                  />
                  <Chip
                    label="No"
                    onClick={() => handleModalInput({
                      target: {
                        name: 'allowed_payment',
                        value: 'offline'
                      }
                    })}
                    color={event.allowed_payment === 'offline' ? "primary" : "default"}
                    variant={event.allowed_payment === 'offline' ? "filled" : "outlined"}
                    sx={{
                      cursor: 'pointer',
                      opacity: 1
                    }}
                  />
                </Stack>
              </Form.Group>
            </div>
          );

        case 6: // Step 6: Virtual Class
          return (
            <div className="step-content">
              {showTime && (
                <>
                  <Form.Group controlId="formIsVirtual">
                    <FormTitle className="step-label">Will this be a virtual class?</FormTitle>
                    <Form.Control
                      as="select"
                      placeholder="Select an option"
                      value={event.is_virtual}
                      name="is_virtual"
                      onChange={(e) => handleModalInput(e)}
                    >
                      <option value={true}>Yes, it's virtual</option>
                      <option value={false}>No, it's in person</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formEventInformation">
                    <FormTitle style={{ marginTop: '1rem' }} className="step-label">Provide details for your event</FormTitle>

                    {/* You may update this later on but if you would like it to show in the reminder email for your client you must enter this */}



                    <small className="form-text text-muted">
                      If this is a **virtual event**, add the Zoom link or online meeting details here.
                    </small>


                    <small className="form-text text-muted">
                      If this is an **in-person event**, include the location, address, and any important instructions for attendees.
                    </small>

                    <small className="form-text ">
                      These details will be included in reminder emails sent to your client 24 hours and 2 hours before the event. You may update this later, but any changes made after scheduling will not be reflected in previously sent reminders. Be sure to include all necessary information for your client here.
                    </small>


                    <Form.Control
                      as="textarea"
                      placeholder="Enter details about the event (Zoom link, location, or special instructions)"
                      value={event.event_information}
                      name="event_information"
                      onChange={(e) => handleModalInput(e)}
                      style={{
                        marginTop: '1rem',
                        padding: '12px',
                        borderRadius: '8px',
                        border: '1px solid #d1eff9',
                        minHeight: '120px',
                        backgroundColor: '#fafafa',
                        fontSize: '14px',
                        resize: 'vertical',
                        boxShadow: 'inset 0 1px 2px rgba(0,0,0,0.05)',
                      }}
                    />
                  </Form.Group>

                  {/* {event.is_virtual && (
                    <Form.Group controlId="formClassInformation">
                      <FormTitle className="step-label">Provide details for your virtual class</FormTitle>
                      <small className="form-text text-muted">
                        These details will be included in reminder emails sent 24 hours and 2 hours before the class.
                      </small>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter details about the virtual class"
                        value={event.class_information}
                        name="class_information"
                        onChange={(e) => handleModalInput(e)}
                      />
                    </Form.Group>

                  )} */}
                </>
              )}
            </div>
          );

        case 7: // Step 7: Manually Approve
          return (
            <div className="step-content">
              {showTime && (
                <Form.Group controlId="formRequiredApproval">
                  <FormTitle className="step-label">Manually Approve Families</FormTitle>
                  <small className="form-text text-muted">
                    You can choose to automatically accept families or manually
                    accept each family.
                  </small>
                  <Form.Control
                    as="select"
                    placeholder="Will approval be required..."
                    value={event.required_approval}
                    name="required_approval"
                    onChange={(e) => handleModalInput(e)}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </Form.Control>
                </Form.Group>
              )}
            </div>
          );

        case 8: // Step 8: Additional Information
          return (
            <div className="step-content">
              <Form.Group controlId="formDescription" className="description-form-group">
                <FormTitle className="step-label">Additional Information</FormTitle>
                <Form.Control
                  as="textarea"
                  placeholder="Any additional information?"
                  value={event.description}
                  name="description"
                  onChange={(e) => handleModalInput(e)}
                  className="custom-textarea"
                  rows={4}
                />
              </Form.Group>
              {/* <Form.Group controlId="formDescription">
                <FormTitle className="step-label">Additional Information</FormTitle>
                <Form.Control
                  as="textarea"
                  placeholder="Any additional information?"
                  value={event.description}
                  name="description"
                  onChange={(e) => handleModalInput(e)}
                />
              </Form.Group> */}
            </div>
          );

        default:
          return null;
      }
    };

    return (
      <Modal size="lg" centered show={showModal.event} onHide={closeEventModal}>
        <Modal.Header style={{ padding: '8 16px' }} closeButton>
          <Modal.Title >
            {/* Step {currentStep} of 8
            <small className="step-indicator">
              {` (Progress: ${Math.round((currentStep / 8) * 100)}%)`}
            </small> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {renderStepContent()}
            {/* Other form elements if necessary */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <CustomButton variant="outline-danger" onClick={closeEventModal}>
            Cancel
          </CustomButton> */}

          <CustomButton disabled={currentStep === 1} variant="secondary" onClick={handlePreviousStep}>
            Back
          </CustomButton>

          {currentStep < 8 ? (
            <CustomButton
              variant="primary"
              onClick={handleNextStep}
              disabled={!isStepValid(currentStep)}
            >
              Next
            </CustomButton>
          ) : (
            <>
              {!isPastSchedule && submitActions()}
            </>
          )}

          {/* Keep existing buttons intact */}
          {/* {!isPastSchedule && submitActions()} */}
          {event.schedule !== 'daily' && isEdit && !isPastSchedule && (
            <CustomButton variant="danger" onClick={() => handleDelete()}>
              Delete
            </CustomButton>
          )}
          {event.schedule === 'daily' && !isPastSchedule && isEdit && (
            <Dropdown className="d-inline-flex">
              <Dropdown.Toggle variant="danger" id="dropdown-basic">
                Delete
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleDeleteMultiPle('current')}>
                  Delete Current
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleDeleteMultiPle('all')}>
                  Delete All
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}

          {isEdit && showTime && (
            <CustomButton
              variant="dark"
              className="ml-auto"
              onClick={() => setShowModal({ ...showModal, bookedUsers: true })}
              disabled={event.bookedUsers && !event.bookedUsers.length}
            >
              {event.bookedUsers && event.bookedUsers.length
                ? `View ${event.bookedUsers.length} Users`
                : 'No Users Booked'}
            </CustomButton>
          )}
        </Modal.Footer>
      </Modal>
    );
  }


  const updateActions = () => {
    return (
      event && event.schedule === 'no_repeat'
        ? <CustomButton onClick={() => isDisable ? null : updatingSchedules('current')} disabled={isDisable}>Update</CustomButton>
        :
        <Dropdown className='d-inline-flex'>
          <Dropdown.Toggle
            variant='primary'
            id="dropdown-basic"
            disabled={isDisable}
          >
            Update
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => isDisable ? null : updatingSchedules('current')} disabled={isDisable} >Current</Dropdown.Item>
            <Dropdown.Item onClick={() => isDisable ? null : updatingSchedules('all')} disabled={isDisable} >All</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
    )
  }

  const submitActions = () => {
    return (
      <>
        {isEdit
          ? updateActions()
          : <CustomButton
            disabled={isDisable}
            onClick={(e) => isDisable ? null : handleSubmitEvent(e)}
          >
            Save
          </CustomButton>
        }
      </>
    )
  }

  const bookedUsersModal = () => {
    return (
      <BookedUsersModal
        onHide={() => setShowModal({ ...showModal, bookedUsers: false })}
        event={event}
        users={event.bookedUsers}
        show={showModal.bookedUsers}
      />
    )
  }

  const handleOnClickSchedule = () => {
    let _startDate = moment().startOf('day').toDate()
    let _endDate = moment().add(1, 'd').startOf('day').toDate()

    handleSelectSlot({ start: _startDate, end: _endDate });
  }

  // Add this function near your other utility functions
  const isStepValid = (step) => {
    switch (step) {
      case 1: // Specialty validation
        return event.service && event.service !== '' && event.service !== 'Choose Specialty';

      case 2: // Time validation
        if (showTime) {
          return event.startTime && event.endTime;
        }
        return true;

      case 4: // Capacity validation
        if (showTime && event.max_capacity) {
          return parseInt(event.max_capacity) >= 1;
        }
        return !showTime; // If not showing time, capacity validation is not needed

      default:
        return true;
    }
  };

  if (loader) return <LoadingView loader="ThreeDots" size={40} />
  return (
    <>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end'

      }}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '500px', maxWidth: '100%' }}>
            {`The link to your ${copiedService} schedule has been copied. You can share this with your customers or display it on your website.`}
          </Alert>
        </Snackbar>
        <CustomButton
          className=""
          style={{ marginBottom: 16, }}
          onClick={() => handleOnClickSchedule()}
        // onClick={() => testPopup()}
        >
          <MdAdd /> Add Schedule
        </CustomButton>
      </div>
      <div className="myCustomHeight" >

        <Calendar
          events={events.map((evt, evtIndex) => { return { ...evt, start: moment(evt.start).toDate(), end: moment(evt.end).toDate(), title: `${evt.title.split('/')[0]} | $${evt.service.amount}${evt.service.amount_type === 'hour' ? '/hour' : ' Flat'}` } })}
          startAccessor="start"
          endAccessor="end"
          views={['day', 'week', 'month']}
          defaultView={Views.MONTH}
          localizer={localizer}
          step={15} // 15 minutes per step
          timeslots={1} // Number of slots per step
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          components={{
            dateCellWrapper: EventWrapper,
            // dayColumnWrapper: EventWrapper,
          }}
          selectable
        />
        {showModal.event && eventModal()}
        {showModal.bookedUsers && bookedUsersModal()}
      </div>
    </>
  )
}

Scheduler.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
}

export default Scheduler;