import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Button, Alert } from "react-bootstrap";
import { FaInfoCircle } from 'react-icons/fa';
import { notValid, emailValidation } from "../../../utils/validations";
import { useSelector, useDispatch } from "react-redux";
import { clearReduxState, createAccount, requestEmailVerification, showAuthModal } from "../../../redux/actions/auth.actions";
import { userRole } from "../../../helpers/config";
import { signInWithGoogle } from "../../../redux/actions/firebase.actions";
import AuthModal from "../AuthModal";
import SEO from "../../../seo";
import { logEvent } from "../../../googleAnalyticWrapper/analytic";
import { ThreeDots } from 'react-loader-spinner';
import referralResource from "../../../utils/referralSource";
import CustomGoogleSignIn from "../../../commonComponents/CustomGoogleSignIn";
import { Chip, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import StepAddress from "./seller/stepAddress";
import CustomButton from "../../sharedComponents/Button";

function SignUpModel() {
    const dispatch = useDispatch();

    const location = window.location;
    const intialValue = {
        name: '',
        email: '',
        // phoneNumber: '',
        password: '',
        confirmPassowrd: '',
        role: userRole.buyer,
        referralCode: '',
        referralSource: ''
    };
    const alertValue = { message: '', show: false, variant: '' };
    const [alert, setAlert] = useState({ ...alertValue })
    const [user, setUser] = useState({ ...intialValue });
    const [termsChecked, setTermsChecked] = useState(false)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [isReferralActive, setReferralActive] = useState(false);
    const [selectOpen, setSelectOpen] = useState(false);

    const [showLogin, setLoginShow] = useState(false);

    /** ------- Reducer State -------- */

    const apiResult = useSelector(state => state.auth);
    const showModal = useSelector(state => state.showModal);
    /** ------ Meta ------ */
    const metaState = useSelector(state => state.meta);

    useEffect(() => {
        logEvent('signup', 'family_signup', 'Family Signup Page Viewed', 1);

        // return () => {
        //     dispatch(clearReduxState());
        // }
    }, [])

    useEffect(() => {
        if (metaState.platform && metaState.platform.success) {
            if (metaState.platform.referral) {
                setReferralActive(metaState.platform.referral.is_active);
            }
        }
    }, [metaState])

    useEffect(() => {
        // console.log( apiResult );
        if (apiResult && apiResult.loginError) {
            let _alert = {}
            _alert['show'] = true
            _alert['message'] = apiResult.loginError.message || apiResult.loginError
            _alert['variant'] = "danger"
            setAlert(_alert)
            setLoading(false)
            dispatch(clearReduxState());
        }

        if (apiResult && apiResult.error) {
            let _alert = {}
            _alert['show'] = true
            _alert['message'] = apiResult.error
            _alert['variant'] = "danger"
            setAlert(_alert)
            setLoading(false)
            dispatch(clearReduxState());
        }

        if (apiResult && apiResult.accountCreated) {
            let _alert = {}
            _alert['show'] = true
            _alert['message'] = 'You’ve successfully submitted your information. Please go to your email and verify your account.'
            _alert['variant'] = "success"
            setAlert(_alert)
            const data = { email: user.email }
            // dispatch(showAuthModal(true)) // open auth modal
            dispatch(requestEmailVerification(data))  // Requested OTP for Input Email
            // dispatch(showVerifyOtp(false)) // Display Verify OTP Screen on Auth Opened Auth Modal -here false [passed] verifying email for signup
            setLoading(false)
            setUser({ ...intialValue });
            setTimeout(() => {
                setAlert({ ...alertValue });
            }, 5000)
        }

        // if (apiResult && (apiResult.token || apiResult.loggedIn)) {
        //     if (location) {
        //         window.location.href = location.href
        //     } else {
        //         window.location.replace('/')
        //     }
        // }

        // if (apiResult && apiResult.token) {
        //     // showToaster('success', 'Successfully Authenticated')
        //     // setLoading(false)
        //     setTimeout(() => {
        //         if (location) {
        //             window.location.href = location.href
        //         } else {
        //             window.location.replace('/')
        //         }
        //     }, 350)
        // }
    }, [apiResult])


    useEffect(() => {
        if (showModal.authModal !== null || showModal.authModal !== undefined) {
            setLoginShow(showModal.authModal)
        }
    }, [showModal.authModal])

    const handleChangeInput = (e) => {
        let { name, value } = e.target;
        // if ((name === 'phoneNumber') && value.trim() !== '') {
        //     value = formatPhoneNumber(value)
        // }
        setUser({ ...user, [name]: value })
        setError({ ...error, [name]: '' })
    }


    const handleCheckboxChange = (e) => {
        setTermsChecked(e.target.checked);
    }

    const isValid = () => {
        let isValid = true
        let passwordErrorMessage = "Please provide password";
        // let userNameErrorMessage = "Please provide Email";
        let email = "Please enter your email";
        let validEmail = "Please enter a valid email address"
        let errorMessages = {};
        if (notValid(user.name)) {

            errorMessages['name'] = 'Name is required'
            isValid = false;
        }
        if (notValid(user.email)) {
            errorMessages['email'] = email;
            isValid = false;
        } else if (!emailValidation(user.email)) {
            errorMessages['email'] = validEmail;
            isValid = false;
        }

        // if (notValid(user.phoneNumber)) {
        //     errorMessages['phoneNumber'] = 'Phone number is required '
        //     isValid = false;
        // }
        if (notValid(user.password)) {
            errorMessages['password'] = passwordErrorMessage;
            isValid = false;
        }

        if (user.password && user.password.length < 8) {
            errorMessages['password'] = 'Password, minimum length must be 8';
            isValid = false;
        }

        // if (notValid(user.confirmPassowrd)) {
        //     errorMessages['confirmPassowrd'] = 'Confirm password is required'
        //     isValid = false;
        // }
        // if (user.password !== user.confirmPassowrd) {
        //     errorMessages['confirmPassowrd'] = ' Password do not match'
        //     isValid = false;
        // }

        if (!user.address) {
            isValid = false;
            errorMessages.location = "Address is required";
        }

        if (user.address && typeof user.address === 'object') {
            if (Object.values(user.address).every(add => !add)) {
                isValid = false;
                errorMessages.location = "Address is required";
            } else {
                errorMessages.location = ''
                errorMessages.address = {}
                if (!user.address.city) {
                    isValid = false;
                    errorMessages.address.city = "City is required";
                }
                if (!user.address.state) {
                    isValid = false;
                    errorMessages.address.state = "State is required";
                }
            }
        }

        if (!termsChecked) {
            errorMessages['termsChecked'] = 'Please Accept Term & Conditions'
            isValid = false;

        }

        if (!isValid) {
            setError({ ...error, ...errorMessages });
        }

        return isValid;
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setError(false)

        if (isValid()) {
            setLoading(true)
            dispatch(createAccount(user))
        }
    }

    const showAlertMessage = () => {
        return (
            <Alert variant={alert.variant}> {alert['message']} </Alert>
        )
    }

    const handleGoogleSignIn = async () => {
        dispatch(signInWithGoogle());
    }

    const handleSignInModel = () => {
        dispatch(showAuthModal(true))
    }

    return (
        <section className="login--Wrapper signup--page">
            <SEO
                title="Sign Up"
                description="Sign up to find and book birth professionals on our platform."
                keywords="signup, join us, Bridge Directory, Doula Match, Hellomeela, Bornbir, birth, prenatal, postpartum, birth professionals, families, Pregnancy"
                url="/family-signup"
            />

            <Container>
                <Row style={{ justifyContent: 'center' }}>
                    {/* <Col sm={12} lg={6} className="pr-lg-0 d-none d-lg-block">
                        <a className="company--brand">
                            <img loading='lazy' src="/images/logo.webp" alt="BirthBridge Logo" />
                            <span>BirthBridge</span>
                        </a>
                    </Col> */}
                    {/* <h1>sub bitchces</h1> */}
                    <Col sm={12} lg={6} className="pl-lg-0">
                        <Form>
                            <h3 className="text-center mb-3">Register as an Expectant Family</h3>
                            {alert['show'] && showAlertMessage()}

                            <CustomGoogleSignIn handleGoogleSignIn={handleGoogleSignIn} />
                            <p className="mb-3 mt-2 text-center"><span>OR</span></p>

                            <Row>
                                <Form.Group className="col-sm-12 mb-4">
                                    <TextField
                                        fullWidth
                                        // type="text" 
                                        placeholder="Name"
                                        name="name"
                                        value={user.name}
                                        onChange={handleChangeInput}
                                        label="Name"
                                    />
                                    {error.name && <span className="error error-message">{error.name}</span>}
                                </Form.Group>

                                <Form.Group className="col-sm-12 mb-4">
                                    <TextField
                                        fullWidth
                                        type="email"
                                        placeholder="Email Address"
                                        name="email"
                                        value={user.email}
                                        onChange={handleChangeInput}
                                        label={'Email'}
                                    />
                                    {error.email && <span className="error error-message">{error.email}</span>}
                                </Form.Group>

                                <Form.Group className="col-sm-12 mb-4">
                                    <TextField
                                        fullWidth
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        value={user.password}
                                        onChange={handleChangeInput}
                                        label={'Password'}
                                    />
                                    {error.password && <span className="error error-message">{error.password}</span>}
                                </Form.Group>

                                {/* <Form.Group className="col-sm-6">
                                    <Form.Control type="password" placeholder="Confirm Password" name="confirmPassowrd" value={user.confirmPassowrd} onChange={handleChangeInput} />
                                    {error.confirmPassowrd && <span className="error error-message">{error.confirmPassowrd}</span>}
                                </Form.Group> */}

                                <Form.Group className="col-sm-12  mb-4">
                                    <StepAddress
                                        handleChangeInput={handleChangeInput}
                                        seller={user}
                                        error={error}
                                        setError={(data) => console.log('error', data)}
                                        label={'Type in your address'}
                                    />
                                </Form.Group>

                                <Form.Group className="col-sm-12 mb-4">
                                    <FormControl fullWidth margin="normal" style={{ width: '100%' }}>
                                        <InputLabel id={'referral_source'}>Where you found us?</InputLabel>
                                        <Select
                                            labelId={'referral_source'}
                                            label={'Where you found us?'}
                                            onChange={(e) => {

                                                handleChangeInput(e)
                                                // Force close the dropdown after selection
                                                setSelectOpen(false);
                                            }}

                                            name={'referralSource'}
                                            value={user.referralSource}

                                            // renderValue={(selected) => <Chip label={selected} />}
                                            // {...(question.key === 'amount_type' && sectionData['service_type'] === 'service' ? { disabled: true } : {})}
                                            open={selectOpen} // Controlled open state
                                            onOpen={() => setSelectOpen(true)} // Open the dropdown
                                            onClose={() => setSelectOpen(false)} // Close the dropdown
                                        >
                                            {referralResource.map((option, optionIndex) => (
                                                <MenuItem
                                                    key={optionIndex}
                                                    value={option.key}

                                                >
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Form.Group>

                                {(isReferralActive && user.referralSource === 'Referred By') &&
                                    <Form.Group className="col-sm-12 mb-4">
                                        <TextField
                                            fullWidth
                                            label="Referred By ( Optional )"
                                            placeholder="Referred By ( Optional )"
                                            name="referralCode"
                                            value={user.referralCode}
                                            onChange={handleChangeInput}
                                        />
                                    </Form.Group>
                                }
                            </Row>
                            <Form.Group className="form-group form--checkbox mb-0">
                                <Form.Check
                                    type="checkbox"
                                    label={
                                        <>
                                            I agree to BirthBridge's {' '}
                                            <span>

                                                <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
                                                <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" className="ml-2">
                                                    <FaInfoCircle />
                                                </a>
                                            </span>
                                            {' '}and our {' '}
                                            <span>

                                                <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                                                <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className="ml-2">
                                                    <FaInfoCircle />
                                                </a>
                                            </span>
                                        </>
                                    }
                                    checked={termsChecked}
                                    onChange={handleCheckboxChange}
                                />
                            </Form.Group>
                            {/* send a link to terms page */}
                            {error.termsChecked && <span className="error error-message">{error.termsChecked}</span>}
                            <CustomButton style={{ display: 'flex', justifyContent: 'center' }} variant="primary" type="submit" className="mt-3" disabled={loading} onClick={(e) => onSubmit(e)}>
                                {/* 'Register' */}
                                {loading ?
                                    <ThreeDots
                                        height="25"
                                        width="25"
                                        radius="9"

                                        // color="#d1eff9"
                                        color="black"
                                        ariaLabel="three-dots-loading"
                                        visible={true}
                                    />
                                    : 'Register'
                                }
                            </CustomButton>
                            <p>Already have an account <a className="pointer" onClick={() => handleSignInModel()}>Login</a></p>
                        </Form>
                    </Col>
                </Row>
            </Container>
            {showLogin &&
                <AuthModal
                    show={showLogin}
                    userEmail={user.email}
                    userPassword={user.password}
                    onHide={() => dispatch(showAuthModal(false))}
                />
            }
        </section>
    )
}

export default SignUpModel